import React from 'react';
import image from '../../../assets/layout/loader.svg'
import { t } from '@lingui/macro';

interface IProps {
    value: boolean
}

const Loader : React.FC<IProps> = (props) => {

    if(props.value) {
        return (
            <div className="loader">
                <div className="loading">
                    <img src={image} alt={t`Načitávam`}/>
                </div>
            </div>
        )
    } else {
        return null
    }
}

export default Loader