import { createStore, applyMiddleware, combineReducers } from 'redux'
import promise from 'redux-promise-middleware'
import thunk from 'redux-thunk'
import { userReducer, IUserStore } from '../reducers/user';
import { loadingReducer, ILoadingStore } from '../reducers/loading';
import localForage from 'localforage'
import { persistStore, persistReducer } from 'redux-persist'
import { routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import { notificationsReducer, INotificationsStore } from '../reducers/notifications';
import { dataReducer, IDataStore } from '../reducers/data';

export const history = createBrowserHistory()

export interface IApplicationStore {
    userStore: IUserStore,
    dataStore: IDataStore,
    loadingStore: ILoadingStore,
    notificationsStore: INotificationsStore
}

const userPersistConfig = {
    key: 'userStore',
    storage: localForage,
    blacklist: [],
}

const dataPersistConfig = {
    key: 'dataStore',
    storage: localForage,
    blacklist: [],
}

const rootReducer = combineReducers({
    userStore: persistReducer(userPersistConfig, userReducer),
    dataStore: persistReducer(dataPersistConfig, dataReducer),
    loadingStore: loadingReducer,
    notificationsStore: notificationsReducer
})

let store = createStore(rootReducer, applyMiddleware(promise, thunk, routerMiddleware(history)))

let persistor = persistStore(store)

export { store, persistor }
