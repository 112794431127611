import config from '../../config';
import { handleErrors } from '../../js/helpers/handleErrors';
import { store } from '../../store';
import { EStatisticsInterval, EStatisticsGraphView, EStatisticsType, IGenderFilter, EStatisticsGenderType, EStatisticsGender } from '../data';

export function getGenderStats (
    filter: IGenderFilter
) {
    let dataToSend: {
        date: {
            from: string,
            to: string
        }[],
        type: EStatisticsType,
        interval?: EStatisticsInterval,
        locations?: number[],
        zones?: number[],
        group?: EStatisticsGraphView,
        gender?: EStatisticsGender,
        outputType?: EStatisticsGenderType
    } = {
        date: filter.dateRanges,
        type: filter.type,
        interval: filter.interval,
        locations: filter.localities,
        zones: filter.zones,
        group: filter.graphView,
        gender: filter.gender,
        outputType: filter.genderType
    }

    return fetch(config.api.gender, {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${store.getState().userStore.token}`
            },
            credentials: 'include',
            body: JSON.stringify(dataToSend)
        })
            .then(handleErrors)
            .then(res => res.json())
            .then(res => {
                return res
            })
            .catch((error: any) => {
                return null
            })
}

export default getGenderStats