import { Trans } from "@lingui/macro";
import { EStatisticsGraphView } from '../../../../reducers/data';

interface IProps {
    type?: EStatisticsGraphView
}

const SmallViewTitle = (props: IProps) => {
    switch(props.type) {
        case EStatisticsGraphView.multi:
            return (<><Trans>Multi</Trans></>)
        case EStatisticsGraphView.zone:
            return (<><Trans>Zóna</Trans></>)
        case EStatisticsGraphView.date:
            return (<><Trans>Dátum</Trans></>)
    }

    return null
}

export default SmallViewTitle
