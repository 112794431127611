import { Trans } from "@lingui/macro"
import { IApplicationStore } from '../../../../store/index';
import { useSelector, useDispatch } from 'react-redux';
import { EDataActions, setDataStore } from '../../../../reducers/data';
import Loader from "../Loader";

interface IProps {
}

const ShoppingCenters: React.FC<IProps> = () => {
    const dispatch = useDispatch()

    const loading = useSelector((store: IApplicationStore) => store.loadingStore.loadingMarts)
    const marts = useSelector((store: IApplicationStore) => store.dataStore.marts)
    const selectedMarts = useSelector((store: IApplicationStore) =>store.dataStore.selectedMarts)

    if(marts.length === 0) {
        return null
    }
    if(selectedMarts.length === 0 && marts.length === 1) {
        dispatch(setDataStore(EDataActions.SET_SELECTED_MARTS, marts.filter( ( val, index ) => index === 0 ) ))
    }

    return (
        <>
            <h2 className="title_h4"><Trans>Obchodné centrum</Trans></h2>
            <div className="sidebar_scroll item_center_list mgt-20">
                <Loader value={loading}/>
                {marts.map((sc, i) => {
                    let checked = typeof selectedMarts.find(m => m.id === sc.id) === 'undefined'?false:true

                    return (
                        <label htmlFor={`shoppingCenter_${sc.id}`} key={i} className="item_center">
                            <input 
                                type="checkbox" 
                                value={sc.id} 
                                id={`shoppingCenter_${sc.id}`}
                                checked={checked} 
                                onChange={(e) => {
                                    let newSelectedMarts = [...selectedMarts, sc]

                                    if(checked ) {
                                        newSelectedMarts = selectedMarts.filter(sm => sm.id !== sc.id)
                                    } 
                                    
                                    dispatch(setDataStore(EDataActions.SET_SELECTED_MARTS, newSelectedMarts))
                                }}
                            />
                            {sc.logo.length > 0 ? (
                                <div className="item_center_image">
                                    <img src={sc.logo} alt={sc.name} />
                                </div>
                            ): null }
                            <div className="item_center_desc">
                                <strong>{sc.name}</strong>   
                                <span>{sc.address}</span> 
                            </div>
                            <svg className="sico">
                                <g><use xlinkHref="/assets/layout/icons.svg#s_check1" /></g>
                                <g><use xlinkHref="/assets/layout/icons.svg#s_check2" /></g>
                            </svg>
                        </label>
                    )
                })}
            </div>
        </>
    )
}

export default ShoppingCenters