import config from '../../config';
import { handleErrors } from '../../js/helpers/handleErrors';
import { store } from '../../store';
import { setLoadingStore, ELoadingActions } from '../loading';
import { EDataActions, setDataStore } from '../data';

export function getReports (
    lang: string,
    marts: number[]
) {
    return (dispatch: any) => {
        if(marts.length === 0) {
            return null
        }
        
        dispatch(setLoadingStore(ELoadingActions.SET_LOADING_REPORTS, true))

        fetch(config.api.report, {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${store.getState().userStore.token}`
            },
            credentials: 'include',
            body: JSON.stringify({
                language: lang,
                mart: marts
            })
        })
            .then(handleErrors)
            .then(res => res.json())
            .then(json => {
                dispatch(setLoadingStore(ELoadingActions.SET_LOADING_REPORTS, false))
                dispatch(setDataStore(
                    EDataActions.GET_REPORTS,
                    json
                ))

            })
            .catch((error: any) => {
                dispatch(setLoadingStore(ELoadingActions.SET_LOADING_REPORTS, false))
                dispatch(setDataStore(EDataActions.GET_REPORTS,[]))
            })
    }
}

export default getReports