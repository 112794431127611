import { Trans } from '@lingui/macro';
import { IApplicationStore } from '../../../store/index';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Loader from '../global/Loader';
import getMarts from '../../../reducers/api/getMarts';
import getMapData from "../../../reducers/api/getMapData";
import { IMart, IMapInfo, IMapFloor, IMapLocation } from '../../interfaces/types';
import { EDataActions, setDataStore } from '../../../reducers/data';
import { getRandomColors } from '../../helpers/index';

interface IProps {

}

const PageMap = (props: IProps) => {

    const loadingMarts = useSelector((store: IApplicationStore) => store.loadingStore.loadingMarts)
    const loadingMartMaps = useSelector((store: IApplicationStore) => store.loadingStore.loadingMartMap)
    const marts = useSelector((store: IApplicationStore) => store.dataStore.marts)
    const selectedMart = useSelector((store: IApplicationStore) => store.dataStore.selectedMapMart)
    const martMaps = useSelector((store: IApplicationStore) => store.dataStore.martMaps)
    
    const [selectedMap, setSelectedMap] = useState<IMapInfo|null>(null)
    const [selectedFloor, setSelectedFloor] = useState<IMapFloor|null>(null)

    let dispatch = useDispatch()

    useEffect(() => {
        if(!marts.length) {
            dispatch(getMarts())
        }
    }, [dispatch, marts])

    useEffect(() => {
        if(!martMaps.length && marts.length) {
            dispatch(getMapData(marts.map(m => m.id)))
        }
    }, [dispatch, martMaps, marts])

    useEffect(() => {
        if(selectedMart) {
            let map = martMaps.find(data => data.id === selectedMart.id)
            
            setSelectedMap(map?map:null)
            setSelectedFloor(map?(map.floor.length?map.floor[0]:null):null)
        }
    }, [selectedMart, martMaps])

    const setSelectedMart = (mart: IMart) => {
        dispatch(setDataStore(EDataActions.SET_SELECTED_MAP_MART, mart))
    }

    if (!marts.length || !selectedMart) {
        return <Loader value={true}/>
    }
    
    let colors: string[] = []

    if (selectedFloor) {
        colors = getRandomColors(selectedFloor.location.length)
    }

    return (
        <>
            <Loader value={loadingMarts || loadingMartMaps}/>

            <h1 className="title_h1 pdt-40">VISITOR <span className="text_w400"><Trans>interaktívna mapa</Trans></span>
                <div className="icon_info wh24 diblock v_top">
                    <svg className="sicofill"><use xlinkHref="assets/layout/icons.svg#s_info" /></svg>
                </div>
            </h1>

            <p className="text_basic mgt-20 wmax460">
                <Trans>
                    Umožní Vám podrobný pohľad na rozmiestnenie jednotlivých lokalít
                    a zón vo vašom obchodnom centre alebo prevádzke. 
                </Trans>
            </p>

            <div className="tabs mgt-40">
                <div className="tabs_inner hide_lgm">
                    {
                        marts.map((m: IMart) => {
                            let active = (m.id === selectedMart?.id) ? 'active' : ''

                            return (
                                <span 
                                    key={m.id}
                                    title="" 
                                    className={`tabs_item ${active}`}
                                    onClick={() => setSelectedMart(m)}
                                >
                                    <span className="dblock">
                                        <span>{m.name}</span>
                                        <span className="dblock text_s12 color3 pdt-5">{m.address}</span> 
                                    </span>
                                </span>
                            )
                        })
                    }
                </div>
                <div className="input_select_cont show_lgm">
                    <select 
                        className="input_select" 
                        onChange={e => {
                            let mart = marts.find(m => m.id === Number(e.target.value))
                            if (mart) {
                                setSelectedMart(mart)
                            }
                        }}
                        value={selectedMart.id}
                    >
                        {
                            marts.map((m: IMart) => {
                                return (
                                    <option
                                        key={m.id}
                                        value={m.id}
                                    >
                                        {m.name}
                                    </option>
                                )
                            })
                        }
                    </select>
                    <svg className="sico"><use xlinkHref="assets/layout/icons.svg#s_ar1_d" /></svg>
                </div>
            </div>

            {
                selectedMap ? (
                    <div className="box mgt-40 mgb-30 pd-40 pdl-25lg pdr-25lg">    
                        
                        {selectedMap.floor.length?(
                            <>
                                <div className="input_select_cont diblock">
                                    <select 
                                        className="input_select basic mid"
                                        value={selectedFloor?.id}
                                        onChange={e => {
                                            let floor = selectedMap.floor.find(f => f.id === Number(e.target.value))
                                            if (floor) {
                                                setSelectedFloor(floor)
                                            }
                                        }}
                                    >
                                        {
                                            selectedMap.floor.map( (f: IMapFloor) => {
                                                if (f.id && f.name) {
                                                    return (
                                                        <option value={f.id} key={f.id}>
                                                            {f.name}
                                                        </option>
                                                    )
                                                }
                                                return null
                                            })
                                        }
                                    </select>
                                    <svg className="sico"><use xlinkHref="assets/layout/icons.svg#s_ar1_d" /></svg>
                                </div>

                                {
                                    selectedFloor ? (
                                        <>
                                            <div className="box_map mgt-20">
                                                <img src={selectedFloor.path} alt={selectedFloor.name} className="box_map_image" />
                                                {
                                                    selectedFloor.location.map( (loc: IMapLocation, index) => {
                                                        return (
                                                            <div
                                                                className="icon_dot pulse" 
                                                                style={{
                                                                    top: loc.y + '%',
                                                                    left: loc.x + '%',
                                                                    backgroundColor: colors[index],
                                                                }}
                                                                key={index}
                                                            >
                                                                <div className="item_tooltip">
                                                                    <div className="item_tooltip_image">
                                                                        <img src={loc.path} alt={loc.name} />
                                                                    </div>
                                                                    <div className="item_tooltip_desc">
                                                                        <i style={{backgroundColor: colors[index]}}></i>
                                                                        <strong>{loc.name}</strong>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                            <div className="pdt-40">
                                                <h2 className="mg-0 text_s16 text_w500 self-center mgt-10"><Trans>Zoznam lokalit a zón</Trans></h2>
                                                <table className="table1 mgt-20 cf">
                                                    <thead>
                                                        <tr>
                                                            <th><Trans>Označenie na mape</Trans></th>
                                                            <th><Trans>Názov lokality</Trans></th>
                                                            <th><Trans>Detail</Trans></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        selectedFloor.location.map( (loc: IMapLocation, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td className="text_center">
                                                                        <div 
                                                                            className="icon_dot small v_mid" 
                                                                            style={{backgroundColor: colors[index]}}
                                                                        ></div>
                                                                    </td>
                                                                    <td>{loc.name}</td>
                                                                    <td></td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </>
                                    ) : null
                                }   
                            </>
                        ):(
                            <div className="box mgt-40 mgb-30 pd-40 pdl-25lg pdr-25lg">
                                <div className="message-wrapper">
                                    <div className="message-content message-content--warning">
                                        <Trans>Toto centrum nemá nahratú mapu.</Trans>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                ) : null
            }
            
        </>
    )
};

export default PageMap
