import React from 'react';

import "react-datepicker/dist/react-datepicker.css";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './App.scss'

import { Route, Switch } from 'react-router';
import PageLogin from './components/pages/PageLogin';
import AppMain from './AppMain';
import { useLocation } from 'react-router-dom';
import FadeAnimation from './components/global/FadeAnimation';
import { useSelector } from 'react-redux';
import { IApplicationStore } from '../store/index';
import { useEffect } from 'react';
import { useLingui } from '@lingui/react';
import { currentLanguage } from '..';
import PageIcons from './components/pages/PageIcons';

interface IProps {

}

const ROOT_PATH = '/'

const App: React.FC<IProps> = props => {
    const location = useLocation()
    const { i18n } = useLingui()
    const language = useSelector((app: IApplicationStore) => app.userStore.language)
    const user = useSelector((app: IApplicationStore) => app.userStore.user)

    useEffect(() => {
        let newLanguage = language?language:(user?user.language:currentLanguage)

        if(newLanguage !== i18n._locale) {
            i18n.activate(newLanguage)
        }
    }, [user, language, i18n])

    return (
        <>
            <FadeAnimation animKey={location.pathname === ROOT_PATH?'1':'0'}>
                <Switch location={location}>
                    <Route exact path={`/`} component={PageLogin}/>
                    <Route exact path='/icons' component={PageIcons}/>
                    <Route path='/app'>
                        <AppMain/>
                    </Route>
                </Switch>
            </FadeAnimation>
        </>
    )
}

export default App

