import { useLingui } from '@lingui/react';
import { IGraphData } from '../../../../interfaces/types';
import { Trans } from '@lingui/macro';
import { useMemo } from 'react';
import { getDateRangeTitle } from '../../../../helpers/index';
import { EStatisticsInterval } from '../../../../../reducers/data';
import moment from 'moment';
 
interface IProps {
    graphResults?: IGraphData[],
    filter?: string
}

const TableDataCompareZone = ({graphResults, filter}: IProps) => {
    const lingui = useLingui()

    let dataMap = useMemo(() => {
        let dataMapVal = new Map();

        graphResults?.forEach(r => {
            r.data.forEach(d => {
                // let value = (dataMapVal.has(d.start))? dataMapVal.get(d.start) : new Map()
                // value.set( r.name,  )
                if(!dataMapVal.has(d.start)) {
                    dataMapVal.set(d.start, {
                        count: Number(d.count),
                        name: r.name,
                        from: d.start,
                        to: d.end
                    })
                }
            })
        })

        return dataMapVal 
    }, [graphResults])

    let comparision = useMemo(() => {

        let maxVal = "";
        Array.from(dataMap.keys()).forEach(v => {
            if(maxVal < v) {
                maxVal = v
            }
        })
        let maxDateVal = new Map()
        dataMap.forEach((v, k) =>{
            let countVal = (dataMap.get(maxVal).count / v.count) - 1
            let color = "color_black"
            let status = "N/A"
            if( k !== maxVal ) {
                color = (countVal < 0 )? "color_red" : "color_green"
                status = ""
            }
            maxDateVal.set( k, {
                "count": countVal *100,
                "color": color,
                "status": status
            } )
        })
        
        return maxDateVal
    }, [dataMap])

    return (
        <div className="table_scroll mgt-20 pdb-40">
            <div className="pdb-10">
            </div>
            <table className="table">
                <thead>
                    <tr>
                    <th><Trans>Dátum - časový interval</Trans></th>
                    {graphResults?.map( (gr,i) => (
                        <th key={i}> {gr.name}</th>
                    ))}
                    <th><Trans>Porovnanie</Trans> (%)</th>
                    </tr>
                </thead>
                <tbody>
                    {Array.from(dataMap.keys()).map( (gr,val) => (
                        <tr key={gr}>
                            <td>{moment(gr).format("YYYY-MM-DD")}</td>
                                <td key={0}>
                                    <div className="table_drop narrow">
                                        <span>{dataMap.get(gr).count}</span>
                                        <div className="drop">
                                            <div><Trans>Názov</Trans>: <strong>{dataMap.get(gr).name}</strong></div>
                                            <div><Trans>Deň</Trans>: <strong>{moment(gr).format('dddd')}</strong></div>
                                            <div><Trans>Dátum</Trans>: <strong>{getDateRangeTitle(dataMap.get(gr).from, dataMap.get(gr).to, EStatisticsInterval.day, lingui.i18n)}</strong></div>
                                            <div><Trans>Stav</Trans>: <strong className="color_green">{dataMap.get(gr).count}</strong></div>
                                        </div>
                                    </div>
                                </td>
                            <td><strong className={comparision.get(gr).color}>{comparision.get(gr).status === "N/A"? "N/A" : comparision.get(gr).count.toFixed(2) + "%" }</strong>
                            </td>
                        </tr>
                    ))}

                </tbody>
            </table>    
        </div>
    )
};

export default TableDataCompareZone
