import Chart from "react-apexcharts";
import { numberWithSpaces, getRandomColors } from '../../../../helpers/index';
import { useLingui } from '@lingui/react';
import { IGraphContentData } from '../../../../interfaces/types';

 
const skGraph = require("apexcharts/dist/locales/sk")
const csGraph = require("apexcharts/dist/locales/cs")
const deGraph = require("apexcharts/dist/locales/de")
const enGraph = require("apexcharts/dist/locales/en")

interface IProps {
    graphData: IGraphContentData,
    graphTitle: string
}

const PieGraph = ({graphData, graphTitle}: IProps) => {
    const lingui = useLingui()

    return (
        <Chart 
            options={{
                legend: {
                    show: true,
                    position: 'bottom'
                },
                title: {
                    text: ''
                },
                chart: {
                    toolbar: {
                        show: true,
                        tools: {
                            download: '<span><svg class="sico wh16"><use xlink:href="/assets/layout/icons.svg#s_save"></use></svg>'
                        }
                    },
                    locales: [skGraph, csGraph, enGraph, deGraph],
                    defaultLocale: lingui.i18n._locale
                },
                tooltip: {
                    onDatasetHover: {
                        highlightDataSeries: true
                    },
                    y: {
                        formatter: (val) => {
                            return numberWithSpaces(val)
                        }
                    }
                },
                dataLabels: {
                    // formatter: (val) => {
                    //     return numberWithSpaces((val as any))
                    // },
                    // offsetY: 125,
                    // offsetX: 100,
                    // offsetY: 50,

                    // enabled: true
                },
                colors: getRandomColors(graphData.categories.length),
                labels: graphData.categories
            }} 
            series={graphData.series} 
            type="pie" 
            height={450} 
        />
    )
};

export default PieGraph
