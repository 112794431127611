import { useState, useEffect } from 'react';
import { useLingui } from '@lingui/react';
import { IGraphContentData } from '../../../../interfaces/types';
import getAttendanceStats from '../../../../../reducers/api/getAttendanceStats';
import { IAttendanceFilter, EStatisticsType } from '../../../../../reducers/data';
import { parseGraphDataAttendance } from '../../../../helpers/index';
import Loader from '../../Loader';
import ColumnGraph from '../../statistics/graphs/ColumnGraph';
import PieGraph from '../../statistics/graphs/PieGraph';
import { t } from '@lingui/macro';

interface IProps {
    filter: IAttendanceFilter
}

const DashboardGraph = (props: IProps) => {
    const lingui = useLingui()

    const [loading, setLoading] = useState(false)
    // const [graphResults, setGraphResults] = useState<IGraphData[]|undefined>()
    const [graphData, setGraphData] = useState<IGraphContentData>({
        series: [],
        categories: []
    })
    
    useEffect(() => {
        setLoading(true)
        let isMounted = true

        getAttendanceStats({
            ...props.filter,
            graphView: props.filter.type === EStatisticsType.table?undefined:props.filter.graphView
        })
            .then(res => {

                if(isMounted) {
                    setLoading(false)

                    let resObject = parseGraphDataAttendance(res?res:[], props.filter, lingui.i18n)
                    setGraphData(resObject)
                }
            })
            .catch(e => {
                if(isMounted) {
                    setLoading(false)
                    setGraphData({
                        series: [],
                        categories: []
                    })
                }
            })
        return () => { isMounted = false }
    }, [lingui.i18n, props.filter])
    
    let graphTitle = t`Dátum`

    return (
        <>
            <Loader value={loading}/>
            {/* <ColumnGraph graphTitle={graphTitle} graphData={graphData}/> */}
            {props.filter.type === EStatisticsType.column && (
                <ColumnGraph graphTitle={graphTitle} graphData={graphData}/>
            )}
            {(props.filter.type === EStatisticsType.cake) && (
                <PieGraph graphTitle={graphTitle} graphData={graphData}/>
            )}
        </>
    )
};

export default DashboardGraph