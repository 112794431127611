import { Trans } from '@lingui/macro';
import { useState, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import useOnClickOutside from '../../../hooks/useOnClickOutside';
import { useSelector, useDispatch } from 'react-redux';
import { IApplicationStore } from '../../../../store/index';
import Loader from '../Loader';
import { EDataActions, setDataStore } from '../../../../reducers/data';
interface IProps {
}

const ShoppingCentersMaps: React.FC<IProps> = () => {
    const dispatch = useDispatch()

    const ref = useRef<HTMLDivElement>(null)
    
    const [ show, setShow ] = useState(false)

    const loading = useSelector((store: IApplicationStore) => store.loadingStore.loadingMarts)
    const marts = useSelector((store: IApplicationStore) => store.dataStore.marts)
    const selectedMart = useSelector((store: IApplicationStore) =>store.dataStore.selectedMapMart)
    
    useOnClickOutside(ref, () => {
        setShow(false)
    })

    if(marts.length === 0) {
        return null
    }

    return (
        <div className="box_mapdrop">
            <div className={`box_mapdrop_top ${show?'active':''}`} ref={ref} onClick={() => setShow(true)}>
                <h2 className="title_h4"><Trans>Mapa centra</Trans></h2>
                <Loader value={loading}/>

                {typeof selectedMart !== 'undefined'?(
                    <>
                        <NavLink 
                            to={`/app/map/${selectedMart.id}`} 
                            role="button" 
                            className="box_mapdrop_button toggle-parent-class"
                            onClick={(e) => {
                                e.preventDefault()
                                if(show) {
                                    e.stopPropagation()
                                    setShow(false)
                                }
                            }}
                        >
                            <strong>{selectedMart.name}</strong>
                            <span>{selectedMart.address}</span>
                            <svg className="sico"><use xlinkHref="/assets/layout/icons.svg#s_ar1_d" /></svg>
                        </NavLink>

                        <div className="box_mapdrop_inner">
                            <div className="box_mapdrop_drop">
                                {marts.map((sc, i) =>(
                                    <NavLink
                                        to={`/app/map/${sc.id}`} 
                                        className="box_mapdrop_item" 
                                        key={i}
                                        onClick={(e) => {
                                            e.preventDefault()
                                            e.stopPropagation()
                                            setShow(false)
                                            dispatch(setDataStore(EDataActions.SET_SELECTED_MAP_MART, sc))
                                        }}
                                    >
                                        <strong>{sc.name}</strong>
                                        <span>{sc.address}</span>     
                                    </NavLink>
                                ))}
                            </div>
                        </div>
                    </>
                ):null}
            </div>
            <div className="box_mapdrop_bottom">
                <a href={ typeof selectedMart !== 'undefined'? selectedMart.map : "/"} className="box_mapdrop_dl">
                    <span><Trans>Stiahnuť mapu</Trans></span>
                    <svg className="sico"><use xlinkHref="/assets/layout/icons.svg#s_dl" /></svg>
                </a>
            </div>                    
        </div>
    )
}

export default ShoppingCentersMaps