
const PageIcons = () => {
    let iconsNames = [
        's_logo',
        's_list',
        's_map',
        's_menu',
        's_menu2',
        's_person',
        's_plus',
        's_rep',
        's_save',
        's_send',
        's_set',
        's_off',
        's_stats',
        's_info',
        's_cal',
        's_ar1_d',
        's_ar1_u',
        's_ar1_r',
        's_ar1_l',
        's_ar2_d',
        's_ar2_u',
        's_ar2_l',
        's_ar2_r',
        's_ar3_d',
        's_ar3_u',
        's_ar3_l',
        's_ar3_r',
        's_bell',
        's_clock',
        's_cross',
        's_dash',
        's_dl',
        's_flag',
        's_hclock',
        's_help',
        's_inf',
        's_check1',
        's_check2',
        's_edit',
        's_delete',
    ]
    return (
        <div className="page-icons">
            {iconsNames.map((n,i) => (
                <svg className="sico" key={i}>
                    <use xlinkHref={`/assets/layout/icons.svg#${n}`}/>
                </svg>
            ))}
        </div>
    )
};

export default PageIcons
