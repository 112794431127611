import { Reducer } from 'redux'

export interface ILoadingStore {
    loadingLogin: boolean,
    loadingMarts: boolean,
    loadingReports: boolean,
    loadingActivities: boolean,
    loadingCouting: boolean,
    loadingMartMap: boolean,
}

export enum ELoadingActions {
    SET_LOADING_LOGIN = 'SET_LOADING_LOGIN',
    SET_LOADING_MARTS = 'SET_LOADING_MARTS',
    SET_LOADING_MART_MAP = 'SET_LOADING_MART_MAP',
    SET_LOADING_REPORTS = 'SET_LOADING_REPORTS',
    SET_LOADING_ACTIVITIES = 'SET_LOADING_ACTIVITIES',
    SET_LOADING_COUNTING = 'SET_LOADING_COUNTING'
}

export function setLoadingStore(type: ELoadingActions, value: boolean) {
    return { type: type, payload: value }
}

const initialState: ILoadingStore = {
    loadingLogin: false,
    loadingMarts: false,
    loadingReports: false,
    loadingActivities: false,
    loadingCouting: false,
    loadingMartMap: false,
}

const reducer: Reducer<ILoadingStore> = (state = initialState, action) => {
    switch (action.type) {
        case ELoadingActions.SET_LOADING_LOGIN:
            state = {
                ...state,
                loadingLogin: action.payload
            }
            break
        case ELoadingActions.SET_LOADING_MARTS:
            state = {
                ...state,
                loadingMarts: action.payload
            }
            break
        case ELoadingActions.SET_LOADING_REPORTS:
            state = {
                ...state,
                loadingReports: action.payload
            }
            break
        case ELoadingActions.SET_LOADING_ACTIVITIES:
            state = {
                ...state,
                loadingActivities: action.payload
            }
            break
        case ELoadingActions.SET_LOADING_COUNTING:
            state = {
                ...state,
                loadingCouting: action.payload
            }
            break
        case ELoadingActions.SET_LOADING_MART_MAP:
            state = {
                ...state,
                loadingMartMap: action.payload
            }
            break
    }

    return state
}

export { reducer as loadingReducer }
