const Footer = () => {
    return (
        <footer className="footer wrapper">
            <div>Copyright © 2012 - {new Date().getFullYear()} - VISITOR, s.r.o., All Right Reserved</div>
            {/* Designed by <a href="https://darencurtis.sk" target="_blank" rel="noreferrer" title="daren&amp;curtis" className="text_ul_hover"><strong>daren&amp;curtis</strong></a> */}
        </footer>
    )
};

export default Footer
