import Chart from "react-apexcharts";
import { numberWithSpaces, getRandomColors } from '../../../../helpers/index';
import { useLingui } from '@lingui/react';
import { IGraphContentData } from '../../../../interfaces/types';
import { t } from '@lingui/macro';
 
const skGraph = require("apexcharts/dist/locales/sk")
const csGraph = require("apexcharts/dist/locales/cs")
const deGraph = require("apexcharts/dist/locales/de")
const enGraph = require("apexcharts/dist/locales/en")

interface IProps {
    graphData: IGraphContentData,
    graphTitle: string
}

const ColumnGraph = ({graphData, graphTitle}: IProps) => {
    const lingui = useLingui()
    return (
        <Chart 
            options={{
                legend: {
                    show: true,
                    position: 'bottom'
                },
                plotOptions: {
                    bar: {
                        borderRadius: 5,
                        dataLabels: {
                            position: 'top'
                        },
                        distributed: true
                    }
                },
                chart: {
                    toolbar: {
                        show: true,
                        tools: {
                            download: '<span><svg class="sico wh16"><use xlink:href="/assets/layout/icons.svg#s_save"></use></svg>'
                        }
                    },
                    locales: [skGraph, csGraph, enGraph, deGraph],
                    defaultLocale: lingui.i18n._locale
                },
                dataLabels: {
                    enabled: true,
                    formatter: (val:any) => {
                        return numberWithSpaces(val)
                    },
                    offsetY: -20,
                    style: {
                    fontSize: '12px',
                    colors: ["#304758"]
                    }
                },
                colors: getRandomColors(graphData.categories.length),
                stroke: {
                    show: false
                },
                xaxis: {
                    labels: {
                        // show: false,
                        style: {
                            fontSize: "0px"
                        }
                        
                        // formatter: (value) => {
                        //     return ""
                        // }
                    }
                    // title: {
                    //     text: graphTitle
                    // }
                    // categories: graphData.categories,
                },
                // tooltip: {
                //     formatter: function(strval:string) {
                //         return strval
                //       }
                // },
                grid: {
                    strokeDashArray: 10,
                },
                yaxis: {
                    title: {
                        text: t`Počet návštevníkov`
                    },
                    labels: {
                        formatter: (val) => {
                            return numberWithSpaces(val)
                        }
                    },
                },
                fill: {
                    opacity: 1
                },
                tooltip: {
                    y: {
                        formatter: function (val:number) {
                        return numberWithSpaces(val)
                        }
                    // },
                    // x: {
                    //     formatter: (value:string) => {
                    //     return value
                    //     }
                    }
                },
                labels: graphData.categories
            }} 
            series={graphData.series} 
            type="bar" 
            height={450} 
        />
    )
};

export default ColumnGraph
