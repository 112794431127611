import { useState, useRef } from 'react';
import useOnClickOutside from '../../../hooks/useOnClickOutside';
import { Trans } from '@lingui/macro';
import { EStatisticsDateType, EStatisticsInterval, EStatisticsType } from '../../../../reducers/data';
import SmallIntervalTitle from '../statistics/SmallIntervalTitle';

interface IProps {
    selectedDate: EStatisticsDateType,
    selectedGraph: EStatisticsType,
    selected?: EStatisticsInterval,
    allowed: EStatisticsInterval[],
    onSelect: (type: EStatisticsInterval) => void
}

const FilterIntervalType: React.FC<IProps> = (props) => {
    const ref = useRef<HTMLDivElement>(null)
    
    const [show, setShow] = useState(false)
    
    useOnClickOutside(ref, () => {
        setShow(false)
    })

    let allowed: EStatisticsInterval[] = props.allowed

    if([EStatisticsType.cake, EStatisticsType.column].includes(props.selectedGraph) || props.selectedDate === EStatisticsDateType.custom) {
        allowed = []
    } else {
        switch(props.selectedDate) {
            case EStatisticsDateType.day:
                allowed = allowed.filter(a => [EStatisticsInterval.min15, EStatisticsInterval.min30, EStatisticsInterval.min60, EStatisticsInterval.day].includes(a))
                break
            case EStatisticsDateType.week:
                allowed = allowed.filter(a => [EStatisticsInterval.day, EStatisticsInterval.week].includes(a))
                break
            case EStatisticsDateType.month:
                allowed = allowed.filter(a => [EStatisticsInterval.day, EStatisticsInterval.week, EStatisticsInterval.month].includes(a))
                break
            case EStatisticsDateType.year:
                allowed = allowed.filter(a => [EStatisticsInterval.day, EStatisticsInterval.week, EStatisticsInterval.month].includes(a))
                break
        }
    }
    
    if(allowed.length === 0) {
        return null
    }
    
    return (
        <div className={`element_drop basic to_center col_default offset ${show?'active':''}`} ref={ref}>
            <button 
                type="button" 
                className="btn_basic element_drop_button"
                onClick={() => {
                    setShow(!show)
                }}
            >
                <span><Trans>Interval</Trans></span>
                <svg className="sico drop"><use xlinkHref="/assets/layout/icons.svg#s_ar1_d" /></svg>
            </button>  
            <div className="element_drop_inner">
                <div className="element_drop_arrow"></div>
                <div className="element_drop_content btn_interval_list pd-20">
                    {allowed.map((g,i) => (
                        <label key={i} className="btn_interval_label">
                            <input 
                                type="radio" 
                                name={`interval__${i}`} 
                                checked={props.selected === g} 
                                onChange={(e) => {
                                    if(e.target.checked) {
                                        props.onSelect(g)
                                    }
                                    setShow(false)
                                }}
                            />
                            <div className="btn_interval dflex">
                                <svg className="sico"><use xlinkHref="/assets/layout/icons.svg#s_hclock" /></svg>   
                                <span><SmallIntervalTitle type={g}/></span> 
                            </div>
                        </label>
                    ))}
                </div>
            </div>                       
        </div> 
    )
}

export default FilterIntervalType