import { Trans, t } from '@lingui/macro';
import { useRouteMatch } from 'react-router-dom';
import Tabs, { ITab } from '../global/Tabs';
import FadeAnimation from "../global/FadeAnimation";
import Attendance from '../global/statistics/Attendance';
import { useState, useEffect } from 'react';
import { ILocation, IZone, EPermission } from '../../interfaces/types';
import { useSelector } from 'react-redux';
import { IApplicationStore } from '../../../store/index';
import { getRandomColors } from '../../helpers/index';
import DwellTime from "../global/statistics/DwellTime";
import Occupancy from '../global/statistics/Occupancy';
import Heatmap from '../global/statistics/Heatmap';
import usePermission from '../../hooks/usePermission';
import Gender from '../global/statistics/Gender';

interface IProps {
}

export enum EStatisticsTabs {
    averageAttendance = 'average-attendance',
    attendance = 'attendance',
    dwell = 'dwell',
    occupancy = 'occupancy',
    heatmap = 'heatmap',
    retail = 'retail',
    gender = 'gender'
}

export interface ILocationExt extends ILocation {
    color: string
}

export interface IZoneExt extends IZone {
    color: string
}

const PageStatistics = (props: IProps) => {
    const match = useRouteMatch<{tabId?: string}>()
    
    const marts = useSelector((store: IApplicationStore) => store.dataStore.selectedMarts)
    let allowStats = usePermission([EPermission.stats])
    let allowDwell = usePermission([EPermission.dwell])
    let allowOccupancy = usePermission([EPermission.occupancy])
    let allowHeatmap = usePermission([EPermission.heatmap])
    let allowGender = usePermission([EPermission.gender])
    let disDwell = usePermission([EPermission.disDwell])
    let disOccupancy = usePermission([EPermission.disOccupancy])
    let disHeatmap = usePermission([EPermission.disHeatmap])   
    let disGender = usePermission([EPermission.disGender])

    const [localities, setLocalities] = useState<ILocationExt[]>([])
    const [zones, setZones] = useState<IZoneExt[]>([])

    useEffect(() => {

    }, [allowStats, allowDwell, allowOccupancy, allowHeatmap, allowGender])

    useEffect(() => {
        let newLoc: ILocationExt[] = []
        let newZon: IZoneExt[] = []

        let cnt = 0

        marts.forEach(m => {
            cnt += m.location.length
            cnt += m.zone.length
        })

        let colors = getRandomColors(cnt)

        let i = 0

        marts.forEach(m => {
            m.location.forEach(l => {
                newLoc.push({
                    ...l,
                    name: m.name + ' - ' + l.name,
                    color: colors[i++]
                })
            })
            m.zone.forEach(z => {
                newZon.push({
                    ...z,
                    name: m.name + ' - ' + z.name,
                    color: colors[i++]
                })
            })
        })

        setZones(newZon)
        setLocalities(newLoc)
    }, [marts])

    const currentTab = match.params.tabId

    const allowedTabs: ITab[] = []

    if(allowStats) {
        allowedTabs.push({
            link: '/app/statistics',
            tabId: null,
            title: t`Návštevnosť`
        })
    }

    if(allowDwell || disDwell ) {
        allowedTabs.push({
            link: '/app/statistics/' + EStatisticsTabs.dwell,
            tabId: EStatisticsTabs.dwell,
            title: t`Dwell Time`,
            disabled: (disDwell)
        })
    }

    if(allowOccupancy || disOccupancy) {
        allowedTabs.push({
            link: '/app/statistics/' + EStatisticsTabs.occupancy,
            tabId: EStatisticsTabs.occupancy,
            title: t`Occupancy`,
            disabled: (disOccupancy)
        })
    }

    if(allowHeatmap || disHeatmap) {
        allowedTabs.push({
            link: '/app/statistics/' + EStatisticsTabs.heatmap,
            tabId: EStatisticsTabs.heatmap,
            title: t`Heatmap`,
            disabled: (disHeatmap)
        })
    }

    if(allowGender || disGender ) {
        allowedTabs.push({
            link: '/app/statistics/' + EStatisticsTabs.gender,
            tabId: EStatisticsTabs.gender,
            title: t`Gender`,
            disabled: (disGender)
        })
    }

    return (
        <>
            <div className="grid small-space align-start pdt-40">
                <h1 className="title_h1 col_default">
                    VISITOR <span className="text_w400"><Trans>štatistiky</Trans></span>
                </h1>
                <div className="icon_info wh24 col_default">
                    <svg className="sicofill"><use xlinkHref="/assets/layout/icons.svg#s_info" /></svg>
                </div>
            </div>

            <p className="text_basic mgt-20 wmax460">
                <Trans>Pre ďalšiu prácu s dátami je k dispozícii detailné štatistické spracovanie ukazovateľov do viacerých výstupov a formátov. Stačí si iba vybrať.</Trans>
            </p>

            {marts.length === 0 ? (
                <div className="box mgt-40 mgb-30 pd-40 pdl-25lg pdr-25lg">
                    <div className="message-wrapper">
                        <div className="message-content message-content--warning">
                            <Trans>Musíte vybrať aspoň jedno obchodné centrum.</Trans>
                        </div>
                    </div>
                </div>
            ):(
                <>
                    <div className="tabs mgt-40">
                        <Tabs
                            tabs={allowedTabs}
                        />             
                    </div>

                    <FadeAnimation animKey={currentTab?currentTab:''}>
                        <div className="box mgt-40 mgb-30 pd-40 pdl-25lg pdr-25lg statistics">    
                            {(((currentTab === EStatisticsTabs.attendance) || (typeof currentTab === 'undefined')) && allowStats) && (
                                <Attendance
                                    zones={zones}
                                    localities={localities}
                                />        
                            )}
                            {((currentTab === EStatisticsTabs.gender) && allowGender) && (
                                <Gender
                                    zones={zones.filter( i => i.gender === true )}
                                    localities={localities.filter( i => i.gender === true )}
                                />        
                            )}
                            {((currentTab === EStatisticsTabs.dwell) && allowDwell) && (
                                <DwellTime
                                    zones={zones}
                                    localities={localities}
                                />        
                            )}

                            {((currentTab === EStatisticsTabs.occupancy) && allowOccupancy) && (
                                <Occupancy
                                    zones={zones}
                                />        
                            )}
                            {((currentTab === EStatisticsTabs.heatmap) && allowHeatmap) && (
                                <Heatmap
                                    zones={zones}
                                    localities={localities}
                                />        
                            )}
                        </div>
                    </FadeAnimation>
                </>
            )}            
        </>
    )
};

export default PageStatistics