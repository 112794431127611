import { Trans } from "@lingui/macro"
import ShoppingCenters from "./sidebar/ShoppingCenters"
import UserTooltip from "./sidebar/UserTooltip"
import ShoppingCentersMaps from './sidebar/ShoppingCentersMaps';
import Activities from "./sidebar/Activities";
import { useDispatch } from 'react-redux';
import { EDataActions, setDataStore } from '../../../reducers/data';
import AuthPermission from "../wrappers/AuthPermission";
import { EPermission } from "../../interfaces/types";

interface IProps {
    show: boolean,
    setShow: (val: boolean) => void
}

const SideBar: React.FC<IProps> = (props) => {
    const dispatch = useDispatch()

    return (
        <div className="sidebar">

            <button 
                type="button" 
                className="sidebar_top btn_back show_xlm remove-body-class text_w500 pd-20 pdl-25" 
                onClick={() => props.setShow(!props.show)}
            >
                <span><Trans>Skryť menu</Trans></span>    
                <svg className="sico"><use xlinkHref="/assets/layout/icons.svg#s_ar2_r" /></svg>
            </button>  

            <div className="pd-10 pdt-0 mgt-20 mgt-0xl">
                <UserTooltip/>
            </div>
            
            <div className="pd-30 pdt-0">
                <ShoppingCenters/>
            </div>

            <AuthPermission permissions={[EPermission.map]}>
                <div className="pd-10 pdt-5">
                    <ShoppingCentersMaps/>
                </div>
            </AuthPermission>

            <AuthPermission permissions={[EPermission.calendar]}>
                <div className="pd-30 pdt-30">
                    <Activities/>
                </div>
            </AuthPermission>

            <div className="sidebar_bottom pd-30 pdt-0">
                <button 
                    className="btn_basic toggle-body-class" 
                    onClick={() => dispatch(setDataStore(EDataActions.SHOW_CONTACT, true))}
                >
                    <svg className="sico"><use xlinkHref="/assets/layout/icons.svg#s_help" /></svg>
                    <span><Trans>Potrebujem pomoc</Trans></span>
                </button>
            </div>    
        </div>
    )
}

export default SideBar