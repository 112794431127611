import config from '../../config';
import { handleErrors } from '../../js/helpers/handleErrors';
import { store } from '../../store';

export function contactForm (
    name: string,
    mail: string,
    subject: string,
    body: string,
    phone?: string
) {
    return fetch(config.api.support, {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${store.getState().userStore.token}`
            },
            credentials: 'include',
            body: JSON.stringify({
                name: name,
                mail: mail,
                subject: subject,
                body: body,
                phone: phone
            })
        })
            .then(handleErrors)
            .then(res => {
                if(res.status === 204) {
                    return true
                } else {
                    return false
                }
            })
            .catch((error: any) => {
                return false
            })
}

export default contactForm