import config from '../../config';
import { handleErrors } from '../../js/helpers/handleErrors';
import { store } from '../../store';

export function deleteEvent (
    id: number
) {
    return fetch(config.api.deleteEvent, {
            method: 'DELETE',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${store.getState().userStore.token}`
            },
            credentials: 'include',
            body: JSON.stringify({
                id: id
            })
        })
            .then(handleErrors)
            .then(res => {
                if(res.status === 204) {
                    return true
                } else {
                    return false
                }
            })
            .catch((error: any) => {
                return false
            })
}

export default deleteEvent