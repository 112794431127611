import config from '../../config';
import { handleErrors } from '../../js/helpers/handleErrors';
import { setLoadingStore, ELoadingActions } from '../loading';
import { EUserActions, setUserStore } from '../user';

export function loginUser (
    username: string,
    password: string
) {
    return (dispatch: any) => {
        dispatch(setLoadingStore(ELoadingActions.SET_LOADING_LOGIN, true))

        fetch(config.api.login, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            // credentials: 'include',
            body: JSON.stringify({
                username: username,
                password: password
            })
        })
            .then(handleErrors)
            .then(res => res.json())
            .then(json => {
                dispatch(setLoadingStore(ELoadingActions.SET_LOADING_LOGIN, false))
                dispatch(setUserStore(
                    EUserActions.LOGIN_USER,
                    {
                        user: {
                            name: json.name,
                            email: json.email,
                            tel: json.tel,
                            language: json.language,
                            permission: json.permission
                        },
                        token: json.token
                    }
                ))

            })
            .catch((error: any) => {
                dispatch(setLoadingStore(ELoadingActions.SET_LOADING_LOGIN, false))
                dispatch(setUserStore(
                    EUserActions.LOGIN_USER,
                    {
                        user: null,
                        token: null
                    }
                ))
            })
    }
}

export default loginUser