import { Reducer } from 'redux'
import { IUser } from '../js/interfaces/types';

export interface IUserStore {
    user: IUser | null,
    language: string | null,
    token: string | null
}

export enum EUserActions {
    LOGIN_USER = 'LOGIN_USER',
    LOGOUT_USER = 'LOGOUT_USER',
    UPDATE_USER = 'UPDATE_USER',
    SET_LANGUAGE = 'SET_LANGUAGE'
}

const initialState: IUserStore = {
    user: null,
    language: null,
    token: null
}

export function setUserStore(type: EUserActions, value?: any) {
    return { type: type, payload: value }
}

const reducer: Reducer<IUserStore> = (state = initialState, action) => {
    switch (action.type) {
        case EUserActions.LOGIN_USER:
            state = {
                ...state,
                user: {
                    ...action.payload.user,
                    language: 'sk'
                },
                token: action.payload.token
            }
            break
        case EUserActions.SET_LANGUAGE:
            state = {
                ...state,
                language: action.payload
            }
            break
        case EUserActions.LOGOUT_USER:
            state = {
                ...state,
                user: null,
                token: null,
                language: null
            }
            break
        case EUserActions.UPDATE_USER:
            state = {
                ...state,
                user: action.payload
            }
            break
    }

    return state
}

export { reducer as userReducer }
