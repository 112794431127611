import Chart from "react-apexcharts";
import { numberWithSpaces } from '../../../../helpers/index';
import { useLingui } from '@lingui/react';
import { IGraphContentData } from '../../../../interfaces/types';
import moment from 'moment';
import { t } from '@lingui/macro';
 
const skGraph = require("apexcharts/dist/locales/sk")
const csGraph = require("apexcharts/dist/locales/cs")
const deGraph = require("apexcharts/dist/locales/de")
const enGraph = require("apexcharts/dist/locales/en")

interface IProps {
    graphData: IGraphContentData,
    graphTitle: string
}

const HeatmapGraph = ({graphData, graphTitle}: IProps) => {
    const lingui = useLingui()
    console.log(graphData)

    return (
        <Chart 
            options={{
                legend: {
                    show: false,
                    position: 'bottom'
                },
                chart: {
                    toolbar: {
                        show: true,
                        tools: {
                            download: '<span><svg class="sico wh16"><use xlink:href="/assets/layout/icons.svg#s_save"></use></svg>'
                        }
                    },
                    locales: [skGraph, csGraph, enGraph, deGraph],
                    defaultLocale: lingui.i18n._locale
                },
                dataLabels: {
                    enabled: true,
                    style: {
                        colors: ['#000000']
                    }
                },
                colors: ["#008FFB"],
                // colors: getRandomColors(graphData.categories.length),
                stroke: {
                    show: false
                },
                xaxis: {
                    title: {
                        text: "Hodiny"
                    },
                    // enabled: true,
                    labels: {
                        show: false,
                        // enabled: true,
                        formatter: (val) => {
                            return moment(val).local().format('HH')
                        }
                    }
                },
                yaxis: {
                    title: {
                        text: "Dni"
                    },
                    labels: {
                        formatter: (val) => {
                            return numberWithSpaces(val)
                        }
                    },
                },
                tooltip: {
                    y: graphData.series.map(s => {
                            return {
                                title: {
                                    formatter: function (val) {
                                        return val
                                    }
                                },
                                formatter: (val) => {
                                    return numberWithSpaces(val)
                                }
                            }
                        }),
                    x: {
                        formatter: (val) => {
                            return moment(val).local().format('HH:mm')
                        }
                    }
                },
                
                labels: graphData.categories
            }} 
            series={graphData.series} 
            type="heatmap" 
            height={450} 
        />
    )
};

export default HeatmapGraph