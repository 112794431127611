import Chart from "react-apexcharts";
import { numberWithSpaces } from '../../../../helpers/index';
import { useLingui } from '@lingui/react';
import { IGraphContentData } from '../../../../interfaces/types';
import { t } from '@lingui/macro';
 
const skGraph = require("apexcharts/dist/locales/sk")
const csGraph = require("apexcharts/dist/locales/cs")
const deGraph = require("apexcharts/dist/locales/de")
const enGraph = require("apexcharts/dist/locales/en")

interface IProps {
    graphData: IGraphContentData,
    isPercentage: boolean
}

const ColumnStackedGraph = ({graphData, isPercentage}: IProps) => {
    const lingui = useLingui()

    return (
        <Chart 
            options={{
                chart: {
                    stacked: true,
                    toolbar: {
                        show: true,
                        tools: {
                            download: '<span><svg class="sico wh16"><use xlink:href="/assets/layout/icons.svg#s_save"></use></svg>'
                        }
                    },
                    locales: [skGraph, csGraph, enGraph, deGraph],
                    defaultLocale: lingui.i18n._locale
                },
                plotOptions: {
                    bar: {
                        borderRadius: 5,
                        horizontal: false
                    },
                },
                stroke: {
                    show: false
                },
                grid: {
                    strokeDashArray: 10,
                },
                xaxis: {
                    categories: graphData.categories
                },
                legend: {
                    show: true,
                    position: 'bottom'
                },
                dataLabels: {
                    formatter: (val: any) => {
                        return isPercentage?val + '%':numberWithSpaces(val)
                    }
                },
                fill: {
                    opacity: 1
                },
                tooltip: {
                    y: {
                        formatter: function (val:number) {
                            return isPercentage?val + '%':numberWithSpaces(val)
                        }
                    }
                },
                yaxis: {
                    title: {
                        text: t`Počet návštevníkov`
                    },
                    labels: {
                        formatter: (val) => {
                            return numberWithSpaces(val)
                        }
                    },
                }
            }} 
            series={graphData.series} 
            type="bar" 
            height={450} 
        />
    )
};

export default ColumnStackedGraph
