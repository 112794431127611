import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import FormContact from '../forms/FormContact';
import FadeAnimation from './FadeAnimation';
import Menu from './Menu';
import SideBar from './SideBar';
import TopBar from './TopBar';

interface IProps {

}

const Layout: React.FC<IProps> = props => {
    const location = useLocation()
    const [ openedMenu, setOpenedMenu ] = useState(false)
    const [ openedSidebar, setOpenedSidebar ] = useState(false)
    
    //Slice for animation (animate just first 2 levels of path)
    let animPath = location.pathname.split('/')

    animPath = animPath.slice(0,3)

    return (
        <>
            <div className="svghide_cont">
                <svg className="svghide" viewBox="0 0 1 1">
                    <clipPath id="clip_tl" clipPathUnits="objectBoundingBox">
                        <path d="M0.002,1l-0.002,0l0,0.1l1.1,0l0,-1.1l-0.1,0c0,0.552 -0.447,0.999 -0.998,1Z" />
                    </clipPath>   
                    <clipPath id="clip_tr" clipPathUnits="objectBoundingBox">
                        <path d="M0.998,1l0.002,0l0,0.1l-1.1,0l0,-1.1l0.1,0c0,0.552 0.447,0.999 0.998,1Z" />
                    </clipPath>    
                    <clipPath id="clip_bl" clipPathUnits="objectBoundingBox">
                        <path d="M0.002,0l-0.002,0l0,-0.1l1.1,-0l0,1.1l-0.1,0c0,-0.552 -0.447,-0.999 -0.998,-1Z" />
                    </clipPath>   
                    <clipPath id="clip_br" clipPathUnits="objectBoundingBox">
                        <path d="M0.998,-0l0.002,-0l0,-0.1l-1.1,-0l0,1.1l0.1,-0c0,-0.552 0.447,-0.999 0.998,-1Z" />
                    </clipPath>   
                </svg>
            </div>     

            <button 
                type="button" 
                className="header_overlay remove-body-class" 
                onClick={() => {
                    document.body.classList.remove('show_header')
                    document.body.classList.remove('show_sidebar')
                }}
            ></button>

            <Menu
                show={openedMenu}
                setShow={(val) => {
                    setOpenedMenu(val)
                    setOpenedSidebar(false)
                    if(val) {
                        document.body.classList.add('show_header')
                    } else {
                        document.body.classList.remove('show_header')
                    }
                }}
            />

            <SideBar 
                show={openedSidebar}
                setShow={(val) => {
                    setOpenedSidebar(val)
                    setOpenedMenu(false)
                    if(val) {
                        document.body.classList.add('show_sidebar')
                    } else {
                        document.body.classList.remove('show_sidebar')
                    }
                }}
            />

            <TopBar
                setShowMenu={(val) => {
                    setOpenedMenu(val)
                    setOpenedSidebar(false)
                    if(val) {
                        document.body.classList.add('show_header')
                    } else {
                        document.body.classList.remove('show_header')
                    }
                }}
                setShowSideBar={(val) => {
                    setOpenedSidebar(val)
                    setOpenedMenu(false)
                    if(val) {
                        document.body.classList.add('show_sidebar')
                    } else {
                        document.body.classList.remove('show_sidebar')
                    }
                }}
            />

            <div className="container">

                <FadeAnimation animKey={animPath.join('/')}>
                    {props.children}
                </FadeAnimation>

            </div>

            <FormContact/>
        </>
        
    )
}

export default Layout