import { Trans } from "@lingui/macro";
import { EPermission } from "../../interfaces/types";
import DashboardCount from '../global/dashboard/DashboardCount';
import DashboardLocationPie from "../global/dashboard/DashboardLocationPie";
import DashboardOccupancy from "../global/dashboard/DashboardOccupancy";
import DashboardLocationTable from "../global/dashboard/DashboardLocationTable";
import DashboardZoneTable from "../global/dashboard/DashboardZoneTable";
import DashboardView from "../global/dashboard/DashboardView";
import AuthPermission from "../wrappers/AuthPermission";

interface IProps {
}

const PageDashboard = (props: IProps) => {
    
    return (
        <>
            <div className="grid big-space justify-between">
                <div className="col2 col1_mdm pdt-40">
                    <h1 className="title_h1">
                        VISITOR <span className="text_w400"><Trans>dashboard</Trans></span>
                        <div className="icon_info wh24 diblock v_top">
                            <svg className="sicofill"><use xlinkHref="/assets/layout/icons.svg#s_info" /></svg>
                        </div>
                    </h1>
        
                    <p className="text_basic wmax500 mgt-20">
                        <Trans>Poskytuje rýchly grafický prehľad kľúčových informácií a najnovších aktualizácií, ktoré využívate najčastejšie. Umožní Vám efektívne a promptne rozhodovať sa ako postupovať ďalej.</Trans>
                    </p>  
                </div>

                <AuthPermission permissions={[EPermission.live]}>
                    <div className="col2 col1_mdm wmax500 pdt-40">
                        <DashboardCount/>
                    </div>
                </AuthPermission>
            </div>

            <AuthPermission permissions={[EPermission.dashboard]}>

            <div className="box mgt-60 mgb-30 pd-40 pdl-25lg pdr-25lg">
                <DashboardView/>
                <DashboardZoneTable/>
            </div>
            <div className="box mgt-60 mgb-30 pd-40 pdl-25lg pdr-25lg">
                <DashboardLocationPie/>
                <DashboardLocationTable/>
            </div>
            <div className="box mgt-60 mgb-30 pd-40 pdl-25lg pdr-25lg">
                <DashboardOccupancy/>
            </div>

                {/* <div className="box mgt-60 mgb-30 pd-40 pdl-25lg pdr-25lg">    
                    
                    <div className="grid flex-nowrap-md align-center">
                        <h2 className="title_h3 col_fill">Porovnanie návštevnosti lokalít po hodinách</h2>
                        <div className="col_default">
                            <a href="#" title="" className="btn_action text_nowrap text_w700">
                                <span>Stiahnuť</span>  
                                <svg className="sico wh16"><use xlinkHref="/assets/layout/icons.svg#s_dl" /></svg>                                                      
                            </a>       
                        </div>
                    </div>

                    <div className="grid flex-nowrap-lg small-space align-start pdt-15 pdb-25">

                        <div className="col_fill">
                            <div className="grid small-space">
                            
                                <div className="element_drop basic to_left small col_default offset">
                                    <button type="button" className="btn_basic element_drop_button toggle-parent-className" data-className="active">
                                        <span>Deň</span>
                                        <svg className="sico drop"><use xlinkHref="/assets/layout/icons.svg#s_ar1_d" /></svg>
                                    </button>  
                                    <div className="element_drop_inner">
                                        <div className="element_drop_arrow"></div>
                                        <div className="element_drop_content pd-20">
                                            <img src="assets/layout/cal.png" alt="" />
                                        </div>
                                    </div>                      
                                </div>

                            </div>
                        </div>                    

                        <div className="text_right col_default col1_lgm order-first-lgm self-center offset">
                            <a href="#" title="" className="btn_action text_nowrap text_w500">
                                <svg className="sico wh16"><use xlinkHref="/assets/layout/icons.svg#s_save" /></svg>
                                <span>Uložiť</span>                            
                            </a>        
                        </div>
                    </div>               
                    
                    <hr />

                    <div className="grid small-space flex-nowrap-lg pdt-20">
                        <div className="col_default col1_lgm text_s16 text_w500 self-center mgt-10">Filtrácie:</div>

                        <div className="btn_filter_list col_default col1_lgm">
                            <a href="#" className="btn_filter">
                                <span>Graf XY</span>
                                <svg className="sico"><use xlinkHref="/assets/layout/icons.svg#s_cross" /></svg>
                            </a>    
                            <a href="#" className="btn_filter">
                                <span>30.3.2017</span>
                                <svg className="sico"><use xlinkHref="/assets/layout/icons.svg#s_cross" /></svg>
                            </a>
                            <a href="#" className="btn_filter">
                                <span>17:30 - 22:30</span>
                                <svg className="sico"><use xlinkHref="/assets/layout/icons.svg#s_cross" /></svg>
                            </a>
                        </div>
                    </div> 
                    
                </div>     */}
            </AuthPermission>
        </>
    )
};

export default PageDashboard
