import config from '../../config';
import { handleErrors } from '../../js/helpers/handleErrors';
import { store } from '../../store';
import { IHeatMapFilter } from '../data';

export function getHeatTable (
    filter: IHeatMapFilter
) {
    let dataToSend: {
        date: {
            from: string,
            to: string
        }[],
        zones?: number[],
        locations?: number[],
    } = {
        date: filter.dateRanges,
        zones: filter.zones,
        locations: filter.localities
    }

    return fetch(config.api.heatTable, {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${store.getState().userStore.token}`
            },
            credentials: 'include',
            body: JSON.stringify(dataToSend)
        })
            .then(handleErrors)
            .then(res => res.json())
            .then(res => {
                return res
            })
            .catch((error: any) => {
                return null
            })
}

export default getHeatTable