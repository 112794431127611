import { useLingui } from '@lingui/react';
import { IGraphData } from '../../../../interfaces/types';
import { Trans } from '@lingui/macro';
import { useMemo } from 'react';
import { getDateRangeTitle } from '../../../../helpers/index';
import moment from 'moment';
import { EStatisticsInterval } from '../../../../../reducers/data';
 
interface IProps {
    graphResults?: IGraphData[],
    filter?: string
}

const TableDataCompare = ({graphResults, filter}: IProps) => {
    const lingui = useLingui()

    let dataMap = useMemo(() => {
        let dataMapVal = new Map();

        graphResults?.forEach(r => {
            r.data.forEach(d => {
                let value = (dataMapVal.has(r.name))? dataMapVal.get(r.name) : new Map()
                value.set( d.start, {
                    count: Number(d.count),
                    name: r.name,
                    from: d.start,
                    to: d.end
                } )
                dataMapVal.set(r.name, value)
            })
        })

        return dataMapVal
    }, [graphResults])

    let dates = useMemo(() => {
        let datesVal: string[] = []
        dataMap.forEach((v, k) => {
            Array.from(v.keys()).forEach( key => {
                if(!datesVal.includes( String(key))) {
                    datesVal.push(String(key))
                }
            })
        })

        return datesVal
    }, [dataMap])


    let comparision = useMemo(() => {
        let comparisionValue = new Map()
        dataMap.forEach((v, k) => {
            let tempData: Number[] = []
            dates.forEach( date => {
                if( v.has( date ) ) {
                    tempData.push( v.get(date).count )
                } else {
                    tempData.push(0)
                }
            })
                let status = ( tempData[0] === 0 )? "N/A" : ""
                let countVal = (( Number(tempData[1]) / Number(tempData[0]) ) - 1)
                let color = "color_black"
                if(status === "") {
                    color = (countVal < 0)? "color_red" : "color_green"
                }

                comparisionValue.set( k, {
                    status: status,
                    count: countVal * 100,
                    color: color
                })

        })

        return comparisionValue
    }, [dataMap, dates])

    return (
        <div className="table_scroll mgt-20 pdb-40">
            <div className="pdb-10">
            </div>
            <table className="table">
                <thead>
                    <tr>
                    <th><Trans>Lokalita / Zóna</Trans></th>
                    {dates.map( (gr,i) => (
                        <th key={i}> {moment(gr).format("YYYY-MM-DD")}</th>
                    ))}
                    <th><Trans>Porovnanie</Trans> (%)</th>
                    </tr>
                </thead>
                <tbody>
                    {Array.from(dataMap.keys()).map( (gr,val) => (
                        <tr key={gr}>
                            <td>{gr}</td>
                            {dates.map( (dateVal,j) => (
                                <td key={j}>
                                    <div className="table_drop narrow">
                                        <span>{dataMap.get(gr).has(dateVal)? dataMap.get(gr).get(dateVal).count : 0}</span>
                                        <div className="drop">
                                            <div><Trans>Názov</Trans>: <strong>{gr}</strong></div>
                                            <div><Trans>Deň</Trans>: <strong>{moment(dateVal).format('dddd')}</strong></div>
                                            <div><Trans>Dátum</Trans>: <strong>{dataMap.get(gr).has(dateVal)? getDateRangeTitle(dataMap.get(gr).get(dateVal).start, dataMap.get(gr).get(dateVal).end, EStatisticsInterval.day, lingui.i18n) : getDateRangeTitle(dateVal, dateVal, EStatisticsInterval.day, lingui.i18n)}</strong></div>
                                            <div><Trans>Stav</Trans>: <strong className="color_green">{dataMap.get(gr).has(dateVal)? dataMap.get(gr).get(dateVal).count : 0}</strong></div>
                                        </div>
                                    </div>
                                </td>
                            ))}
                            <td><strong className={comparision.get(gr).color}>{comparision.get(gr).status === "N/A"? "N/A" : comparision.get(gr).count.toFixed(2) + "%" }</strong>
                            </td>
                        </tr>
                    ))}

                </tbody>
            </table>    
        </div>
    )
};

export default TableDataCompare
