import { useState, useEffect } from 'react';
import Loader from '../../Loader';
import { IHeatMapFilter } from '../../../../../reducers/data';
import getHeatTable from '../../../../../reducers/api/getHeatTable';
import { IGraphContentData } from '../../../../interfaces/types';
import { useLingui } from '@lingui/react';

import { parseGraphData } from '../../../../helpers/index';
import HeatmapGraph from './HeatmapGraph';
import { t } from '@lingui/macro';

interface IProps {
    filter: IHeatMapFilter
}

const GraphHeatmap = (props: IProps) => {
    const lingui = useLingui()

    const [loading, setLoading] = useState(false)
    // const [graphResults, setGraphResults] = useState<IGraphData[]|undefined>()
    const [graphData, setGraphData] = useState<IGraphContentData>({
        series: [],
        categories: []
    })
    
    useEffect(() => {
        setLoading(true)
        let isMounted = true

        console.log(props.filter)  
        getHeatTable(props.filter)
            .then(res => {
                console.log(res)
                // setGraphResults(res)

                if(isMounted) {
                    setLoading(false)

                    let resObject = parseGraphData(res?res:[], lingui.i18n)
                    setGraphData(resObject)
                }
            })
            .catch(e => {
                if(isMounted) {
                    setLoading(false)
                    // setGraphResults(undefined)
                    setGraphData({
                        series: [],
                        categories: []
                    })
                }
            })
        return () => { isMounted = false }
    }, [lingui.i18n, props.filter])
    
    let graphTitle = t`Dátum`

    return (
        <>
            <Loader value={loading}/>
            <HeatmapGraph graphTitle={graphTitle} graphData={graphData} />
        </>
    )
};

export default GraphHeatmap
