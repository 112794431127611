import { useState, useRef } from 'react';
import useOnClickOutside from '../../../hooks/useOnClickOutside';
import { Trans } from '@lingui/macro';
import { ILocationExt, IZoneExt } from '../../pages/PageStatistics';

interface IProps {
    showZones: boolean,
    showLocation: boolean,
    zones: IZoneExt[],
    locations: ILocationExt[],
    selectedZones: number[],
    selectedLocation: number[],
    setLocation?: (locations: number[]) => void,
    setZone?: (zone: number[]) => void
}

const FilterLocalitiesZones: React.FC<IProps> = (props) => {
    const ref = useRef<HTMLDivElement>(null)

    const [show, setShow] = useState(false)

    useOnClickOutside(ref, () => {
        setShow(false)
    })

    const selectAllZones = () => {
        if(typeof props.setZone !== 'undefined') {
            if(props.selectedZones.length === props.zones.length) {
                props.setZone([])
            } else {
                props.setZone(props.zones.map(z => z.id))
            }
        }
    }

    const selectAllLocation = () => {
        if(typeof props.setLocation !== 'undefined') {
            if(props.selectedLocation.length === props.locations.length) {
                props.setLocation([])
            } else {
                props.setLocation(props.locations.map(z => z.id))
            }
        }
    }

    return (
        <div className={`element_drop basic to_left small col_default offset ${show?'active':''}`} ref={ref}>
            <button 
                type="button" 
                className="btn_basic element_drop_button"
                onClick={() => {
                    setShow(!show)
                }}
            >
                <span><Trans>Zóny/Lokality</Trans></span>
                <svg className="sico drop"><use xlinkHref="/assets/layout/icons.svg#s_ar1_d" /></svg>
            </button>  
            <div className="element_drop_inner">
                <div className="element_drop_arrow"></div>
                <div className="element_drop_content pd-15">
                    {props.showZones && (
                        <div className="label_check_list wmin190">
                            <div className="label_check_head">
                                <strong><Trans>Zóny</Trans></strong>
                                <button 
                                    type="button" 
                                    className="label_check_toggle"
                                    onClick={() => selectAllZones()}
                                >
                                    <Trans>Vybrať všetky</Trans>
                                </button>
                            </div> 
                            
                            {props.zones.map((l,j) => (
                                <label className="label_check" key={j}>
                                    <input 
                                        type="checkbox" 
                                        value={l.id} 
                                        onChange={(e) => {
                                            let checked = e.target.checked

                                            if(typeof props.setZone !== 'undefined') {
                                                if(checked) {
                                                    props.setZone(props.selectedZones.filter(s => s !== l.id).concat([l.id]))
                                                } else {
                                                    props.setZone(props.selectedZones.filter(s => s !== l.id))
                                                }
                                            }
                                        }}
                                        checked={props.selectedZones.includes(l.id)}
                                    />
                                    <span>
                                        <i style={{backgroundColor: l.color}}/>
                                        <strong>{l.name}</strong>
                                    </span>     
                                </label>
                            ))}
                        </div>
                    )}
                    
                    {props.showLocation && (
                        <div className="label_check_list wmin190">
                            <div className="label_check_head">
                                <strong><Trans>Lokality</Trans></strong>
                                <button 
                                    type="button" 
                                    className="label_check_toggle"
                                    onClick={() => selectAllLocation()}
                                >
                                    <Trans>Vybrať všetky</Trans>
                                </button>
                            </div> 
                            {props.locations.map((l,j) => (
                                <label className="label_check" key={j}>
                                    <input 
                                        type="checkbox" 
                                        value={l.id} 
                                        onChange={(e) => {
                                            let checked = e.target.checked

                                            if(typeof props.setLocation !== 'undefined') {
                                                if(checked) {
                                                    props.setLocation(props.selectedLocation.filter(s => s !== l.id).concat([l.id]))
                                                } else {
                                                    props.setLocation(props.selectedLocation.filter(s => s !== l.id))
                                                }
                                            }
                                        }}
                                        checked={props.selectedLocation.includes(l.id)}
                                    />
                                    <span>
                                        <i style={{backgroundColor: l.color}}/>
                                        <strong>{l.name}</strong>
                                    </span>     
                                </label>
                            ))}
                        </div>
                    )}
                </div>
            </div>                      
        </div> 
    )
}

export default FilterLocalitiesZones