import config from '../../config';
import { handleErrors } from '../../js/helpers/handleErrors';
import { store } from '../../store';
import { ELoadingActions, setLoadingStore } from '../loading';
import { EDataActions, setDataStore } from '../data';

export function getComingEvents (
    language: string,
    marts: number[]
) {

    return (dispatch: any) => {
        if(marts.length === 0) {
            return null
        }
        
        dispatch(setLoadingStore(ELoadingActions.SET_LOADING_ACTIVITIES, true))
        
        fetch(config.api.events, {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${store.getState().userStore.token}`
            },
            credentials: 'include',
            body: JSON.stringify({
                language: language,
                mart: marts
            })
        })
            .then(handleErrors)
            .then(res => res.json())
            .then(json => {
                dispatch(setLoadingStore(ELoadingActions.SET_LOADING_ACTIVITIES, false))
                dispatch(setDataStore(EDataActions.GET_ACTIVITIES, json))

            })
            .catch((error: any) => {
                dispatch(setLoadingStore(ELoadingActions.SET_LOADING_ACTIVITIES, false))
                dispatch(setDataStore(EDataActions.GET_ACTIVITIES, []))
            })
    }
}

export default getComingEvents