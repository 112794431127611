import { Trans, t } from '@lingui/macro';
import {FormEvent, useEffect, useState} from 'react';

import FadeAnimation from '../global/FadeAnimation';
import passwordUserChange from '../../../reducers/api/passwordUserChange';

interface IProps {
}

const FormPasswordChange = (props: IProps) => {    
    const [oldPassword, setOldPassword] = useState<string|null>(null)
    const [newPassword, setNewPassword] = useState<string|null>(null)
    const [newPassword2, setNewPassword2] = useState<string|null>(null)
    const [loading, setLoading] = useState(false)
    
    const [ message, setMessage ] = useState<string|null>('')

    useEffect(() => {
        let newMessage = ''

        if(oldPassword && oldPassword.length === 0) {
            newMessage = t`Musíte zadať staré heslo.`
        }

        if(newPassword && newPassword.length === 0) {
            newMessage = t`Musíte zadať nové heslo.`
        }

        if(newPassword2 && newPassword2.length === 0) {
            newMessage = t`Musíte zopakovať nové heslo.`
        }

        if(newPassword && newPassword2 && newPassword !== newPassword2) {
            newMessage = t`Nové heslá sa nezhodujú.`
        }

        setMessage(newMessage)
    }, [oldPassword, newPassword, newPassword2])

    const submitForm = (e: FormEvent<HTMLFormElement>) => {
        e.stopPropagation()
        e.preventDefault()
        
        if(message === '' && oldPassword && newPassword) {
            setLoading(true)
            
            passwordUserChange(
                oldPassword,
                newPassword
            ).then(res => {
                if(res) {
                    setMessage(null)
                    setNewPassword(null)
                    setNewPassword2(null)
                    setOldPassword(null)
                } else {
                    setMessage(t`Zadali ste správne staré heslo?`)
                }
                setLoading(false)
            }).catch((e) => {
                console.log(e)
                
                setMessage(t`Niečo sa pokazilo. Kontaktujte prosím administrátora.`)
                setLoading(false)
            })
        }
    }

    return (
        <form onSubmit={(e) => submitForm(e)}>
            <div className="grid">
                <div className="col3 col1_lgm mgt-30">
                    <label className="label_basic"><Trans>Staré heslo</Trans></label>  
                    <input 
                        type="password" 
                        value={oldPassword?oldPassword:''} 
                        onChange={(e) => setOldPassword(e.target.value)}
                        placeholder="**********"
                        className="input_text" 
                        required
                    />   
                </div>

                <div className="col3 col1_lgm mgt-30">
                    <label className="label_basic"><Trans>Nové heslo</Trans></label>  
                    <input 
                        type="password" 
                        value={newPassword?newPassword:''} 
                        onChange={(e) => setNewPassword(e.target.value)}
                        placeholder="**********"
                        className="input_text" 
                        required
                    />  
                </div>

                <div className="col3 col1_lgm mgt-30">
                    <label className="label_basic"><Trans>Potvrdenie nového hesla</Trans></label>  
                    <input 
                        type="password" 
                        value={newPassword2?newPassword2:''} 
                        onChange={(e) => setNewPassword2(e.target.value)}
                        placeholder="**********"
                        className="input_text" 
                        required
                    /> 
                </div>
                <div className="message-wrapper">
                    <FadeAnimation animKey={message?message:''}>
                        {message === null ? (
                            <div className="message-content">
                                <Trans>Heslo bolo úspešne zmenené.</Trans>
                            </div>
                        ):(
                            <>
                                {message.length > 0?(
                                    <div className="message-content message-content--warning">
                                        {message}
                                    </div>
                                ):<span/>}
                            </>
                        )}
                    </FadeAnimation>
                </div>
                <div className="col1 mgt-30 text_center">
                    <button type="submit" className={`btn_basic ${loading?'btn_loading':''}`}>
                        <svg className="sico"><use xlinkHref="/assets/layout/icons.svg#s_save" /></svg>
                        <span><Trans>Uložiť</Trans></span>
                    </button>
                </div>
            </div>
        </form>
    )
};

export default FormPasswordChange
