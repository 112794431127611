import { useState, useEffect } from 'react';
import { IAttendanceFilter, EStatisticsInterval, EStatisticsGraphView, EStatisticsType } from '../../../../../reducers/data';
import getAttendanceStats from '../../../../../reducers/api/getAttendanceStats';
import Loader from '../../Loader';
import { parseGraphDataAttendance } from '../../../../helpers/index';
import { useLingui } from '@lingui/react';
import ColumnGraph from './ColumnGraph';
import { IGraphContentData, IGraphData } from '../../../../interfaces/types';
import PieGraph from './PieGraph';
import XYGraph from './XYGraph';
import TableGraph from './TableGraph';
import { t } from '@lingui/macro';

interface IProps {
    filter: IAttendanceFilter
}

const GraphAttendance = (props: IProps) => {
    const lingui = useLingui()

    const [loading, setLoading] = useState(false)
    const [graphResults, setGraphResults] = useState<IGraphData[]|undefined>()
    const [graphData, setGraphData] = useState<IGraphContentData>({
        series: [],
        categories: []
    })
    
    useEffect(() => {
        setLoading(true)
        let isMounted = true

        getAttendanceStats({
            ...props.filter,
            graphView: props.filter.type === EStatisticsType.table?undefined:props.filter.graphView
        })
            .then(res => {
                setGraphResults(res)

                if(isMounted) {
                    setLoading(false)

                    let resObject = parseGraphDataAttendance(res?res:[], props.filter, lingui.i18n)
                    setGraphData(resObject)
                }
            })
            .catch(e => {
                if(isMounted) {
                    setLoading(false)
                    setGraphResults(undefined)
                    setGraphData({
                        series: [],
                        categories: []
                    })
                }
            })
        return () => { isMounted = false }
    }, [lingui.i18n, props.filter])

    let graphTitle = t`Multi`
    let isTime = false;

    if(props.filter.graphView === EStatisticsGraphView.date) {
        graphTitle = t`Dátum`
    } else if (props.filter.graphView === EStatisticsGraphView.zone) {
        graphTitle = t`Zóna`
    }
    let yTitle =  t`Počet návštevníkov`
    let dataToSend: {
        date: {
            from: string,
            to: string
        }[],
        type: EStatisticsType,
        interval?: EStatisticsInterval,
        locations?: number[],
        zones?: number[],
        group?: EStatisticsGraphView
    } = {
        date: props.filter.dateRanges,
        type: props.filter.type,
        interval: props.filter.interval,
        locations: props.filter.localities,
        zones: props.filter.zones,
        group: props.filter.graphView
    }

    let objJsonStr = JSON.stringify(dataToSend);
    let objJsonB64 = Buffer.from(objJsonStr).toString("base64");

    return (
        <>
            <Loader value={loading}/>
            {props.filter.type === EStatisticsType.column && (
                <ColumnGraph graphTitle={graphTitle} graphData={graphData}/>
            )}
            {(props.filter.type === EStatisticsType.cake) && (
                <PieGraph graphTitle={graphTitle} graphData={graphData}/>
            )}
            {(props.filter.type === EStatisticsType.xy) && (
                <XYGraph graphTitle={graphTitle} graphData={graphData} interval={props.filter.interval} isTime={isTime} yTitle={yTitle} />
            )}
            {(props.filter.type === EStatisticsType.table) && (
                <TableGraph graphResults={graphResults} filter={objJsonB64} interval={props.filter.interval}/>
            )}
        </>
    )
};

export default GraphAttendance
