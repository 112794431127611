import config from '../../config';
import { handleErrors } from '../../js/helpers/handleErrors';
import { store } from '../../store';
import { ELoadingActions, setLoadingStore } from '../loading';
import { EDataActions, setDataStore } from '../data';
import moment from 'moment';

export function getCounting (
    mart: number
) {
    return (dispatch: any) => {
        dispatch(setLoadingStore(ELoadingActions.SET_LOADING_COUNTING, true))
        
        fetch(config.api.counting, {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${store.getState().userStore.token}`
            },
            credentials: 'include',
            body: JSON.stringify({
                mart: mart
            })
        })
            .then(handleErrors)
            .then(res => res.json())
            .then(json => {
                dispatch(setLoadingStore(ELoadingActions.SET_LOADING_COUNTING, false))
                json.forEach((retVal: any) => {
                    if(retVal.mart === mart) {
                        dispatch(setDataStore(EDataActions.SET_COUNTING, {
                            date: moment(),
                            value: retVal.count
                        }))
                    }
                })
            })
            .catch((error: any) => {
                dispatch(setLoadingStore(ELoadingActions.SET_LOADING_COUNTING, false))
                dispatch(setDataStore(EDataActions.SET_COUNTING, undefined))
            })
    }
}

export default getCounting