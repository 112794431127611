import { useState } from "react"
import { useLingui } from '@lingui/react';
import { useDispatch } from 'react-redux';
import { EUserActions, setUserStore } from '../../../reducers/user';
import languageIcon from '../../../assets/layout/language.svg';

interface IProps {
    setShowMenu: (val: boolean) => void,
    setShowSideBar: (val: boolean) => void
}

const TopBar: React.FC<IProps> = (props) => {
    const dispatch = useDispatch()

    const lingui  = useLingui()

    const [showLanguages, setShowLanguages] = useState(false)

    const languages = ['sk','en']

    return (
        <div className="topbar">
            <div className="topbar_inner">

                <div className="topbar_left">
                    <button 
                        type="button" 
                        className="btn_icon toggle-body-class show_xlm" 
                        onClick={() => props.setShowMenu(true)}
                    >
                        <svg className="sicofill"><use xlinkHref="/assets/layout/icons.svg#s_menu" /></svg>
                    </button>
    
                    <a href="https://visitor.sk" target="_blank" rel="noreferrer" className="topbar_logo show_xlm">
                        <svg className="sicofill"><use xlinkHref="/assets/layout/icons.svg#s_logo" /></svg>
                    </a>
                </div>

                <div className="topbar_right">
                    {/* <div className={`element_drop notification ${showNotifications?'active':''}`}>
                        <button 
                            type="button" 
                            className="btn_icon element_drop_button toggle-parent-class unread" 
                            onClick={() => {
                                setShowNotifications(!showNotifications)
                                setShowLanguages(false)
                            }}
                        >
                            <svg className="sicofill"><use xlinkHref="/assets/layout/icons.svg#s_bell" /></svg>
                        </button>  
                        <div className="element_drop_inner">
                            <div className="element_drop_arrow"></div>
                            <div className="element_drop_content btn_noti_list">
                                <a href="/" className="btn_noti unread">
                                    <strong>Generovať report: Navštevnosť - REPORT: tyždenný</strong>
                                    <span>Pred 5 minutami</span>
                                </a>

                                <a href="/" className="btn_noti">
                                    <strong>Generovať report: Navštevnosť - REPORT: tyždenný</strong>
                                    <span>14:20</span>
                                </a>

                                <a href="/" className="btn_noti unread">
                                    <strong>Generovať report: Navštevnosť - REPORT: tyždenný</strong>
                                    <span>Pred 5 minutami</span>
                                </a>
                            </div>
                        </div>                      
                    </div> */}
                    <div className={`element_drop lang ${showLanguages?'active':''}`}>
                        <button 
                            type="button" 
                            className="btn_icon element_drop_button toggle-parent-class" 
                            onClick={() => {
                                setShowLanguages(!showLanguages)
                                // setShowNotifications(false)
                            }}
                        >
                            <img src={languageIcon} alt={'language_icon'} />
                        </button>
                        <div className="element_drop_inner">
                            <div className="element_drop_arrow"></div>
                            <div className="element_drop_content">
                                {languages.map((l,i) => {
                                    return (
                                        <a 
                                            href="/" 
                                            title="" 
                                            className={lingui.i18n._locale === l?'active':''}
                                            key={i}
                                            onClick={(e) => {
                                                e.preventDefault()
                                                e.stopPropagation()

                                                dispatch(setUserStore(EUserActions.SET_LANGUAGE, l))
                                            }}
                                        >
                                            {l.toUpperCase()}
                                        </a>
                                    )
                                })} 
                            </div>
                        </div>
                    </div>
                    <button 
                        type="button" 
                        className="btn_icon show_xlm toggle-body-class" 
                        onClick={() => props.setShowSideBar(true)}
                    >
                        <svg className="sicofill"><use xlinkHref="/assets/layout/icons.svg#s_menu" /></svg>
                    </button>                  
                </div>
            </div>
        </div>
    )
}

export default TopBar