import { Reducer } from 'redux'
import { INotificationFull, INotification } from '../js/interfaces/types';
import { EDataActions } from './data';

export interface INotificationsStore {
    floatingNotificationId: number,
    floatingNotifications: INotificationFull[]
}

export enum ENotificationsActions {
    ADD_FLOATING_NOTIFICATION = 'ADD_FLOATING_NOTIFICATION',
    REMOVE_FLOATING_NOTIFICATION = 'REMOVE_FLOATING_NOTIFICATION',
    UPDATE_NOTIFICATIONS = 'UPDATE_NOTIFICATIONS'
}

const initialState: INotificationsStore = {
    floatingNotificationId: 1,
    floatingNotifications: []
}

export function addFloatingNotification(payload: INotification) {
    return { type: ENotificationsActions.ADD_FLOATING_NOTIFICATION, payload }
}

export  function updateNotifications(notifications: INotificationFull[]) {

    let newNotifications = new Array<INotificationFull>()

    notifications.map((value: INotificationFull, index: number) => {
        let newDate = new Date()

        let diff = Number(newDate.getTime() - new Date(value.validFrom).getTime())
        
        if(diff < 4000) {
            value.goingToShow = true
        } else if (diff > 4000) {
            value.goingToHide = true
        }

        if(diff < 4700) { //More then 5.2s
            newNotifications.push(value)
        }

        return null
    })

    return { type: ENotificationsActions.UPDATE_NOTIFICATIONS, payload: newNotifications }
}

export function deleteFloatingNotification(payload: number) {
    return { type: ENotificationsActions.REMOVE_FLOATING_NOTIFICATION, payload }
}

const reducer: Reducer<INotificationsStore> = (state = initialState, action) => {

    switch(action.type) {
        case ENotificationsActions.ADD_FLOATING_NOTIFICATION:
            let newNotification: INotificationFull = action.payload

            newNotification.id = state.floatingNotificationId+1
            newNotification.validFrom = new Date()
            newNotification.goingToShow = true
            newNotification.goingToHide = false

            state = {
                ...state,
                floatingNotifications: [
                    ...state.floatingNotifications,
                    newNotification
                ],
                floatingNotificationId: state.floatingNotificationId+1
            }
            break
        case ENotificationsActions.REMOVE_FLOATING_NOTIFICATION:
            let index = state.floatingNotifications.findIndex((notif: INotificationFull) => notif.id === action.payload)
            
            if(typeof index !== 'undefined') {
                state =  {
                    ...state,
                    floatingNotifications: [
                        ...state.floatingNotifications.slice(0, index),
                        ...state.floatingNotifications.slice(index+1)
                    ]
                }
            }
            break
        case ENotificationsActions.UPDATE_NOTIFICATIONS:
            state = {
                ...state,
                floatingNotifications: action.payload
            }
            break
        case EDataActions.RESET_STATE:
            state = initialState
            break
    }

    return state
}

export { reducer as notificationsReducer }