import { Trans, t } from '@lingui/macro';
import {FormEvent, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { IApplicationStore } from '../../../store/index';

import loginUser from '../../../reducers/api/loginUser';

interface IProps {
}

const FormLogin = (props: IProps) => {
    const dispatch = useDispatch()

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    
    const loading = useSelector((store: IApplicationStore) => store.loadingStore.loadingLogin)

    const submitForm = (e: FormEvent<HTMLFormElement>) => {
        e.stopPropagation()
        e.preventDefault()
        
        if(email.length && password.length) {
            dispatch(loginUser(email, password))
        }
    }

    return (
        <form onSubmit={(e) => submitForm(e)}>
            <div>
                <label className="label_basic"><Trans>Prihlasovacie meno</Trans></label>  
                <input 
                    type="text" 
                    value={email} 
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder={t`Prihlasovacie meno`} 
                    className="input_text" 
                    required
                />   
            </div>

            <div className="mgt-20">
                <label className="label_basic"><Trans>Heslo</Trans></label>  
                <input 
                    type="password" 
                    value={password} 
                    onChange={(e) => setPassword(e.target.value)} 
                    placeholder="**********" 
                    className="input_text" 
                    required
                />   
            </div>

            <div className="text_center mgt-30">
                <button type="submit" className={`btn_basic ${loading?'btn_loading':''}`}>
                    <span><Trans>Prihlásiť sa</Trans></span>
                    <svg className="sico more"><use xlinkHref="assets/layout/icons.svg#s_ar1_r" /></svg>
                </button>
            </div>
        </form>
    )
};

export default FormLogin
