import { useSelector } from 'react-redux';
import { IApplicationStore } from '../../../../store/index';
import { useMemo } from 'react';
import FadeAnimation from '../FadeAnimation';
import DashboardGraph from "./graphs/DashboardGraph";


interface IProps {
}

const DashboardView = (props: IProps) => {
    // const ref = useRef<HTMLDivElement>(null)

    let errorMessage = ''

    let same = 1

    const filterGraph = useSelector((store: IApplicationStore) => store.dataStore.filterDashboard)

    const graph = useMemo(() => <DashboardGraph filter={filterGraph}/>, [filterGraph])

    return (
        <>
        <div className="mgt-40 loader-wrapper">
                <FadeAnimation animKey={same?'1':'0'}>
                    {same?(
                        <>
                            {(errorMessage.length === 0)?graph:(
                                <>
                                    <div className="message">
                                        <div className="message-content message-content--warning">
                                            {errorMessage}
                                        </div>
                                    </div>
                                </>
                            )}
                        </>
                    ):(
                        <div className="message">
                            {/* <div className="message-content message-content--warning">
                                <Trans>Musíte uložiť filter pre zobrazenie aktuálneho grafu.</Trans>
                            </div> */}
                        </div>
                    )}
                </FadeAnimation>
            </div>
        </>
    )

}

export default DashboardView