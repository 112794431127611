import { Trans, t } from '@lingui/macro';
import {FormEvent, useEffect, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { IApplicationStore } from '../../../store/index';

import FadeAnimation from '../global/FadeAnimation';
import updateUser from '../../../reducers/api/updateUser';
import { EUserActions, setUserStore } from '../../../reducers/user';

interface IProps {
}

const FormUserChange = (props: IProps) => {
    const dispatch = useDispatch()

    const [nameSurname, setNameSurname] = useState<string|null>(null)
    const [phone, setPhone] = useState<string|null>(null)
    const [email, setEmail] = useState<string|null>(null)
    const [loading, setLoading] = useState(false)
    const [ message, setMessage ] = useState<string|null>('')

    const user = useSelector((store: IApplicationStore) => store.userStore.user)

    useEffect(() => {
        if(user) {
            setNameSurname(user.name)
            setPhone(user.tel)
            setEmail(user.email)
        }
    }, [user])
    useEffect(() => {
        let newMessage = ''

        if(nameSurname && nameSurname.length === 0) {
            newMessage = t`Musíte zadať meno a priezvisko.`
        }

        if(phone && phone.length === 0) {
            newMessage = t`Musíte zadať telefón.`
        }

        if(email && email.length === 0) {
            newMessage = t`Musíte zadať e-mail.`
        }

        setMessage(newMessage)
    }, [nameSurname, phone, email])

    const submitForm = (e: FormEvent<HTMLFormElement>) => {
        e.stopPropagation()
        e.preventDefault()
        
        if(message === '' && !loading && nameSurname && phone && email) {
            setLoading(true)
            
            updateUser(
                nameSurname,
                phone,
                email
            ).then(res => {
                if(res) {
                    setMessage('')
                    dispatch(setUserStore(EUserActions.UPDATE_USER, {
                        ...user,
                        email: email,
                        tel: phone,
                        name: nameSurname
                    }))
                } else {
                    setMessage(t`Niečo sa pokazilo. Kontaktujte prosím administrátora.`)
                }
                setLoading(false)
            }).catch((e) => {
                console.log(e)
                setMessage(t`Niečo sa pokazilo. Kontaktujte prosím administrátora.`)
                setLoading(false)
            })
        }
    }

    return (
        <form onSubmit={(e) => submitForm(e)}>
            <div className="grid">
                <div className="col3 col1_lgm mgt-30">
                    <label className="label_basic"><Trans>Meno a priezvisko</Trans></label>  
                    <input 
                        type="text" 
                        value={nameSurname?nameSurname:''} 
                        onChange={(e) => setNameSurname(e.target.value)}
                        placeholder={t`Ján Novák`}
                        className="input_text" 
                        required
                    />   
                </div>

                <div className="col3 col1_lgm mgt-30">
                    <label className="label_basic"><Trans>Telefónne číslo</Trans></label>  
                    <input 
                        type="text" 
                        value={phone?phone:''} 
                        onChange={(e) => setPhone(e.target.value)}
                        placeholder={t`+421 911 123 456`}
                        className="input_text" 
                        required
                    /> 
                </div>

                <div className="col3 col1_lgm mgt-30">
                    <label className="label_basic"><Trans>Vaša e-mailová adresa</Trans></label>  
                    <input 
                        type="email" 
                        value={email?email:''} 
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder={t`@`}
                        className="input_text" 
                        required
                    /> 
                </div>
                <div className="message-wrapper">
                    <FadeAnimation animKey={message?message:''}>
                        {message === null ? (
                            <div className="message-content">
                                <Trans>Osobné údaje boli úspešne aktualizované.</Trans>
                            </div>
                        ):(
                            <>
                                {message.length > 0?(
                                    <div className="message-content message-content--warning">
                                        {message}
                                    </div>
                                ):<span/>}
                            </>
                        )}
                    </FadeAnimation>
                </div>
                <div className="col1 mgt-30 text_center">
                    <button type="submit" className={`btn_basic ${loading?'btn_loading':''}`}>
                        <svg className="sico"><use xlinkHref="/assets/layout/icons.svg#s_save" /></svg>
                        <span><Trans>Uložiť</Trans></span>
                    </button>
                </div>
            </div>
        </form>
    )
};

export default FormUserChange
