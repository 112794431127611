import { handleErrors } from '../../js/helpers/handleErrors';
import config from '../../config';
import { store } from '../../store';

const updateUser = (
    nameSurname: string,
    phone: string,
    email: string
): Promise<any> => {
    return fetch(config.api.user, {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().userStore.token}`
        },
        credentials: 'include',
        body: JSON.stringify({
            name: nameSurname,
            tel: phone,
            mail: email
        })
    })
        .then(handleErrors)
        .then(res => {
            if(res.status === 204) {
                return true
            } else {
                return false
            }
        })
}

export default updateUser