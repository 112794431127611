import config from '../../config';
import { handleErrors } from '../../js/helpers/handleErrors';
import { store } from '../../store';

export function addNewEvent (
    mart: number,
    title: string,
    date: string, 
    description: string
) {
    return fetch(config.api.addEvent, {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${store.getState().userStore.token}`
            },
            credentials: 'include',
            body: JSON.stringify({
                mart: mart,
                title: title,
                date: date,
                description: description
            })
        })
            .then(handleErrors)
            .then(res => {
                if(res.status === 200) {
                    return true
                } else {
                    return false
                }
            })
            .catch((error: any) => {
                return false
            })
}

export default addNewEvent