import config from '../../config';
import { handleErrors } from '../../js/helpers/handleErrors';
import { store } from '../../store';

export function getMonthAttendance (
    month: string,
    marts: number[]
) {
    return fetch(config.api.monthAttendance, {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${store.getState().userStore.token}`
            },
            credentials: 'include',
            body: JSON.stringify({
                mart: marts,
                date: month
            })
        })
            .then(handleErrors)
            .then(res => res.json())
            .then(json => {
                return json

            })
            .catch((error: any) => {
                return []
            })
}

export default getMonthAttendance