import { Trans } from "@lingui/macro";
import { EStatisticsGenderType } from '../../../../reducers/data';

interface IProps {
    type: EStatisticsGenderType
}

const SmallGenderTypeTitle = (props: IProps) => {
    switch(props.type) {
        case EStatisticsGenderType.number: 
            return (<><Trans>Číselná hodnota</Trans></>)
        case EStatisticsGenderType.percentage:
            return (<><Trans>Percentá</Trans></>)
    }
}

export default SmallGenderTypeTitle
