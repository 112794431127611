import config from '../../config';
import { handleErrors } from '../../js/helpers/handleErrors';
import { store } from '../../store';
import { setLoadingStore, ELoadingActions } from '../loading';
import { EDataActions, setDataStore } from '../data';

export function getMapData(marts: number[]) {

    return (dispatch: any) => {
        dispatch(setLoadingStore(ELoadingActions.SET_LOADING_MART_MAP, true))

        if(marts.length === 0) {
            return
        }

        return fetch(config.api.map, {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${store.getState().userStore.token}`
            },
            credentials: 'include',
            body: JSON.stringify({
                mart: marts
            })
        })
            .then(handleErrors)
            .then(res => res.json())
            .then((json) => {
                console.log('response data:', json)
                dispatch(setLoadingStore(ELoadingActions.SET_LOADING_MART_MAP, false))
                dispatch(setDataStore(EDataActions.SET_MART_MAP, json))
            })
            .catch((error: any) => {
                dispatch(setLoadingStore(ELoadingActions.SET_LOADING_MART_MAP, false))
                console.log('error: cannot fetch map data: ', error)
                return [];
            })
    }
}

export default getMapData
