import { useState, useEffect } from 'react';
import { useLingui } from '@lingui/react';
import { IGraphData } from '../../../../interfaces/types';
import getAttendanceStats from '../../../../../reducers/api/getAttendanceStats';
import { IAttendanceFilter, EStatisticsType } from '../../../../../reducers/data';
import Loader from '../../Loader';
import TableDataCompare from './TableDataCompare';
import TableDataCompareZone from './TableDataCompareZone';

interface IProps {
    filter: IAttendanceFilter,
    zone: boolean
}

const DashboardTable = (props: IProps) => {
    const lingui = useLingui()

    const [loading, setLoading] = useState(false)
    const [graphResults, setGraphResults] = useState<IGraphData[]|undefined>()
    
    useEffect(() => {
        setLoading(true)
        let isMounted = true

        getAttendanceStats({
            ...props.filter,
            graphView: props.filter.type === EStatisticsType.table?undefined:props.filter.graphView
        })
            .then(res => {
                setGraphResults(res)
                if(isMounted) {
                    setLoading(false)
                }
            })
            .catch(e => {
                if(isMounted) {
                    setLoading(false)
                    setGraphResults(undefined)
                }
            })
        return () => { isMounted = false }
    }, [lingui.i18n, props.filter])

    return (
        <>
            <Loader value={loading}/>
            {(props.zone === true) && (
                <TableDataCompareZone graphResults={graphResults} filter={""}/>
            )}
            {(props.zone === false) && (
                <TableDataCompare graphResults={graphResults} filter={""}/>
            )}
        </>
    )
};

export default DashboardTable