import { Trans } from "@lingui/macro";
import { EStatisticsInterval } from '../../../../reducers/data';

interface IProps {
    type: EStatisticsInterval
}

const SmallIntervalTitle = (props: IProps) => {
    switch(props.type) {
        case EStatisticsInterval.min15: 
            return (<><Trans>15 minút</Trans></>)
        case EStatisticsInterval.min30:
            return (<><Trans>30 minút</Trans></>)
        case EStatisticsInterval.min60: 
            return (<><Trans>60 minút</Trans></>)
        case EStatisticsInterval.day:
            return (<><Trans>Deň</Trans></>)
        case EStatisticsInterval.week:
            return (<><Trans>Týždeň</Trans></>)
        case EStatisticsInterval.month:
            return (<><Trans>Mesiac</Trans></>)
    }

    return null
}

export default SmallIntervalTitle
