import { Trans, t } from '@lingui/macro';
import FilterLocalitiesZones from '../filters/FilterLocalitiesZones';
import { useSelector, useDispatch } from 'react-redux';
import { IApplicationStore } from '../../../../store/index';
import { EDataActions, EStatisticsDateType, setDataStore, IDwellTimeFilter } from '../../../../reducers/data';
import { ILocationExt, IZoneExt } from '../../pages/PageStatistics';
import FilterDayWeekMonth from '../filters/FilterDayWeekMonth';
import { useState, useEffect, useMemo } from 'react';
import GraphDwellTime from './graphs/GraphDwellTime';
import FadeAnimation from '../FadeAnimation';
import { getFilterDateRangeTitle } from '../../../helpers/index';
import { useLingui } from '@lingui/react';

interface IProps {
    localities: ILocationExt[],
    zones: IZoneExt[]
}

const DwellTime = (props: IProps) => {
    const dispatch = useDispatch()
    const lingui = useLingui()

    const [filter, setFilter] = useState<IDwellTimeFilter>({
        zones: [],
        dateType: EStatisticsDateType.day,
        dateRanges: []
    })

    const filterGraph = useSelector((store: IApplicationStore) => store.dataStore.filterDwell)

    useEffect(() => {
        setFilter(filterGraph)
    }, [filterGraph])

    const graph = useMemo(() => <GraphDwellTime filter={filterGraph}/>, [filterGraph])

    let same = JSON.stringify(filterGraph) === JSON.stringify(filter)

    let errorMessage = ''

    if(filterGraph.zones.length === 0) {
        errorMessage += t`Nie je zadaná zóna ani lokalita.` + ' ' 
    }

    if(filterGraph.dateRanges.length === 0) {
        errorMessage += t`Nie je zadaný dátum.` + ' ' 
    }

    return (
        <>
            <div className="grid align-center">
                <h2 className="title_h3 col_fill"><Trans>Dwell Time</Trans></h2>
            </div>

            <div className="grid flex-nowrap-lg small-space align-start pdt-15 pdb-25">

                <div className="col_fill">
                    <div className="grid small-space">
                        <FilterLocalitiesZones
                            showZones
                            showLocation={false}
                            locations={props.localities}
                            zones={props.zones}
                            selectedZones={filter.zones}
                            selectedLocation={[]}
                            setZone={(zones) => {
                                setFilter({
                                    ...filter,
                                    zones: zones
                                })
                            }}
                        />
    
                        <FilterDayWeekMonth
                            dateType={[EStatisticsDateType.day, EStatisticsDateType.week, EStatisticsDateType.month, EStatisticsDateType.year, EStatisticsDateType.custom]}
                            selectedDateType={filter.dateType}
                            selectedRanges={filter.dateRanges}
                            onSelectedRanges={(ranges) => {
                                setFilter({
                                    ...filter,
                                    dateRanges: ranges
                                })
                            }}
                            onSelectDateType={(type) => {
                                setFilter({
                                    ...filter,
                                    dateType: type,
                                    dateRanges: []
                                })
                            }}
                        />
                    </div>
                </div>                    

                {!same && (
                    <div className="text_right col_default col1_lgm order-first-lgm self-center offset">
                        <a 
                            href="/" 
                            title="" 
                            className="btn_action text_nowrap text_w500"
                            onClick={(e) => {
                                e.preventDefault()

                                dispatch(setDataStore(EDataActions.SET_FILTER_DWELL, filter))
                            }}
                        >
                            <svg className="sico wh16"><use xlinkHref="/assets/layout/icons.svg#s_save" /></svg>
                            <span><Trans>Odoslať</Trans></span>                            
                        </a>        
                    </div>
                )}
            </div>               
            
            <hr />

            <div className="grid small-space flex-nowrap-lg pdt-20">
                <div className="col_default col1_lgm text_s16 text_w500 self-center mgt-10"><Trans>Filtrácie</Trans>:</div>

                <div className="btn_filter_list col_default col1_lgm">
                    {filter.zones.length > 0 ? (
                        <div className="btn_filter_list--wrapper">
                            {filter.zones.map((z,i) => {
                                let zone = props.zones.find(zn => zn.id === z)

                                if(zone) {
                                    return (
                                        <a 
                                            href="/" 
                                            className="btn_filter" 
                                            key={i}
                                            onClick={(e) => {
                                                e.preventDefault()
                                                dispatch(setDataStore(EDataActions.SET_FILTER_DWELL, {
                                                    ...filter,
                                                    zones: filter.zones.filter(l => l !== z)
                                                }))
                                            }}
                                        >
                                            <span>{zone.name}</span>
                                            <svg className="sico"><use xlinkHref="/assets/layout/icons.svg#s_cross" /></svg>
                                        </a>   
                                    )
                                } else {
                                    return null
                                }

                            })}
                        </div>
                    ):null}
                    {filter.dateRanges.length > 0 ? (
                        <div className="btn_filter_list--wrapper">
                            {filter.dateRanges.map((dr, i) => {
                                let text = getFilterDateRangeTitle(dr.from, dr.to, lingui.i18n, filter.dateType)

                                return (
                                    <a 
                                        key={i}
                                        href="/" 
                                        className="btn_filter"
                                        onClick={(e) => {
                                            e.preventDefault()
                                            dispatch(setDataStore(EDataActions.SET_FILTER_DWELL, {
                                                ...filter,
                                                dateRanges: filter.dateRanges.filter(l => !((l.from === dr.from) && (l.to === dr.to)))
                                            }))
                                        }}
                                    >
                                        <span>{text}</span>
                                        <svg className="sico"><use xlinkHref="/assets/layout/icons.svg#s_cross" /></svg>
                                    </a>  
                                )
                            })}
                        </div>
                    ):null}
                </div>
            </div> 

            <div className="mgt-40 loader-wrapper">
                <FadeAnimation animKey={same?'1':'0'}>
                    {same?(
                        <>
                            {(errorMessage.length === 0)?graph:(
                                <>
                                    <div className="message">
                                        <div className="message-content message-content--warning">
                                            {errorMessage}
                                        </div>
                                    </div>
                                </>
                            )}
                        </>
                    ):(
                        <div className="message">
                            {/* <div className="message-content message-content--warning">
                                <Trans>Musíte uložiť filter pre zobrazenie aktuálneho grafu.</Trans>
                            </div> */}
                        </div>
                    )}
                </FadeAnimation>
            </div>
        </>
    )
};

export default DwellTime
