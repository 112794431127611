import { useState, useRef } from 'react';
import useOnClickOutside from '../../../hooks/useOnClickOutside';
import { Trans } from '@lingui/macro';
import { EStatisticsGraphView } from '../../../../reducers/data';
import SmallViewTitle from '../statistics/SmallViewTitle';

interface IProps {
    selected?: EStatisticsGraphView,
    allowed: EStatisticsGraphView[],
    onSelect: (type: EStatisticsGraphView) => void
}

const FilterShowType: React.FC<IProps> = (props) => {
    const ref = useRef<HTMLDivElement>(null)
    
    const [show, setShow] = useState(false)
    
    useOnClickOutside(ref, () => {
        setShow(false)
    })

    let types: {
        image: string,
        type: EStatisticsGraphView
    }[] = []

    if(props.allowed.includes(EStatisticsGraphView.multi)) {
        types.push({
            image: '/assets/layout/z1.png',
            type: EStatisticsGraphView.multi
        })
    }
    
    if(props.allowed.includes(EStatisticsGraphView.zone)) {
        types.push({
            image: '/assets/layout/z2.png',
            type: EStatisticsGraphView.zone
        })
    }

    if(props.allowed.includes(EStatisticsGraphView.date)) {
        types.push({
            image: '/assets/layout/z3.png',
            type: EStatisticsGraphView.date
        })
    }
    
    return (
        <div className={`element_drop basic to_center col_default offset ${show?'active':''}`} ref={ref}>
            <button 
                type="button" 
                className="btn_basic element_drop_button"
                onClick={() => {
                    setShow(!show)
                }}
            >
                <span><Trans>Zobrazenie</Trans></span>
                <svg className="sico drop"><use xlinkHref="/assets/layout/icons.svg#s_ar1_d" /></svg>
            </button>  
            <div className="element_drop_inner">
                <div className="element_drop_arrow"></div>
                <div className="element_drop_content pd-30 pdt-10">
                    <div className="grid flex-nowrap-md">
                        {types.map((g,i) => (
                            <label key={i} className="item_image_label col_equal offset wmin190 wmin150_xlm wmin100_mdm">
                                <input 
                                    type="radio" 
                                    name={"typ_" + g.type} 
                                    checked={props.selected === g.type}
                                    onChange={(e) => {
                                        if(e.target.checked) {
                                            props.onSelect(g.type)
                                        }
                                        setShow(false)
                                    }}
                                />
                                <div className="item_image">
                                    <img src={g.image} alt={g.type} className="item_image_img" />
                                    <div className="item_image_desc">
                                        <span><Trans>Typ</Trans></span>
                                        <strong><SmallViewTitle type={g.type}/></strong>
                                    </div>
                                </div>
                            </label>
                        ))}
                    </div>
                </div>
            </div>                       
        </div> 
    )
}

export default FilterShowType