import { Trans } from "@lingui/macro";
import { EStatisticsType } from '../../../../reducers/data';

interface IProps {
    type: EStatisticsType
}

const SmallTypeTitle = (props: IProps) => {
    switch(props.type) {
        case EStatisticsType.cake: 
            return (<><Trans>Koláčový</Trans></>)
        case EStatisticsType.column:
            return (<><Trans>Stĺpcový</Trans></>)
        case EStatisticsType.xy: 
            return (<><Trans>XY</Trans></>)
        case EStatisticsType.table:
            return (<><Trans>Tabuľka</Trans></>)
    }

    return null
}

export default SmallTypeTitle
