import { useState, useRef } from 'react';
import useOnClickOutside from '../../../hooks/useOnClickOutside';
import moment from 'moment';
import { useLingui } from '@lingui/react';
import { DateRange } from 'react-date-range';
import { enGB as enDate, sk as skDate, de as deDate, cs as csDate } from "date-fns/locale";

interface IProps {
    title: string,
    selected: {
        dayFrom?: string,
        dayTo?: string
    },
    setFilterDateRange: (dayFrom?: string, dayTo?: string) => void
}

const FilterDateRange: React.FC<IProps> = (props) => {
    const lingui = useLingui()

    const ref = useRef<HTMLDivElement>(null)

    const [show, setShow] = useState(false)
    
    useOnClickOutside(ref, () => {
        setShow(false)
    })

    const setRange = props.setFilterDateRange

    let startDate = props.selected.dayFrom?moment(props.selected.dayFrom).toDate():new Date()
    let endDate = props.selected.dayTo?moment(props.selected.dayTo).toDate():null

    let locale = enDate

    switch(lingui.i18n._locale) {
        case 'sk':
            locale = skDate
            break
        case 'de':
            locale = deDate
            break
        case 'cs':
            locale = csDate
            break
    }

    return (
        <div className={`element_drop basic to_left small col_default offset ${show?'active':''}`} ref={ref}>
            <button 
                type="button" 
                className="btn_basic element_drop_button"
                onClick={() => {
                    setShow(!show)
                }}
            >
                <span>{props.title}</span>
                <svg className="sico drop"><use xlinkHref="/assets/layout/icons.svg#s_ar1_d" /></svg>
            </button>  
            <div className="element_drop_inner">
                <div className="element_drop_arrow"></div>
                <div className="element_drop_content pd-10">
                    <DateRange
                        editableDateInputs={true}
                        onChange={item => {
                            setRange(moment(item.selection.startDate).format('YYYY-MM-DD'), moment(item.selection.endDate).format('YYYY-MM-DD'))
                        }}
                        moveRangeOnFirstSelection={false}
                        ranges={[{
                            startDate: startDate,
                            endDate: endDate === null?undefined:endDate,
                            key: 'selection'
                        }]}
                        locale={locale}
                        showDateDisplay={false}
                        rangeColors={['#A81A40', '#A81A40', '#A81A40']	
                    }
                    />
                </div>
            </div>                      
        </div> 
    )
}

export default FilterDateRange