import * as React from 'react';

export enum ENotificationType {
    success = 'success',
    info = 'info',
    warning = 'warning',
    error = 'error'
}

export enum EPermission {
    dashboard = 'dashboard', 
    live = 'live', 
    calendar = 'calendar', 
    map = 'map', 
    report = 'report', 
    stats = 'stats', 
    dwell = 'dwell', 
    occupancy = 'occupancy', 
    heatmap = 'heatmap', 
    gender = 'gender',
    disDwell = 'disDwell',
    disOccupancy = 'disOccupancy',
    disHeatmap = 'disHeatmap',
    disGender = 'disGender'
}

export interface IUser {
    name: string,
    email: string,
    tel: string,
    language: string,
    permission: EPermission[]
}

export interface INotification {
    text: string | React.ReactNode,
    type: ENotificationType,
    showBell?: boolean
}

export interface INotificationFull  extends INotification {
    id: number,
    validFrom: Date,
    goingToHide: boolean,
    goingToShow: boolean
}

export interface ILocation {
    id: number,
    name: string,
    gender: boolean
}

export interface IZone {
    id: number,
    name: string,
    gender: boolean,
    isAttendance: boolean
}

export interface IMart {
    id: number,
    name: string,
    address: string,
    logo: string,
    map: string,
    location: ILocation[],
    zone: IZone[]
}

export interface IReport {
    id: number,
    date: string,
    name: string,
    mart: number,
    type: string,
    typeTitle: string,
    link: string
}

export enum EEventType {
    holliday = 'holliday',
    note = 'note',
    report = 'report'
}
export interface IEvent {
    id: number,
    name: string,
    description: string,
    mart: number,
    date: string,
    type: EEventType,
    link: string
}

export interface IEventDay {
    date: string,
    icon: string,
    value: number,
    event?: IEvent[]
}

export interface IGraphData {
    martName: string,
    name: string,
    data: {
        start: string,
        end: string,
        count: string
    }[]
}

export interface IGraphDataGender {
    martName: string,
    name: string,
    data: {
        start: string,
        end: string,
        count: string,
        gender: 'F'|'M',
        percentage: number
    }[]
}

export interface IGraphContentData {
    series: ({
        name: string,
        data: (number|number[])[]
    }|number)[],
    categories: string[]
}

export interface IGraphHeatmapData {
    series: ({
        name: string,
        data: (number|number[])[]
    }|number)[],
    categories: string[]
}

export interface IMapLocation {
    location_id: number,
    name: string,
    path: string,
    x: BigInteger,
    y: BigInteger,
}

export interface IMapFloor {
    id: number,
    name: string,
    path: string,
    location: IMapLocation[]
}

export interface IMapInfo {
    id: number,
    floor: IMapFloor[]
}

export interface IAttendanceDay {
    date: string,
    value: string
}