import { useState, useRef } from 'react';
import useOnClickOutside from '../../../hooks/useOnClickOutside';

interface IProps {
    title: string,
    values: {
        id: string,
        title: string
    }[],
    selected: {
        id: string,
        title: string
    }[],
    setFilterSelect: (value: {
        id: string,
        title: string
    }[]) => void
}

const FilterReportType: React.FC<IProps> = (props) => {
    const ref = useRef<HTMLDivElement>(null)

    const [show, setShow] = useState(false)

    useOnClickOutside(ref, () => {
        setShow(false)
    })

    return (
        <div className={`element_drop basic to_left small col_default offset ${show?'active':''}`} ref={ref}>
            <button 
                type="button" 
                className="btn_basic element_drop_button"
                onClick={() => {
                    setShow(!show)
                }}
            >
                <span>{props.title}</span>
                <svg className="sico drop"><use xlinkHref="/assets/layout/icons.svg#s_ar1_d" /></svg>
            </button>  
            <div className="element_drop_inner">
                <div className="element_drop_arrow"></div>
                <div className="element_drop_content element_drop_list pd-10">
                    {props.values.map((v,i) => (
                        <a 
                            href="/" 
                            key={i}
                            onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()

                                let isSelected = props.selected.find(s => s.id === v.id)

                                if(typeof isSelected !== 'undefined') {
                                    props.setFilterSelect(props.selected.filter(s => s.id !== v.id))
                                } else {
                                    props.setFilterSelect([...props.selected, v])
                                }
                            }}
                            className={props.selected?.map(s => s.id).includes(v.id)?'active':''}
                        >
                            {v.title}
                        </a>   
                    ))}  
                </div>
            </div>                      
        </div> 
    )
}

export default FilterReportType