import { useLingui } from '@lingui/react';
import { IGraphData, IGraphDataGender } from '../../../../interfaces/types';
import { Trans } from '@lingui/macro';
import { useMemo } from 'react';
import { getDateRangeTitle } from '../../../../helpers/index';
import { EStatisticsInterval } from '../../../../../reducers/data';
import moment from 'moment';
import { store } from '../../../../../store';
 
interface IProps {
    graphResults?: (IGraphDataGender | IGraphData)[],
    filter?: string,
    interval?: EStatisticsInterval,
    isGender?: boolean
}

interface ICell {
    name: string,
    count: string,
    from: string,
    to: string
}

const TableGraph = ({graphResults, filter, interval, isGender}: IProps) => {
    const lingui = useLingui()

    let splitted = useMemo(() => {
        //Split by time
        let splittedVal: {
            start: string,
            end: string,
            values: ICell[]
        }[] = []

        graphResults?.forEach(r => {
            r.data.forEach(d => {
                let was = false

                splittedVal.forEach(s => {
                    if(s.start === d.start && s.end === d.end) {
                        was = true
                        s.values.push({
                            count: d.count,
                            name: r.name,
                            from: d.start,
                            to: d.end
                        })
                    }
                })

                if(!was) {
                    splittedVal.push({
                        start: d.start,
                        end: d.end,
                        values: [{
                            count: d.count,
                            name: r.name,
                            from: d.start,
                            to: d.end
                        }]
                    })
                }
            })
        })

        // filter.

        return splittedVal
    }, [graphResults])

    let urlExport = "https://api.clientportal.visitor.sk/table/?id=" + filter + "&token=" + store.getState().userStore.token

    return (
        <div className="table_scroll mgt-20 pdb-40">
            <div className="pdb-10">
            <a href={urlExport} className="btn_action text_nowrap_download text_w500">
            <svg className="sico wh16"><use xlinkHref="/assets/layout/icons.svg#s_dl"></use></svg>
            
            <span><Trans>Stiahnuť tabuľku</Trans></span>
            </a>
            </div>
            <table className={`table ${isGender?'-gender':''}`}>
                <thead>
                    <th><Trans>Dátum - časový interval</Trans></th>
                    {graphResults?.map((gr,i) => (
                        <th key={i} colSpan={isGender?2:1}>
                            {gr.name}
                        </th>
                    ))}
                </thead>
                <tbody>
                    {isGender?(
                        <>
                            <tr className="thead">
                                <td></td>
                                {graphResults?.map((gr, i) => (
                                    <>
                                        <th key={i}>
                                            <Trans>Muži</Trans>
                                        </th>
                                        <th key={'z_' + i}>
                                            <Trans>Ženy</Trans>
                                        </th>
                                    </>
                                ))}
                            </tr>
                        </>
                    ):null}
                    {splitted.map((s,i) => (
                        <tr key={i}>
                            <td>{getDateRangeTitle(s.start, s.end, interval!, lingui.i18n)}</td>
                            {s.values.map((v,j) => (
                                <td key={j}>
                                    <div className="table_drop">
                                        <span>{v.count}</span>
                                        <div className="drop">
                                            <div><Trans>Názov</Trans>: <strong>{v.name}</strong></div>
                                            <div><Trans>Deň</Trans>: <strong>{moment(v.from).format('dddd')}</strong></div>
                                            <div><Trans>Dátum</Trans>: <strong>{getDateRangeTitle(v.from, v.to, interval!, lingui.i18n)}</strong></div>
                                            <div><Trans>Stav</Trans>: <strong className="color_green">{v.count}</strong></div>
                                        </div>
                                    </div>
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>    
        </div>
    )
};

export default TableGraph
