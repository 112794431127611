import { EUserActions, setUserStore } from '../user';
import { EDataActions, setDataStore } from '../data';

export function logoutUser () {
    return (dispatch: any) => {
        dispatch(setUserStore(EUserActions.LOGOUT_USER))
        dispatch(setDataStore(EDataActions.RESET_STATE))
    }
}

export default logoutUser