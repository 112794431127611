
import { useState, useEffect } from 'react';
import { IOccupancyFilter, EStatisticsGraphView, EStatisticsInterval } from '../../../../../reducers/data';
import getOccupancy from '../../../../../reducers/api/getOccupancy';
import Loader from '../../Loader';
import { useLingui } from '@lingui/react';
import { IGraphContentData, IGraphData } from '../../../../interfaces/types';
import XYGraph from '../../statistics/graphs/XYGraph';
import TableGraph from './TableGraph';

import { parseGraphData } from '../../../../helpers/index';
import { t } from '@lingui/macro';


interface IProps {
    filter: IOccupancyFilter
}

const GraphOccupancy = (props: IProps) => {
    const lingui = useLingui()

    const [loading, setLoading] = useState(false)
    const [graphResults, setGraphResults] = useState<IGraphData[]|undefined>()
    const [graphData, setGraphData] = useState<IGraphContentData>({
        series: [],
        categories: []
    })
    
    useEffect(() => {
        setLoading(true)
        let isMounted = true

        getOccupancy(props.filter)
            .then(res => {
                setGraphResults(res)

                if(isMounted) {
                    setLoading(false)

                    let resObject = parseGraphData(res?res:[], lingui.i18n)
                    setGraphData(resObject)
                }
            })
            .catch(e => {
                if(isMounted) {
                    setLoading(false)
                    setGraphResults(undefined)
                    setGraphData({
                        series: [],
                        categories: []
                    })
                }
            })
        return () => { isMounted = false }
    }, [lingui.i18n, props.filter])

    let graphTitle = t`Multi`
    let graphInterval = EStatisticsInterval.min15;

    if(props.filter.graphView === EStatisticsGraphView.date) {
        graphTitle = t`Dátum`
    } else if (props.filter.graphView === EStatisticsGraphView.zone) {
        graphTitle = ''
    }
    let isTime = false;
    let yTitle =  t`Počet návštevníkov`

    let dataToSend: {
        date: {
            from: string,
            to: string
        }[],
        interval?: EStatisticsInterval,
        locations?: number[],
        zones?: number[],
        group?: EStatisticsGraphView
    } = {
        date: props.filter.dateRanges,
        zones: props.filter.zones,
        group: props.filter.graphView
    }

    let objJsonStr = JSON.stringify(dataToSend);
    let objJsonB64 = Buffer.from(objJsonStr).toString("base64");


    return (
        <>
            <Loader value={loading}/>
            <XYGraph graphTitle={graphTitle} graphData={graphData} interval={graphInterval} isTime={isTime} yTitle={yTitle} />

            <TableGraph graphResults={graphResults} filter={objJsonB64}/>
        </>
    )
};

export default GraphOccupancy
