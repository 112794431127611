import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router';
import { useRouteMatch, useLocation } from 'react-router-dom';
import Layout from './components/global/Layout';
import { PrivateRoute } from './components/global/PrivateRoute';
import PageUserProfile from './components/pages/PageUserProfile';
import PageDashboard from './components/pages/PageDashboard';
import PageMap from './components/pages/PageMap';
import PageStatistics from './components/pages/PageStatistics';
import PageReports from './components/pages/PageReports';
import PageCalendar from './components/pages/PageCalendar';
import getMarts from '../reducers/api/getMarts';
import { useDispatch, useSelector } from 'react-redux';
import { IApplicationStore } from '../store/index';
import { EPermission } from './interfaces/types';
import usePermission from './hooks/usePermission';
import PageNotFound from './components/pages/PageNotFound';
import { PrivateRestrictedRoute } from './components/global/PrivateRestrictedRoute';

interface IProps {

}

const AppMain: React.FC<IProps> = props => {
    let dispatch = useDispatch()
    let match = useRouteMatch()
    let location = useLocation()
    let allowCalendar = usePermission([EPermission.calendar])
    let allowMap = usePermission([EPermission.map])
    let allowStatistics = usePermission([EPermission.dwell, EPermission.occupancy, EPermission.heatmap, EPermission.gender], true)
    let allowReports = usePermission([EPermission.report])

    const token = useSelector((store: IApplicationStore) => store.userStore.token)
    
    useEffect(() => {
        if(token) {
            dispatch(getMarts())
        }
    }, [dispatch, token])

    if(token === null) {
        return <Redirect to="/"/>
    }

    return (
        <>
            <Layout>
                <Switch location={location}>
                    <PrivateRoute
                        exact 
                        path={`${match.path}`}
                        component={PageDashboard}
                    />
                    <PrivateRoute
                        exact 
                        path={`${match.path}/profile`}
                        component={PageUserProfile}
                    />
                    <PrivateRoute
                        exact
                        path={[
                            `${match.path}/profile`,
                            `${match.path}/profile/:tabId`
                        ]} 
                        component={PageUserProfile}
                    />
                    <PrivateRestrictedRoute
                        exact
                        enableByRight={allowMap}
                        path={[
                            `${match.path}/map`,
                            `${match.path}/map/:martId`
                        ]} 
                        component={PageMap}
                    />
                    <PrivateRestrictedRoute
                        exact
                        enableByRight={allowStatistics}
                        path={[
                            `${match.path}/statistics`,
                            `${match.path}/statistics/:tabId`
                        ]} 
                        component={PageStatistics}
                    />
                    <PrivateRestrictedRoute
                        exact
                        enableByRight={allowReports}
                        path={`${match.path}/reports`} 
                        component={PageReports}
                    />
                    <PrivateRestrictedRoute
                        exact
                        enableByRight={allowCalendar}
                        path={`${match.path}/calendar`} 
                        component={PageCalendar}
                    />
                    <Route component={PageNotFound}/>
                </Switch>
            </Layout>
        </>
    )
}

export default AppMain

