import { t, Trans } from '@lingui/macro';
import { i18n } from '@lingui/core';
import {FormEvent, useState} from 'react';
import { useDispatch } from 'react-redux';
import { EDataActions, setDataStore } from '../../../reducers/data';
import contactForm from '../../../reducers/api/contactForm';
import Loader from '../global/Loader';

interface IProps {
}

const FormContact = (props: IProps) => {
    const dispatch = useDispatch()

    const [loading, setLoading] = useState(false)
    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')
    const [sent, setSent] = useState(false)
    
    const submitForm = (e: FormEvent<HTMLFormElement>) => {
        e.stopPropagation()
        e.preventDefault()

        console.log(message);
        
        if(
            name.length &&
            phone.length &&
            email.length &&
            message.length 
        ) {
            setLoading(true)

            contactForm(name, email, t`Kontaktný formulár`, message, phone)
                .then(res => {
                    setLoading(false)

                    if(res) {
                        setSent(true)
                        setName('')
                        setPhone('')
                        setEmail('')
                        setMessage('')
                    } else {
                        setSent(false)
                    }
                })
                .catch(e => {
                    setLoading(false)
                    setSent(false)
                    alert(e)
                })

            setName('')
            setPhone('')
            setEmail('')
            setMessage('')
        }
    }

    return (
        <div className="popup">
            <div className="popup_inner">
                <div className="popup_content wrapper wmax350 pdt-60 pdb-60">
                    <h2 className="title_h2 text_center"><Trans>Kontaktný formulár</Trans></h2>
                    <p className="text_basic text_center mgt-10"><Trans>V tejto sekcii nás môžte jednoducho kontaktovať ohľadom vaších pripomienok</Trans></p>

                    <form onSubmit={(e) => submitForm(e)} className={`mgt-10`}>
                        <Loader value={loading}/>
                        <div className="mgt-20">
                            <label className="label_basic"><Trans>Meno a priezvisko</Trans></label>  
                            <input 
                                type="text" 
                                value={name} 
                                onChange={(e) => setName(e.target.value)}
                                placeholder={t`Ján Novák`} 
                                className="input_text" 
                                required
                            />   
                        </div>

                        <div className="mgt-20">
                            <label className="label_basic"><Trans>Telefonné číslo</Trans></label>  
                            <input 
                                type="tel" 
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                                placeholder={i18n._("+421 911 123 456")}
                                className="input_text" 
                                required
                            />   
                        </div>

                        <div className="mgt-20">
                            <label className="label_basic"><Trans>E-mailová adresa</Trans></label>  
                            <input 
                                type="email" 
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder={i18n._("noreply@visitor.sk")}
                                className="input_text" 
                                required
                            /> 
                        </div>

                        <div className="mgt-20">
                            <label className="label_basic"><Trans>Správa</Trans></label>  
                            <textarea 
                                cols={15}
                                rows={5}
                                onChange={ (e) => setMessage(e.target.value) }
                                className="input_text" 
                                placeholder={i18n._("Dobrý deň, kontaktujte ma, chcel by som sa opýtať")}
                                required
                            />
                        </div>
                        {sent && (
                            <div className="mgt-20">
                                <div className="message-wrapper">
                                    <div className="message-content">
                                        <Trans>Ďakujeme za odoslanie. Budeme Vás kontaktovať.</Trans>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="text_center mgt-30">
                            <button type="submit" className="btn_basic">
                                <svg className="sico"><use xlinkHref="/assets/layout/icons.svg#s_send" /></svg>
                                <span><Trans>Odoslať</Trans></span>                            
                            </button>
                        </div>
                    </form>
                </div>
            </div>   

            <button 
                className="popup_close remove-body-class" 
                type="button" 
                onClick={() => dispatch(setDataStore(EDataActions.SHOW_CONTACT, false))}
            >
                <svg className="sicofill"><use xlinkHref="/assets/layout/icons.svg#s_cross" /></svg>    
            </button>
        </div>
    )
};

export default FormContact
