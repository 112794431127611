import config from '../../config';
import { handleErrors } from '../../js/helpers/handleErrors';
import { store } from '../../store';
import { IAttendanceFilter, EStatisticsInterval, EStatisticsGraphView, EStatisticsType } from '../data';

export function getAttendanceStats (
    filter: IAttendanceFilter
) {
    let dataToSend: {
        date: {
            from: string,
            to: string
        }[],
        type: EStatisticsType,
        interval?: EStatisticsInterval,
        locations?: number[],
        zones?: number[],
        group?: EStatisticsGraphView
    } = {
        date: filter.dateRanges,
        type: filter.type,
        interval: filter.interval,
        locations: filter.localities,
        zones: filter.zones,
        group: filter.graphView
    }

    return fetch(config.api.attendance, {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${store.getState().userStore.token}`
            },
            credentials: 'include',
            body: JSON.stringify(dataToSend)
        })
            .then(handleErrors)
            .then(res => res.json())
            .then(res => {
                return res
            })
            .catch((error: any) => {
                return null
            })
}

export default getAttendanceStats