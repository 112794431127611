import { history } from '../../index';
import { store } from '../../store';
import { EUserActions, setUserStore } from '../../reducers/user';
import { addFloatingNotification } from '../../reducers/notifications';
import { ENotificationType } from '../interfaces/types';
import { t } from '@lingui/macro';

export function handleErrors(response: any) {
    if(response.status === 409 || response.status === 204 || response.status === 405)  {
        return response
    }

    if (!response.ok) {
        if(response.status ===  401 || response.status === 403) {
            //alert(t`Nastala chyba v komunikácii zo serverom. Kontaktujte prosím administrátora.` + ' Code: ' + response.status + ' Text: ' + response.statusText)
            store.dispatch(setUserStore(EUserActions.LOGOUT_USER))

            throw Error(response.statusText);

        } else if(response.status > 400 && response.status < 500) {
            console.error('Request warning', response);

            if(response.status !== 404) {
                /*alert(t`Nastala chyba v komunikácii zo serverom. Kontaktujte prosím administrátora.` + ' Code: ' + response.status + ' Text: ' + response.statusText)
                store.dispatch(addFloatingNotification({
                    type: ENotificationType.warning,
                    text:t`Nastala chyba v komunikácii so serverom!` + '( ' + response.statusText + ' )',
                    showBell: true
                }))*/

                history.push('/app/404')

            } else {
                alert(t`Nastala chyba v komunikácii zo serverom. Kontaktujte prosím administrátora.` + ' Code: ' + response.status + ' Text: ' + response.statusText)
            }

            throw Error(response.statusText);
        }
        
        store.dispatch(addFloatingNotification({
            type: ENotificationType.error,
            text:t`Nastala neočakávaná chyba!`,
            showBell: true
        }))

        alert(t`Nastala chyba v komunikácii zo serverom. Kontaktujte prosím administrátora.` + ' Code: ' + response.status + ' Text: ' + response.statusText)

        console.error('Request error', response)
        throw Error(response.statusText);
    }
    return response;
}
