interface IProps {
}

const PageNotFound = (props: IProps) => {
    
    return (
        <div className="container container_overlay"> 
            
            <div className="box pd-80 mgt-20"></div>
            <div className="box pd-80 mgt-20"></div>
            <div className="box pd-80 mgt-20"></div>
            <div className="box pd-80 mgt-20"></div>
            <div className="box pd-80 mgt-20"></div>
            <div className="box pd-80 mgt-20"></div>

            <div className="box_first over_overlay text_center pdt-60 pdb-60">
                <h1 className="title_h1">
                    <strong className="dblock">404</strong>
                    <span className="text_w400">Táto stránka neexistuje.</span>
                    
                </h1>
            </div>
            
        </div>
    )
};

export default PageNotFound
