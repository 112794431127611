import { Trans, t } from '@lingui/macro';
import FilterLocalitiesZones from '../filters/FilterLocalitiesZones';
import { useSelector, useDispatch } from 'react-redux';
import { IApplicationStore } from '../../../../store/index';
import { EDataActions, EStatisticsDateType, setDataStore, EStatisticsType, EStatisticsGraphView, EStatisticsInterval, IAttendanceFilter } from '../../../../reducers/data';
import { ILocationExt, IZoneExt } from '../../pages/PageStatistics';
import FilterDayWeekMonth from '../filters/FilterDayWeekMonth';
import FilterGraphType from '../filters/FilterGraphType';
import SmallTypeTitle from './SmallTypeTitle';
import FilterShowType from '../filters/FilterShowType';
import SmallViewTitle from './SmallViewTitle';
import FilterIntervalType from '../filters/FilterIntervalType';
import SmallIntervalTitle from './SmallIntervalTitle';
import { useState, useEffect, useMemo } from 'react';
import FadeAnimation from '../FadeAnimation';
import GraphAttendance from './graphs/GraphAttendance';
import { getFilterDateRangeTitle } from '../../../helpers/index';
import { useLingui } from "@lingui/react";
import SmallDateTypeTitle from './SmallDateTypeTitle';

interface IProps {
    localities: ILocationExt[],
    zones: IZoneExt[]
}

const Attendance = (props: IProps) => {
    const dispatch = useDispatch()
    const lingui = useLingui()

    const [filter, setFilter] = useState<IAttendanceFilter>({
        localities: [],
        zones: [],
        dateType: EStatisticsDateType.day,
        dateRanges: [],
        type: EStatisticsType.column,
        graphView: EStatisticsGraphView.multi
    })

    const filterGraph = useSelector((store: IApplicationStore) => store.dataStore.filterAttendance)

    filterGraph.zones = props.zones.filter( val => ( filterGraph.zones.includes( val.id ) ) ).map( val => val.id )
    filterGraph.localities = props.localities.filter( val => ( filterGraph.localities.includes( val.id ) ) ).map( val => val.id )

    useEffect(() => {
        setFilter(filterGraph)
    }, [filterGraph])

    //marts[0].zone.filter( val => (val.isAttendance === true) ).map( val => val.id )

    const graph = useMemo(() => <GraphAttendance filter={filterGraph}/>, [filterGraph])


    let same = JSON.stringify(filterGraph) === JSON.stringify(filter)
    
    let errorMessage = ''

    if( EStatisticsDateType.custom === filter.dateType ) {
        filterGraph.interval = EStatisticsInterval.custom
    }

    if(![EStatisticsType.cake, EStatisticsType.column].includes(filterGraph.type) && EStatisticsDateType.custom !== filter.dateType ) {
        if(typeof filterGraph.interval === 'undefined') {
            errorMessage += t`Nie je zadaný interval.` + ' ' 
        }
    }

    if(filterGraph.zones.length + filterGraph.localities.length === 0) {
        errorMessage += t`Nie je zadaná zóna ani lokalita.` + ' ' 
    }

    if(filterGraph.dateRanges.length === 0) {
        errorMessage += t`Nie je zadaný dátum.` + ' ' 
    }

    return (
        <>
            <div className="grid align-center">
                <h2 className="title_h3 col_fill"><Trans>Návštevnosť</Trans></h2>
                {/* <div className="col_default">
                    <a href="#" title="" className="btn_action text_nowrap text_w700">
                        <span>Stiahnuť</span>  
                        <svg className="sico wh16"><use xlinkHref="/assets/layout/icons.svg#s_dl" /></svg>                                                      
                    </a>       
                </div> */}
            </div>

            <div className="grid flex-nowrap-lg small-space align-start pdt-15 pdb-25">

                <div className="col_fill">
                    <div className="grid small-space">
                        <FilterLocalitiesZones
                            showZones
                            showLocation
                            locations={props.localities}
                            zones={props.zones}
                            selectedZones={filter.zones}
                            selectedLocation={filter.localities}
                            setLocation={(loc) => {
                                setFilter({
                                    ...filter,
                                    localities: loc
                                })
                            }}
                            setZone={(zones) => {
                                setFilter({
                                    ...filter,
                                    zones: zones
                                })
                            }}
                        />
    
                        <FilterDayWeekMonth
                            dateType={[EStatisticsDateType.day, EStatisticsDateType.week, EStatisticsDateType.month, EStatisticsDateType.year, EStatisticsDateType.custom]}
                            selectedDateType={filter.dateType}
                            selectedRanges={filter.dateRanges}
                            onSelectedRanges={(ranges) => {
                                setFilter({
                                    ...filter,
                                    dateRanges: ranges
                                })
                            }}
                            onSelectDateType={(type) => {
                                let interval = filter.interval

                                switch(type) {
                                    case EStatisticsDateType.day:
                                        if(interval) {
                                            interval = [EStatisticsInterval.min15, EStatisticsInterval.min30, EStatisticsInterval.min60, EStatisticsInterval.day].includes(interval)?interval:EStatisticsInterval.min15
                                        } else {
                                            interval = EStatisticsInterval.min15
                                        }
                                        break
                                    case EStatisticsDateType.week:
                                        if(interval) {
                                            interval = [EStatisticsInterval.day, EStatisticsInterval.week].includes(interval)?interval:EStatisticsInterval.day
                                        } else {
                                            interval = EStatisticsInterval.day
                                        }
                                        break
                                    case EStatisticsDateType.month:
                                        if(interval) {
                                            interval = [EStatisticsInterval.day, EStatisticsInterval.week, EStatisticsInterval.month].includes(interval)?interval:EStatisticsInterval.day
                                        } else {
                                            interval = EStatisticsInterval.day
                                        }
                                        break
                                    case EStatisticsDateType.year:
                                        if(interval) {
                                            interval = [EStatisticsInterval.day, EStatisticsInterval.week, EStatisticsInterval.month].includes(interval)?interval:EStatisticsInterval.day
                                        } else {
                                            interval = EStatisticsInterval.month
                                        }
                                        break
                                    case EStatisticsDateType.custom:
                                        if(interval) {
                                            interval = [EStatisticsInterval.day, EStatisticsInterval.week].includes(interval)?interval:EStatisticsInterval.day
                                        } else {
                                            interval = EStatisticsInterval.day
                                        }
                                        break
                                }

                                setFilter({
                                    ...filter,
                                    dateType: type,
                                    interval: [EStatisticsType.cake, EStatisticsType.column].includes(filter.type)?undefined:interval,
                                    dateRanges: []
                                })
                            }}
                        />

                        <FilterGraphType
                            allowed={[EStatisticsType.cake, EStatisticsType.column, EStatisticsType.table, EStatisticsType.xy]}
                            selected={filter.type}
                            onSelect={(type) => {
                                setFilter({
                                    ...filter,
                                    type: type,
                                    interval: [EStatisticsType.cake, EStatisticsType.column].includes(type)?undefined:filter.interval
                                })
                            }}
                        />

                        <FilterIntervalType
                            selectedDate={filter.dateType}
                            selectedGraph={filter.type}
                            selected={filter.interval}
                            allowed={[
                                EStatisticsInterval.min15, 
                                EStatisticsInterval.min30, 
                                EStatisticsInterval.min60, 
                                EStatisticsInterval.day, 
                                EStatisticsInterval.week, 
                                EStatisticsInterval.month
                            ]}
                            onSelect={(val) => {
                                setFilter({
                                    ...filter,
                                    interval: val
                                })
                            }}
                        />

                        {filter.type !== EStatisticsType.table ? (
                            <FilterShowType
                                allowed={[EStatisticsGraphView.multi, EStatisticsGraphView.zone, EStatisticsGraphView.date]}
                                selected={filter.graphView}
                                onSelect={(type) => {
                                    setFilter({
                                        ...filter,
                                        graphView: type
                                    })
                                }}
                            />
                        ):null}
                    </div>
                </div>                    

                {!same && (
                    <div className="text_right col_default col1_lgm order-first-lgm self-center offset">
                        <a 
                            href="/" 
                            title="" 
                            className="btn_action text_nowrap text_w500"
                            onClick={(e) => {
                                e.preventDefault()

                                dispatch(setDataStore(EDataActions.SET_FILTER_ATTENDANCE, filter))
                            }}
                        >
                            <svg className="sico wh16"><use xlinkHref="/assets/layout/icons.svg#s_save" /></svg>
                            <span><Trans>Odoslať</Trans></span>                            
                        </a>        
                    </div>
                )}
            </div>               
            
            <hr />

            <div className="grid small-space flex-nowrap-lg pdt-20">
                <div className="col_default col1_lgm text_s16 text_w500 self-center mgt-10"><Trans>Filtrácie</Trans>:</div>

                <div className="btn_filter_list col_default col1_lgm">
                    <div className="btn_filter_list--wrapper">
                        {(typeof filter.dateType !== 'undefined') ? (
                            <a href="/" style={{pointerEvents:'none'}} className="btn_filter">
                                <span><Trans>Dátum</Trans> - <SmallDateTypeTitle type={filter.dateType}/></span>
                            </a> 
                        ):null}
                        <a href="/" style={{pointerEvents:'none'}} className="btn_filter">
                            <span><Trans>Typ</Trans> - <SmallTypeTitle type={filter.type}/></span>
                        </a>  
                        {(typeof filter.interval !== 'undefined') ? (
                            <a href="/" style={{pointerEvents:'none'}} className="btn_filter">
                                <span><Trans>Interval</Trans> - <SmallIntervalTitle type={filter.interval}/></span>
                            </a> 
                        ):null}
                        <a href="/" style={{pointerEvents:'none'}} className="btn_filter">
                            <span><Trans>Zobrazenie</Trans> - <SmallViewTitle type={filter.graphView}/></span>
                        </a>  
                    </div>
                    
                    {filter.localities.length > 0 ? (
                        <div className="btn_filter_list--wrapper">
                            {filter.localities.map((z,i) => {
                                let locality = props.localities.find(zn => zn.id === z)
                                
                                if(locality) {
                                    return (
                                        <a 
                                            href="/" 
                                            className="btn_filter" 
                                            key={i}
                                            onClick={(e) => {
                                                e.preventDefault()
                                                setFilter({
                                                    ...filter,
                                                    localities: filter.localities.filter(l => l !== z)
                                                })
                                            }}
                                        >
                                            <span>{locality.name}</span>
                                            <svg className="sico"><use xlinkHref="/assets/layout/icons.svg#s_cross" /></svg>
                                        </a>   
                                    )
                                } else {
                                    return null
                                }

                            })}
                        </div>
                    ):null}
                    {filter.zones.length > 0 ? (
                        <div className="btn_filter_list--wrapper">
                            {filter.zones.map((z,i) => {
                                let zone = props.zones.find(zn => zn.id === z)

                                if(zone) {
                                    return (
                                        <a 
                                            href="/" 
                                            className="btn_filter" 
                                            key={i}
                                            onClick={(e) => {
                                                e.preventDefault()
                                                setFilter({
                                                    ...filter,
                                                    zones: filter.zones.filter(l => l !== z)
                                                })
                                            }}
                                        >
                                            <span>{zone.name}</span>
                                            <svg className="sico"><use xlinkHref="/assets/layout/icons.svg#s_cross" /></svg>
                                        </a>   
                                    )
                                } else {
                                    return null
                                }

                            })}
                        </div>
                    ):null}
                    {filter.dateRanges.length > 0 ? (
                        <div className="btn_filter_list--wrapper">
                            {filter.dateRanges.map((dr, i) => {
                                let text = getFilterDateRangeTitle(dr.from, dr.to, lingui.i18n, filter.dateType)

                                return (
                                    <a 
                                        key={i}
                                        href="/" 
                                        className="btn_filter"
                                        onClick={(e) => {
                                            e.preventDefault()
                                            setFilter({
                                                ...filter,
                                                dateRanges: filter.dateRanges.filter(l => !((l.from === dr.from) && (l.to === dr.to)))
                                            })
                                        }}
                                    >
                                        <span>{text}</span>
                                        <svg className="sico"><use xlinkHref="/assets/layout/icons.svg#s_cross" /></svg>
                                    </a>  
                                )
                            })}
                        </div>
                    ):null}
                </div>
            </div> 

            <div className="mgt-40 loader-wrapper">
                <FadeAnimation animKey={same?'1':'0'}>
                    {same?(
                        <>
                            {(errorMessage.length === 0)?graph:(
                                <>
                                    <div className="message">
                                        <div className="message-content message-content--warning">
                                            {errorMessage}
                                        </div>
                                    </div>
                                </>
                            )}
                        </>
                    ):(
                        <div className="message">
                            {/* <div className="message-content message-content--warning">
                                <Trans>Musíte uložiť filter pre zobrazenie aktuálneho grafu.</Trans>
                            </div> */}
                        </div>
                    )}
                </FadeAnimation>
            </div>
        </>
    )
};

export default Attendance
