import { EPermission } from '../interfaces/types';
import { useSelector } from 'react-redux';
import { IApplicationStore } from '../../store';

function usePermission(permissions: EPermission[], or?: boolean) {
    const userData = useSelector((store: IApplicationStore) => store.userStore.user)
    
    let authenticated = true

    if(userData) {
        if(!or) {
            permissions.forEach(p => {
                if(!userData.permission.includes(p)) {
                    authenticated = false
                }
            })
        } else {
            authenticated = false

            permissions.forEach(p => {
                if(userData.permission.includes(p)) {
                    authenticated = true
                }
            })
        }
    } else {
        authenticated = false
    }

    return authenticated
}

export default usePermission