import { Trans } from "@lingui/macro";
import { EStatisticsDateType } from '../../../../reducers/data';

interface IProps {
    type: EStatisticsDateType
}

const SmallDateTypeTitle = (props: IProps) => {
    switch(props.type) {
        case EStatisticsDateType.day: 
            return (<><Trans>Deň</Trans></>)
        case EStatisticsDateType.month:
            return (<><Trans>Mesiac</Trans></>)
        case EStatisticsDateType.week: 
            return (<><Trans>Týždeň</Trans></>)
        case EStatisticsDateType.year:
            return (<><Trans>Rok</Trans></>)
        case EStatisticsDateType.custom:
            return (<><Trans>Vlastné</Trans></>)
    }

    return null
}

export default SmallDateTypeTitle
