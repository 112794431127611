import { useSelector } from 'react-redux';
import { IApplicationStore } from '../../../store';
import { EPermission } from '../../interfaces/types';

interface IProps {
    permissions: EPermission[],
    or?: boolean
}

const AuthPermission:React.FC<IProps> = (props) => {
    const userData = useSelector((store: IApplicationStore) => store.userStore.user)

    let authenticated = true

    if(userData) {
        if(!props.or) {
            props.permissions.forEach(p => {
                if(!userData.permission.includes(p)) {
                    authenticated = false
                }
            })
        } else {
            authenticated = false

            props.permissions.forEach(p => {
                if(userData.permission.includes(p)) {
                    authenticated = true
                }
            })
        }
    } else {
        authenticated = false
    }

    if(authenticated) {
        return (
            <>
                {props.children}
            </>
        )
    } else {
        return (<></>)
    }
}

export default AuthPermission