import React from 'react';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { useRef } from 'react';

interface IProps {
    animKey: string,
    fast?: boolean
}

const FadeAnimation : React.FC<IProps> = (props) => {
    const nodeRef = useRef<any>(null);

    return (
        <SwitchTransition mode="out-in">
            <CSSTransition
                classNames={props.fast?'fade-fast':"fade"}
                addEndListener={(done: () => void) => {
                    nodeRef.current?.addEventListener("transitionend", done, false);
                }}
                key={props.animKey}
                nodeRef={nodeRef}
                mountOnEnter={true}
                unmountOnExit={true}
                duration={3000}
            >
                <div ref={nodeRef}>
                    {props.children}
                </div>
            </CSSTransition>
        </SwitchTransition>
    )
}

export default FadeAnimation