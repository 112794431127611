import { Trans } from '@lingui/macro';
import { useEffect } from 'react';
import FormLogin from '../forms/FormLogin';
import Footer from '../global/Footer';
import { useSelector } from 'react-redux';
import { IApplicationStore } from '../../../store/index';
import { useHistory } from 'react-router-dom';

interface IProps {
}

const PageLogin = (props: IProps) => {
    const history = useHistory()

    const token = useSelector((store: IApplicationStore) => store.userStore.token)
    const user = useSelector((store: IApplicationStore) => store.userStore.user)

    let historyPush = history.push

    useEffect(() => {
        if(token && user) {
            historyPush('/app')
        }    
    }, [token, user, historyPush])

    return (
        <>
            <div className="svghide_cont">
                <svg className="svghide" viewBox="0 0 1 1">
                    <clipPath id="clip_tl" clipPathUnits="objectBoundingBox">
                        <path d="M0.002,1l-0.002,0l0,0.1l1.1,0l0,-1.1l-0.1,0c0,0.552 -0.447,0.999 -0.998,1Z" />
                    </clipPath>   
                    <clipPath id="clip_tr" clipPathUnits="objectBoundingBox">
                        <path d="M0.998,1l0.002,0l0,0.1l-1.1,0l0,-1.1l0.1,0c0,0.552 0.447,0.999 0.998,1Z" />
                    </clipPath>    
                    <clipPath id="clip_bl" clipPathUnits="objectBoundingBox">
                        <path d="M0.002,0l-0.002,0l0,-0.1l1.1,-0l0,1.1l-0.1,0c0,-0.552 -0.447,-0.999 -0.998,-1Z" />
                    </clipPath>   
                    <clipPath id="clip_br" clipPathUnits="objectBoundingBox">
                        <path d="M0.998,-0l0.002,-0l0,-0.1l-1.1,-0l0,1.1l0.1,-0c0,-0.552 0.447,-0.999 0.998,-1Z" />
                    </clipPath>   
                </svg>
            </div>  
            
            <div className="homepage">
                <main className="main">
                    <div className="main_top">
                        <div className="main_head">
                            {/* <div className="main_head_left">
                                <a href="https://visitor.sk" target="_blank" title="Visitor.sk" rel="noreferrer" className="btn_back text_w500 color_white">
                                    <svg className="sico"><use xlinkHref="assets/layout/icons.svg#s_ar2_l" /></svg>
                                    <span><Trans>Späť</Trans></span>
                                </a>
                            </div> */}

                            <a href="https://visitor.sk" target="_blank" title="Visitor.sk" className="main_logo text_w500" rel="noreferrer">
                                <svg className="sicofill"><use xlinkHref="assets/layout/icons.svg#s_logo" /></svg>
                            </a>
                        </div>

                        <div className="wrapper wmax460 text_center pdt-60 pdb-60">
                            <h1 className="title_h2">
                                <Trans>Vitajte</Trans>!
                                <span className="text_w400">
                                    <span className="dblock" style={{color: '#A15D70'}}><Trans>Prihláste sa</Trans></span>
                                    <Trans>do vašej klientskej zóny</Trans>
                                </span>
                            </h1>
                        </div>
                        
                    </div>

                    <div className="main_bottom">
                        <div className="wrapper wmax350 pdt-60 pdb-60">
                            <FormLogin/>
                        </div>    
                    </div>
                </main>
                <Footer/>
            </div>  
        </>
    )
};

export default PageLogin
