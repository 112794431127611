import config from '../../config';
import { handleErrors } from '../../js/helpers/handleErrors';
import { store } from '../../store';
import { setLoadingStore, ELoadingActions } from '../loading';
import { EDataActions, setDataStore } from '../data';

export function getMarts (
) {
    return (dispatch: any) => {
        dispatch(setLoadingStore(ELoadingActions.SET_LOADING_MARTS, true))

        fetch(config.api.mart, {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${store.getState().userStore.token}`
            },
            credentials: 'include'
        })
            .then(handleErrors)
            .then(res => res.json())
            .then(json => {
                dispatch(setLoadingStore(ELoadingActions.SET_LOADING_MARTS, false))
                dispatch(setDataStore(
                    EDataActions.GET_MARTS,
                    json
                ))

            })
            .catch((error: any) => {
                dispatch(setLoadingStore(ELoadingActions.SET_LOADING_MARTS, false))
                dispatch(setDataStore(EDataActions.GET_MARTS,[]))
            })
    }
}

export default getMarts