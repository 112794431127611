import { Trans, t } from '@lingui/macro';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IApplicationStore } from '../../../store/index';
import { useLingui } from "@lingui/react";
import getReports from '../../../reducers/api/getReports';
import moment from 'moment';
import Loader from "../global/Loader";
import { Link } from "react-router-dom";
import { setDataStore, EDataActions } from '../../../reducers/data';
import FilterReportType from '../global/filters/FilterReportType';
import FilterDateRange from '../global/filters/FilterDateRange';

interface IProps {
}

const PageReports = (props: IProps) => {
    const dispatch = useDispatch()
    const lingui = useLingui()
    
    const reports = useSelector((store: IApplicationStore) => store.dataStore.reports)
    const selectedMarts = useSelector((store: IApplicationStore) => store.dataStore.selectedMarts)
    const filter = useSelector((store: IApplicationStore) => store.dataStore.reportsFilter)
    const loading = useSelector((store: IApplicationStore) => store.loadingStore.loadingReports)
    
    useEffect(() => {
        dispatch(getReports(
            lingui.i18n._locale,
            selectedMarts.map(m => m.id)
        ))    
    }, [dispatch, selectedMarts, lingui.i18n._locale])

    const filteredReports = useMemo(() => {
        let newReports = reports

        if(filter.type.length > 0) {
            newReports = newReports.filter(r => filter.type.map(t => t.id).includes(r.type))
        }

        if(filter.dayFrom) {
            newReports = newReports.filter(r => moment(r.date).isSameOrAfter(filter.dayFrom))
        }

        if(filter.dayTo) {
            newReports = newReports.filter(r => moment(r.date).isSameOrBefore(filter.dayTo))
        }

        return newReports
    }, [filter, reports])

    const types = useMemo(() => {
        let newTypes: {
            id: string,
            title: string
        }[] = []

        reports.forEach(r => {
            if(typeof newTypes.find(t => t.id === r.type) === 'undefined') {
                newTypes.push({
                    id: r.type,
                    title: r.typeTitle
                })
            }
        })

        return newTypes
    }, [reports])

    return (
        <>
            <h1 className="title_h1 pdt-40">
                VISITOR <span className="text_w400"><Trans>reporty</Trans></span>
                <div className="icon_info wh24 diblock v_top">
                    <svg className="sicofill"><use xlinkHref="/assets/layout/icons.svg#s_info" /></svg>
                </div>
            </h1>

            <p className="text_basic mgt-20 wmax460">
                <Trans>Vygenerované špecifické denné, týždenné, mesačné a alebo ročné správy tzv. reporty. Tu ich máte vždy poruke.</Trans>
            </p>        

            <div className="loader-wrapper box mgt-60 mgb-30 pd-40 pdl-25lg pdr-25lg">    
                <Loader value={loading}/>
                {selectedMarts.length === 0?(
                    <div className="message-wrapper">
                        <div className="message-content message-content--warning">
                            <Trans>Musíte vybrať aspoň jedno obchodné centrum.</Trans>
                        </div>
                    </div>
                ):(
                    <>
                        <h2 className="title_h3"><Trans>Reporty</Trans></h2>

                        <div className="grid flex-nowrap-lg small-space align-start pdt-15 pdb-25">

                            <div className="col_fill">
                                <div className="grid small-space">
                                    <FilterReportType
                                        title={t`Typ reportu`}
                                        values={types}
                                        selected={filter.type}
                                        setFilterSelect={(val) => {
                                            dispatch(setDataStore(EDataActions.SET_REPORT_FILTERS, {
                                                ...filter, type: val
                                            }))
                                        }}
                                    />

                                    <FilterDateRange
                                        title={t`Dátum`}
                                        selected={{
                                            dayFrom: filter.dayFrom,
                                            dayTo: filter.dayTo
                                        }}
                                        setFilterDateRange={(from, to) => {
                                            dispatch(setDataStore(EDataActions.SET_REPORT_FILTERS, {
                                                ...filter, dayFrom: from, dayTo: to
                                            }))
                                        }}
                                    />
                                </div>
                            </div>  
                        </div>               
                        
                        <hr />

                        <div className="grid small-space flex-nowrap-lg pdt-20">
                            <div className="col_default col1_lgm text_s16 text_w500 self-center mgt-10"><Trans>Filter</Trans>:</div>

                            <div className="btn_filter_list col_default col1_lgm">
                                {filter.type.map((t,i) => (
                                    <a 
                                        key={i}
                                        href="/" 
                                        className="btn_filter"
                                        onClick={(e) => {
                                            e.preventDefault()
                                            e.stopPropagation()

                                            let newFilter = {...filter}

                                            newFilter.type = filter.type.filter(ft => ft.id !== t.id)

                                            dispatch(setDataStore(EDataActions.SET_REPORT_FILTERS, {...filter, type: []}))
                                        }}
                                    >
                                        <span>{t.title}</span>
                                        <svg className="sico"><use xlinkHref="/assets/layout/icons.svg#s_cross" /></svg>
                                    </a>   
                                ))} 
                                {(typeof filter.dayFrom !== 'undefined' || typeof filter.dayTo !== 'undefined')?(
                                    <a 
                                        href="/" 
                                        className="btn_filter"
                                        onClick={(e) => {
                                            e.preventDefault()
                                            e.stopPropagation()

                                            dispatch(setDataStore(EDataActions.SET_REPORT_FILTERS, {...filter, dayFrom: undefined, dayTo: undefined}))
                                        }}
                                    >
                                        {(typeof filter.dayFrom !== 'undefined' && typeof filter.dayTo !== 'undefined')?(
                                            <span>{moment(filter.dayFrom).format('DD.MM.YYYY')} – {moment(filter.dayTo).format('DD.MM.YYYY')} </span>
                                        ):(
                                            <span>{filter.dayFrom?moment(filter.dayFrom).format('DD.MM.YYYY'):moment(filter.dayTo).format('DD.MM.YYYY')}</span>
                                        )}
                                        <svg className="sico"><use xlinkHref="/assets/layout/icons.svg#s_cross" /></svg>
                                    </a>
                                ):null}
                            </div>
                        </div> 
                        
                        <div className="pdt-90 item_report_list">
                            {filteredReports.length === 0 ? (
                                <div className="message-wrapper">
                                    <div className="message-content message-content--warning">
                                        <Trans>Zadaným filtrom nevyhovujú žiadne reporty.</Trans>
                                    </div>
                                </div>
                            ):null}
                            {filteredReports.map((r,i) => {
                                let mart = selectedMarts.find(m => m.id === r.mart)

                                if(typeof mart === 'undefined') {
                                    return null
                                }

                                return (
                                    <div className="item_report" key={i}>
                                        <a href={r.link} target="_blank" rel="noreferrer" title={r.name} className="item_report_title">{moment(r.date).format('DD.MM.YYYY')} - {r.name}</a>
                                        <Link to={`/app/map/${r.mart}`} title={mart.name} className="item_report_type">{mart.name}</Link>  
                                        <a href={r.link} target="_blank" rel="noreferrer" title={r.name} className="item_report_dl btn_action text_w700">
                                            <span><Trans>Stiahnuť</Trans></span>
                                            <svg className="sico wh16"><use xlinkHref="/assets/layout/icons.svg#s_dl" /></svg>
                                        </a>    
                                    </div>
                                )
                            })}
                        </div>
                    </>
                )}

                
            </div>
        </>
    )
};

export default PageReports
