import ReactDOM from 'react-dom';
import './index.css';
import App from './js/App';
import { BrowserRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { i18n } from '@lingui/core'
import { I18nProvider } from '@lingui/react'
import { messages as messagesSk } from './locales/sk/messages.js'
import { messages as messagesCz } from './locales/cs/messages.js'
import { messages as messagesDe } from './locales/de/messages.js'
import { messages as messagesEn } from './locales/en/messages.js'
import { Provider } from 'react-redux'
import { persistor, store } from './store';
import { PersistGate } from 'redux-persist/integration/react'
import { sk, en, de, cs } from 'make-plural/plurals'
import { registerLocale } from 'react-datepicker'
import { enGB as enDate, sk as skDate, de as deDate, cs as csDate } from "date-fns/locale";
import 'moment/locale/en-gb'
import 'moment/locale/de'
import 'moment/locale/cs'
import 'moment/locale/sk'

registerLocale("sk", skDate)
registerLocale("de", deDate)
registerLocale("cs", csDate)
registerLocale("en", enDate)

export const history = createBrowserHistory()

export const currentLanguage = 'sk'

i18n.load('en', messagesEn)
i18n.load('de', messagesDe)
i18n.load('cz', messagesCz)
i18n.load('sk', messagesSk)

i18n.loadLocaleData({
    sk: { plurals: sk },
    en: { plurals: en },
    cs: { plurals: cs },
    de: { plurals: de }
})
i18n.activate(currentLanguage)

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <I18nProvider i18n={i18n}>
                <BrowserRouter>
                    <App/>
                </BrowserRouter>
            </I18nProvider>
        </PersistGate>
    </Provider>,
    document.getElementById('root')
)
