import Chart from "react-apexcharts";
import { numberWithSpaces, numberToTime } from '../../../../helpers/index';
import { useLingui } from '@lingui/react';
import { IGraphContentData } from '../../../../interfaces/types';
import { EStatisticsInterval } from '../../../../../reducers/data';
import moment from 'moment';
import { t } from '@lingui/macro';
 
const skGraph = require("apexcharts/dist/locales/sk")
const csGraph = require("apexcharts/dist/locales/cs")
const deGraph = require("apexcharts/dist/locales/de")
const enGraph = require("apexcharts/dist/locales/en")

interface IProps {
    graphData: IGraphContentData,
    graphTitle: string,
    interval?: EStatisticsInterval,
    isTime: boolean,
    yTitle: string
}

const XYGraph = ({graphData, graphTitle, interval, isTime, yTitle }: IProps) => {
    const lingui = useLingui()

    return (
        <Chart 
            options={{
                chart: {
                    toolbar: {
                        show: true,
                        tools: {
                            download: '<span><svg class="sico wh16"><use xlink:href="/assets/layout/icons.svg#s_save"></use></svg>'
                        }
                    },
                    locales: [skGraph, csGraph, enGraph, deGraph],
                    defaultLocale: lingui.i18n._locale
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'smooth',
                    width: 1
                },
                fill: {
                    type: 'gradient',
                    gradient: {
                        opacityFrom: 0.6,
                        opacityTo: 0.8,
                    }
                },
                // legend: {
                //   position: 'top',
                //   horizontalAlign: 'left'
                // },
                xaxis: {
                    title: {
                        text: graphTitle
                    },
                    labels: {
                        datetimeUTC: false
                    },
                    type: 'datetime'
                },
                yaxis: {
                    title: {
                        text: yTitle
                    },
                    labels: {
                        formatter: (val) => {
                            return numberWithSpaces(val)
                        }
                    }
                },
                tooltip: {
                    y: graphData.series.map(s => {
                            return {
                                title: {
                                    formatter: function (val) {
                                        return val
                                    }
                                },
                                formatter: (val) => {
                                    if(isTime) {
                                        return numberToTime(val)
                                    } else {
                                        return numberWithSpaces(val)
                                    }
                                }
                            }
                        }),
                    x: {
                        formatter: (val) => {
                            switch(interval) {
                                case EStatisticsInterval.day:
                                    return moment(val).local().format('DD. MMMM YYYY')
                                case EStatisticsInterval.month:
                                    return moment(val).local().format('MMMM YYYY')
                                case EStatisticsInterval.week:
                                    return t`Týždeň` + ' ' + moment(val).week() + '-' + moment(val).year()
                                default: 
                                    return moment(val).local().format('DD. MMMM YYYY - HH:mm:ss')
                            }
                        }
                    }
                },
            }} 
            series={graphData.series} 
            type="area" 
            height={450} 
        />
    )
};

export default XYGraph
