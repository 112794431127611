declare global {
  interface Window {
      env: any
  }
}

window.env = window.env || {}

let apiUrl = window.env.REACT_APP_API_URL
let dashboardCountingInterval = window.env.REACT_APP_DASHBOARD_UPDATE

let config = {
    api: {
        url: apiUrl,
        login: apiUrl + '/user/login',
        mart: apiUrl + '/menu/mart',
        events: apiUrl + '/menu/event',
        user: apiUrl + '/client/edit',
        password: apiUrl + '/client/password',
        report: apiUrl + '/report/get',
        monthEvent: apiUrl + '/calendar/event',
        monthAttendance: apiUrl + '/calendar/attendance',
        updateEvent: apiUrl + '/calendar/edit',
        addEvent: apiUrl + '/calendar/add',
        deleteEvent: apiUrl + '/calendar/delete',
        counting: apiUrl + '/dashboard/actual',
        support: apiUrl + '/menu/support',
        attendance: apiUrl + '/stats/footfall',
        gender: apiUrl + '/stats/gender',
        dwell: apiUrl + '/stats/dwelltime',
        occupancy: apiUrl + '/stats/occupation',
        heatTable: apiUrl + '/stats/heattable',
        map: apiUrl + '/map/get',
    },
    dashboard: {
        interval: dashboardCountingInterval
    }
}

export default config
