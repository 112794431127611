import { Trans } from '@lingui/macro';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import logoutUser from '../../../../reducers/api/logoutUser';
import { IApplicationStore } from '../../../../store/index';
import { useState, useRef } from 'react';
import useOnClickOutside from '../../../hooks/useOnClickOutside';

interface IProps {
}

const UserTooltip: React.FC<IProps> = () => {
    const dispatch = useDispatch()
    
    const ref = useRef<HTMLDivElement>(null)

    const [ show, setShow ] = useState(false)

    const user = useSelector((store: IApplicationStore) => store.userStore.user)

    useOnClickOutside(ref, () => {
        setShow(false)
    })

    if(!user) return null

    return (
        <div 
            className={`box_userdrop ${show?'active':''}`}
            ref={ref}
        >
            <NavLink 
                to="/app/profile" 
                role="button" 
                className="btn_user box_userdrop_button toggle-parent-class" 
                onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    setShow(!show)
                }}
            >
                <div className="btn_user_image">
                    <div>
                        <div>
                            <svg className="sicofill"><use xlinkHref="/assets/layout/icons.svg#s_person" /></svg>
                        </div>  
                    </div>
                </div>
                <div className="btn_user_desc">
                    <strong>{user.name}</strong>
                    {/* <span>CEO</span> */}
                </div>
                <svg className="sico btn_user_arrow"><use xlinkHref="/assets/layout/icons.svg#s_ar3_d" /></svg>
            </NavLink>  
            
            <div className="box_userdrop_inner">
                <div className="box_userdrop_drop">
                    <NavLink 
                        to="/app/profile" 
                        className="box_userdrop_item"
                        onClick={() => {
                            setShow(false)
                        }}
                    >
                        <svg className="sico"><use xlinkHref="/assets/layout/icons.svg#s_set" /></svg>    
                        <strong><Trans>Nastavenia učtu</Trans></strong>
                    </NavLink>   
                    <NavLink 
                        to="/" 
                        onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            dispatch(logoutUser())
                            setShow(false)
                        }} 
                        className="box_userdrop_item"
                    >
                        <svg className="sico"><use xlinkHref="/assets/layout/icons.svg#s_off" /></svg> 
                        <strong><Trans>Odhlasiť sa</Trans></strong>
                    </NavLink>  
                </div>     
            </div>
        </div>
    )
}

export default UserTooltip