import { Link, useRouteMatch, useHistory } from 'react-router-dom';

export interface ITab {
    title: string,
    link: string,
    tabId: string|null,
    disabled?: boolean
}

interface IProps {
    tabs: ITab[]
}

const Tabs = (props: IProps) => {
    const history = useHistory()
    const match = useRouteMatch<{tabId?: string}>()

    const currentTab = match.params.tabId

    return (
        <>
            <div className="tabs_inner hide_lgm">
                {props.tabs.map((t,i) => (
                    <Link key={i} to={t.link} title={t.title} className={`tabs_item ${(currentTab === t.tabId || ((currentTab === null || typeof currentTab === 'undefined') && t.tabId === null))?'active':''} ${t.disabled?'disabled':''}`}>{t.title}</Link>
                ))}
            </div>
            <div className="input_select_cont show_lgm">
                <select 
                    className="input_select"
                    value={currentTab}
                    onChange={(e) => {
                        let tab = props.tabs.find(t => (e.target.value === '' && t.tabId === null) || t.tabId === e.target.value)

                        if(tab) {
                            history.push(tab.link)
                        }
                    }}
                >
                    {props.tabs.filter(t => typeof t.disabled === 'undefined').map((t,i) => (
                        <option value={t.tabId?t.tabId:''} key={i}>{t.title}</option>
                    ))}
                </select>
                <svg className="sico"><use xlinkHref="/assets/layout/icons.svg#s_ar1_d" /></svg>
            </div>   
        </>
    )
};

export default Tabs
