import { useState, useEffect } from 'react';
import { EStatisticsInterval, EStatisticsGraphView, EStatisticsType, IGenderFilter, EStatisticsGender, EStatisticsGenderType } from '../../../../../reducers/data';
import Loader from '../../Loader';
import { parseGraphDataGender, numberWithSpaces } from '../../../../helpers/index';
import { useLingui } from '@lingui/react';
import { IGraphContentData, IGraphDataGender } from '../../../../interfaces/types';
import PieGraph from './PieGraph';
import XYGraph from './XYGraph';
import TableGraph from './TableGraph';
import getGenderStats from '../../../../../reducers/api/getGenderStats';
import ColumnStackedGraph from './ColumnStackedGraph';
import { t } from '@lingui/macro';

interface IProps {
    filter: IGenderFilter
}

const GraphGender = (props: IProps) => {
    const lingui = useLingui()

    const [loading, setLoading] = useState(false)
    const [graphResults, setGraphResults] = useState<IGraphDataGender[]|undefined>()
    const [graphData, setGraphData] = useState<IGraphContentData>({
        series: [],
        categories: []
    })
    
    useEffect(() => {
        setLoading(true)
        let isMounted = true

        getGenderStats({
            ...props.filter,
            graphView: props.filter.type === EStatisticsType.table?undefined:props.filter.graphView
        })
            .then(res => {
                setGraphResults(res)

                if(isMounted) {
                    setLoading(false)

                    let resObject = parseGraphDataGender(res?res:[], props.filter, lingui.i18n)
                    setGraphData(resObject)
                }
            })
            .catch(e => {
                if(isMounted) {
                    setLoading(false)
                    setGraphResults(undefined)
                    setGraphData({
                        series: [],
                        categories: []
                    })
                }
            })
        return () => { isMounted = false }
    }, [lingui.i18n, props.filter])

    let graphTitle = t`Multi`
    let isTime = false;

    if(props.filter.graphView === EStatisticsGraphView.date) {
        graphTitle = t`Dátum`
    } else if (props.filter.graphView === EStatisticsGraphView.zone) {
        graphTitle = t`Zóna`
    }

    if(props.filter.type === EStatisticsType.cake) {
        graphTitle = t`Gender`
    }

    let yTitle =  t`Počet návštevníkov`
    
    let dataToSend: {
        date: {
            from: string,
            to: string
        }[],
        type: EStatisticsType,
        interval?: EStatisticsInterval,
        locations?: number[],
        zones?: number[],
        group?: EStatisticsGraphView,
        gender?: EStatisticsGender,
        outputType?: EStatisticsGenderType
    } = {
        date: props.filter.dateRanges,
        type: props.filter.type,
        interval: props.filter.interval,
        locations: props.filter.localities,
        zones: props.filter.zones,
        group: props.filter.graphView,
        gender: props.filter.gender,
        outputType: props.filter.genderType
    }

    let objJsonStr = JSON.stringify(dataToSend);
    let objJsonB64 = Buffer.from(objJsonStr).toString("base64");

    let filteredResults = graphResults

    if(props.filter.type === EStatisticsType.table) {
        filteredResults = graphResults?.map(gr => {
            return {
                ...gr,
                data: gr.data.filter(grd => {
                    if(
                        (props.filter.gender === EStatisticsGender.man) &&
                        (grd.gender === 'M')
                    ) {
                        return true
                    } 
                    
                    if(
                        (props.filter.gender === EStatisticsGender.woman) &&
                        (grd.gender === 'F')
                    ) {
                        return true
                    }
                    
                    if(props.filter.gender === EStatisticsGender.all) {
                        return true
                    }

                    return false
                }).map(d => {
                    return {
                        ...d,
                        count: props.filter.genderType === EStatisticsGenderType.percentage?Number(d.percentage) + '%':numberWithSpaces(Number(d.count))
                    }
                })
            }
        })
    }
    
    console.log(filteredResults)
    return (
        <>
            <Loader value={loading}/>
            {props.filter.type === EStatisticsType.column && (
                <ColumnStackedGraph isPercentage={props.filter.genderType === EStatisticsGenderType.percentage?true:false} graphData={graphData}/>
            )}
            {(props.filter.type === EStatisticsType.cake) && (
                <PieGraph graphTitle={graphTitle} graphData={graphData}/>
            )}
            {(props.filter.type === EStatisticsType.xy) && (
                <XYGraph graphTitle={graphTitle} graphData={graphData} interval={props.filter.interval} isTime={isTime} yTitle={yTitle} />
            )}
            {(props.filter.type === EStatisticsType.table) && (
                <TableGraph graphResults={filteredResults} filter={objJsonB64} isGender={props.filter.gender === EStatisticsGender.all} interval={props.filter.interval}/>
            )}
        </>
    )
};

export default GraphGender
