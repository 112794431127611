import { useState, useEffect } from 'react';
import { IDwellTimeFilter, EStatisticsInterval, EStatisticsDateType } from '../../../../../reducers/data';
import getDwellStats from '../../../../../reducers/api/getDwellStats';
import Loader from '../../Loader';
import { useLingui } from '@lingui/react';
import { IGraphContentData } from '../../../../interfaces/types';
import XYGraph from './XYGraph';
import { parseGraphData } from '../../../../helpers/index';
import { t } from '@lingui/macro';

interface IProps {
    filter: IDwellTimeFilter
}

const GraphDwellTime = (props: IProps) => {
    const lingui = useLingui()

    const [loading, setLoading] = useState(false)
    const [graphData, setGraphData] = useState<IGraphContentData>({
        series: [],
        categories: []
    })
    
    useEffect(() => {
        setLoading(true)
        let isMounted = true

        getDwellStats(props.filter)
            .then(res => {
                console.log(res)

                if(isMounted) {
                    setLoading(false)

                    let resObject = parseGraphData(res?res:[], lingui.i18n)
                    setGraphData(resObject)
                }
            })
            .catch(e => {
                if(isMounted) {
                    setLoading(false)
                    setGraphData({
                        series: [],
                        categories: []
                    })
                }
            })
        return () => { isMounted = false }
    }, [lingui.i18n, props.filter])

    let graphTitle = t`Multi`
    let graphInterval = EStatisticsInterval.min60;
    let isTime = true;

    if(props.filter.dateType === EStatisticsDateType.week) {
        graphInterval = EStatisticsInterval.day;
    } else if (props.filter.dateType === EStatisticsDateType.month) {
        graphInterval = EStatisticsInterval.day;
    } else if (props.filter.dateType === EStatisticsDateType.year) {
        graphInterval = EStatisticsInterval.month;
    }
    let yTitle =  t`Strávený čas`

    return (
        <>
            <Loader value={loading}/>
            <XYGraph graphTitle={graphTitle} graphData={graphData} interval={graphInterval} isTime={isTime} yTitle={yTitle}/>
        </>
    )
};

export default GraphDwellTime
