import { useLingui } from '@lingui/react';
import { IGraphData } from '../../../../interfaces/types';
import { t, Trans } from '@lingui/macro';
import { useMemo } from 'react';
import { getDateRangeTitle } from '../../../../helpers/index';
import { EStatisticsInterval } from '../../../../../reducers/data';
import moment from 'moment';
 
interface IProps {
    graphResults?: IGraphData[],
    filter?: string
}

interface ICell {
    name: string,
    count: number,
    from: string,
    to: string
}

const TableGraph = ({graphResults, filter}: IProps) => {
    const lingui = useLingui()

    let splitted = useMemo(() => {
        //Split by time
        let splittedVal: {
            start: string,
            end: string,
            values: ICell[]
        }[] = []

        graphResults?.forEach(r => {
            r.data.forEach(d => {
                let was = false

                splittedVal.forEach(s => {
                    if(s.start === d.start && s.end === d.end) {
                        was = true
                        s.values.push({
                            count: Number(d.count),
                            name: r.name,
                            from: d.start,
                            to: d.end
                        })
                    }
                })

                if(!was) {
                    splittedVal.push({
                        start: d.start,
                        end: d.end,
                        values: [{
                            count: Number(d.count),
                            name: r.name,
                            from: d.start,
                            to: d.end
                        }]
                    })
                }
            })
        })

        return splittedVal
    }, [graphResults])

    // let urlExport = "https://api.clientportal.visitor.sk/table/?id=" + filter + "&token=" + store.getState().userStore.token

    return (
        <div className="table_scroll mgt-20 pdb-40">
            <div className="pdb-10">
            {/* <a href={urlExport} className="btn_action text_nowrap_download text_w500">
            <svg className="sico wh16"><use xlinkHref="/assets/layout/icons.svg#s_dl"></use></svg>
            
            <span><Trans>Stiahnuť tabuľku</Trans></span>
            </a> */}
            </div>
            <table className="table">
                <thead>
                    <tr>
                    <th><Trans>Lokalita / Zóna</Trans></th>
                    {splitted.filter( (gr, i) => moment(gr.end).format("mm") === "00" && moment(gr.end).format("HH") > "08" && moment(gr.end).format("HH") < "22" ).map( (gr,i) => (
                        <th key={i}> {moment(gr.end).format("HH:mm")}</th>
                    ))}
                    {/* {graphResults?.map((gr,i) => (
                        <th key={i}>
                            {gr.name}
                        </th>
                    ))} */}
                    </tr>
                </thead>
                <tbody>
                    {graphResults?.map((s,i) => (
                        <tr key={i}>
                            {/* <td>{getDateRangeTitle(s.start, s.end, lingui.i18n)}</td> */}
                            <td>{s.name}</td>
                            {s.data.filter( (vl, ind) => moment(vl.end).format("mm") === "00" && moment(vl.end).format("HH") > "08" && moment(vl.end).format("HH") < "22" ).map((v,j) => (
                                <td key={j}>
                                    <div className="table_drop narrow">
                                        <span>{v.count}</span>
                                        <div className="drop">
                                            <div><Trans>Názov</Trans>: <strong>{s.name}</strong></div>
                                            <div><Trans>Deň</Trans>: <strong>{moment(v.start).format('dddd')}</strong></div>
                                            <div><Trans>Dátum</Trans>: <strong>{getDateRangeTitle(v.start, v.end, EStatisticsInterval.day, lingui.i18n)}</strong></div>
                                            <div><Trans>Stav</Trans>: <strong className="color_green">{v.count}</strong></div>
                                        </div>
                                    </div>
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>    
        </div>
    )
};

export default TableGraph
