import { Trans, t } from '@lingui/macro';
import { NavLink, useLocation } from 'react-router-dom';
import { EPermission } from "../../interfaces/types";
import { EStatisticsTabs } from "../pages/PageStatistics";
import { EUserTabs } from '../pages/PageUserProfile';
import AuthPermission from "../wrappers/AuthPermission";

interface IProps {
    show: boolean,
    setShow: (val: boolean) => void
}

interface IPropsMenu {
    path: string,
    name: string,
    icon: string,
    alternativePaths: string[],
    hideMenu: () => void
}

const MenuItem: React.FC<IPropsMenu> = (props) => {
    const location = useLocation()

    let isActive = props.alternativePaths.concat(props.path).includes(location.pathname)

    return (
        <NavLink 
            to={props.path} 
            activeClassName={`${isActive?'active':''}`}
            onClick={() => props.hideMenu()}
        >
            <svg className="sico"><use xlinkHref={`/assets/layout/icons.svg#${props.icon}`} /></svg>
            <span>{props.name}</span>
        </NavLink>
    )
}
const Menu: React.FC<IProps> = (props) => {
    return (
        <header className="header">
            <div className="header_inner">
                <div className="header_top">
                    <div className="header_top_inner">
                        <button 
                            type="button" 
                            className="header_button toggle-body-class" 
                            onClick={() => props.setShow(!props.show)}
                        >
                            <span className="btn_back text_w500 color_white">
                                <svg className="sico"><use xlinkHref="/assets/layout/icons.svg#s_ar2_l" /></svg>
                                <span><Trans>Skryť menu</Trans></span>
                            </span>
                            <svg className="sico icon"><use xlinkHref="/assets/layout/icons.svg#s_menu" /></svg>
                        </button>   
                    </div>

                    <a href="https://visitor.sk" title="Visitor.sk" target="_blank" rel="noreferrer" className="header_logo">
                        <svg className="sico"><use xlinkHref="/assets/layout/icons.svg#s_logo" /></svg>
                    </a>
                </div>     
                <div className="header_bottom">
                    <nav className="nav">
                        <MenuItem 
                            icon={'s_dash'} 
                            name={t`Dashboard`} 
                            alternativePaths={[]} 
                            path='/app' 
                            hideMenu={() => props.setShow(false)}
                        />
                        <AuthPermission permissions={[EPermission.stats, EPermission.dwell, EPermission.occupancy, EPermission.heatmap, EPermission.gender]} or>
                            <MenuItem 
                                icon={'s_stats'} 
                                name={t`Štatistiky`} 
                                alternativePaths={Object.values(EStatisticsTabs).map(t => '/app/statistics/' + t)} 
                                path='/app/statistics' 
                                hideMenu={() => props.setShow(false)}
                            />
                        </AuthPermission>
                        <AuthPermission permissions={[EPermission.report]}>
                            <MenuItem 
                                icon={'s_rep'} 
                                name={t`Reporty`} 
                                alternativePaths={[]} 
                                path='/app/reports' 
                                hideMenu={() => props.setShow(false)}
                            />
                        </AuthPermission>
                        <AuthPermission permissions={[EPermission.calendar]}>
                            <MenuItem 
                                icon={'s_cal'} 
                                name={t`Kalendár`} 
                                alternativePaths={[]} 
                                path='/app/calendar' 
                                hideMenu={() => props.setShow(false)}
                            />
                        </AuthPermission>
                        <AuthPermission permissions={[EPermission.map]}>
                            <MenuItem 
                                icon={'s_map'} 
                                name={t`Interaktívna mapa`} 
                                alternativePaths={[]} 
                                path='/app/map' 
                                hideMenu={() => props.setShow(false)}
                            />
                        </AuthPermission>
                        <MenuItem 
                            icon={'s_set'} 
                            name={t`Nastavenia`} 
                            alternativePaths={Object.values(EUserTabs).map(t => '/app/profile/' + t)} 
                            path='/app/profile' 
                            hideMenu={() => props.setShow(false)}
                        />
                    </nav>
                </div>    
            </div> 
        </header>
    )
}

export default Menu