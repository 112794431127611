import { Trans, t } from '@lingui/macro';
import { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { useLingui } from "@lingui/react";
import getMonthEvents from '../../../reducers/api/getMonthEvents';
import { useSelector, useDispatch } from 'react-redux';
import { IApplicationStore } from '../../../store/index';
import { EEventType, IEventDay, IEvent, IAttendanceDay } from '../../interfaces/types';
import Loader from '../global/Loader';
import DatePicker from "react-datepicker";
import Event from "../global/Event";
import updateEvent from "../../../reducers/api/updateEvent";
import deleteEvent from "../../../reducers/api/deleteEvent";
import addNewEvent from "../../../reducers/api/addNewEvent";
import AddNewEvent from "../global/AddNewEvent";
import getComingEvents from '../../../reducers/api/getComingEvents';
import { useLocation, useHistory } from 'react-router-dom';
import getMonthAttendance from '../../../reducers/api/getMonthAttendance';

interface IProps {
}

const PageCalendar = (props: IProps) => {
    const lingui = useLingui()
    const dispatch = useDispatch()
    const location = useLocation()
    const { push } = useHistory()

    const activities = useSelector((store: IApplicationStore) => store.dataStore.activities)

    const [selectedDay, setSelectedDay] = useState(new Date())
    const [events, setEvents] = useState<IEventDay[]>([])
    const [attendance, setAttendance] = useState<IAttendanceDay[]>([])
    const [selectedReport, setSelectedReport] = useState<IEvent|undefined>(undefined)
    const [loadingAttendance, setLoadingAttendance] = useState(false)
    const [loadingEvents, setLoadingEvents] = useState(false)

    const selectedMarts = useSelector((store: IApplicationStore) => store.dataStore.selectedMarts)
    const selectedMonth = moment(selectedDay).format('YYYY-MM')
    const loading = loadingAttendance || loadingEvents

    useEffect(() => {
        let isEvent = new URLSearchParams(location.search).get("e")

        if(isEvent === null) {
            isEvent = new URLSearchParams(location.search).get("s")
        }

        if(isEvent) {
            let eventFind = Number(isEvent)

            let allEvents: IEvent[] = []
            
            events.forEach(e => e.event?.forEach(ev => {
                allEvents.push(ev)
            }))

            let event = allEvents.find(e => e.id === eventFind)

            if(typeof event !== 'undefined') {
                setSelectedDay(moment(event.date).toDate())
            }
        }
    }, [location.search, events, push])

    useEffect(() => {
        let dayEvent = events.find(d => d.date === moment(selectedDay).format('YYYY-MM-DD'))

        if(dayEvent && dayEvent.event) {
            setSelectedReport(dayEvent.event.find(e => e.type === EEventType.report))
        } else {
            setSelectedReport(undefined)
        }
    }, [selectedDay, events])

    const loadEvents = useCallback(() => {
        setLoadingEvents(true)

        dispatch(getComingEvents(lingui.i18n._locale, selectedMarts.map(m => m.id)))

        getMonthEvents(selectedMonth, lingui.i18n._locale, selectedMarts.map(m => m.id))
            .then(res => {
                setLoadingEvents(false)
                setEvents(res)
            })
            .catch(e => {
                setLoadingEvents(false)
                setEvents([])
            })
        
        setLoadingAttendance(true)
        setAttendance([])

        getMonthAttendance(selectedMonth, selectedMarts.map(m => m.id))
            .then(res => {
                setLoadingAttendance(false)
                setAttendance(res)
            })
            .catch(e => {
                setLoadingAttendance(false)
            })
    }, [dispatch, selectedMonth, lingui.i18n._locale, selectedMarts])

    
    useEffect(() => {
        loadEvents()
    }, [loadEvents, activities.length])
    
    const updateEventFnc = (ev: IEvent) => {
        setLoadingAttendance(true)

        updateEvent(ev.id, ev.mart, ev.name, ev.date, ev.description)
            .then(res => {
                loadEvents()
                setLoadingAttendance(false)
            })
            .catch(e => {
                loadEvents()
                setLoadingAttendance(false)
            })
    }

    const deleteEventFnc = (ev: IEvent) => {
        setLoadingAttendance(true)

        deleteEvent(ev.id)
            .then(res => {
                loadEvents()
                setLoadingAttendance(false)
            })
            .catch(e => {
                loadEvents()
                setLoadingAttendance(false)
            })
    }
    
    const createEventFnc = async (name: string, description: string, date: string, marts: number[]) => {
        setLoadingAttendance(true)

        for(let mart of marts) {
            await addNewEvent(mart, name, date, description)
                .catch(e => {
                    console.log(e)
                })
        }

        setLoadingAttendance(false)

        loadEvents()
    }

    const dayEvent = events.find(d => d.date === moment(selectedDay).format('YYYY-MM-DD'))
    const holiday = dayEvent?.event?.find(e => e.type === EEventType.holliday)
    const reports = dayEvent?.event?.filter(e => e.type === EEventType.report)
    const notes = dayEvent?.event?.filter(e => e.type === EEventType.note)
    const attendanceDay = attendance.find(at => at.date === moment(selectedDay).format('YYYY-MM-DD'))
    
    const attendanceVal = attendanceDay?Number(attendanceDay.value):0
    
    let attName = t`návštevníkov`

    if(attendanceVal === 1) {
        attName = t`návštevník`
    } else if ((attendanceVal <= 4) && (attendanceVal > 1)) {
        attName = t`návštevníci`
    }

    return (
        <>
            <h1 className="title_h1 pdt-40">
                VISITOR <span className="text_w400"><Trans>kalendár</Trans></span>
                <div className="icon_info wh24 diblock v_top">
                    <svg className="sicofill"><use xlinkHref="/assets/layout/icons.svg#s_info" /></svg>
                </div>
            </h1>

            <p className="text_basic mgt-20 wmax460">
                <Trans>Informácie o počasí, celková denná návštevnosť, upozornenie o existencii reportu, tvorba a prehľad poznámok. Všetky tieto podstatné informácie nájdete na jednom mieste.</Trans>
            </p>        

            <div className="box pos_rel mgt-60 mgb-30 pd-40 pdl-25lg pdr-25lg">    
                {selectedMarts.length !== 0?(
                    <div className="grid small-space align-start flex-nowrap-lg">
                        <div className="col_default col1_lgm offset">
                            <h2 className="calendar-date">{moment(selectedMonth).locale(lingui.i18n._locale).format('MMMM')} {moment(selectedMonth).year()}</h2>
                        </div>

                        <div className="col_fill col1_lgm offset">
                        </div>

                        <div className="col_default col1_lgm self-center mgt-10lg">
                            <div className="grid small-space">
                                <AddNewEvent    
                                    date={moment(selectedDay).format('YYYY-MM-DD')}
                                    createEvent={createEventFnc}
                                />
                            </div>
                        </div>
                    </div>
                ):null}

                <div id="event-modal-wrapper" className={`box_calendar mgt-40 ${loading?'loader-wrapper':''}`}>
                    <Loader value={loading}/>
                    {selectedMarts.length === 0?(
                        <div className="message-wrapper">
                            <div className="message-content message-content--warning">
                                <Trans>Musíte vybrať aspoň jedno obchodné centrum.</Trans>
                            </div>
                        </div>
                    ):(
                        <>
                            <div className="box_calendar_left pdb-30lg">
                                <DatePicker
                                    selected={selectedDay}
                                    onChange={(date:any) => setSelectedDay(date)}
                                    locale={lingui.i18n._locale}
                                    highlightDates={events.filter(e => typeof e.event !== 'undefined').map(e => moment(e.date).toDate())}
                                    onMonthChange={(date) => {
                                        setSelectedDay(moment(date).startOf('month').toDate())
                                    }}
                                    inline
                                />
                            </div>

                            <div className="box_calendar_right pdl-30 pd-0lg">
                                <div className="grid">
                                    <div className="col2 col1_mdm mgt-40lg">
                                        <div>
                                            <h3 className="title_h3">{moment(selectedDay).locale(lingui.i18n._locale).format('D. MMMM')} <span className="text_w400">{selectedDay.getFullYear()}</span></h3>
                                            {holiday?(
                                                <p className="mg-0 mgt-5 text_italic">{holiday.name}</p>
                                            ):null}
                                        </div>
                                        {dayEvent ? (
                                            <>
                                                {dayEvent.icon !== '' ? (
                                                    <div className="mgt-30">
                                                        <h4 className="text_s16 mg-0"><Trans>Počasie</Trans>:</h4>

                                                        <div className="item_weather mgt-15">
                                                            <div className="item_weather_image">
                                                                <img src={dayEvent.icon} alt={t`Počasie`} />
                                                            </div> 
                        
                                                            <div className="item_weather_desc">
                                                                <div>{dayEvent.value} °C</div>
                                                            </div>       
                                                        </div>
                                                    </div>
                                                ):null}

                                                <div className="mgt-30">
                                                    <h4 className="text_s16 mg-0"><Trans>Navštevnosť</Trans>:</h4>

                                                    <div className="text_s16 mgt-10 text_w500 color2">
                                                        <strong className="color_green">{attendanceVal}</strong> {attName}   
                                                    </div>
                                                </div>

                                                {((typeof reports !== 'undefined') && (reports.length > 0)) ?(
                                                    <div className="mgt-30">
                                                        <h4 className="text_s16 mg-0"><Trans>Reporty</Trans>:</h4>

                                                        <div className="grid align-center">
                                                            <div className="input_select_cont col_default mgt-15">
                                                                <select 
                                                                    className="input_select basic border"
                                                                    onChange={(e) => {
                                                                        let report = reports.find(r => r.id === Number(e.target.value))

                                                                        setSelectedReport(report)
                                                                    }}
                                                                    value={selectedReport?selectedReport.id.toString():''}
                                                                >
                                                                    {reports.map((r,i) => (
                                                                        <option value={r.id}>{selectedMarts.find(m => m.id === r.mart)?.name} - {r.name}</option>
                                                                    ))}
                                                                </select>
                                                                <svg className="sico"><use xlinkHref="/assets/layout/icons.svg#s_ar1_d" /></svg>
                                                            </div>
                                                            
                                                            {selectedReport?(
                                                                <a href={selectedReport.link} title={selectedReport.name} target="_blank" rel="noreferrer" className="btn_action small text_w700 col_default mgt-15">
                                                                    <span><Trans>Stiahnuť</Trans></span>
                                                                    <svg className="sico wh10"><use xlinkHref="/assets/layout/icons.svg#s_dl" /></svg>
                                                                </a>  
                                                            ):null}
                                                        </div>
                                                    </div>
                                                ):null}
                                            </>
                                        ):null}
                                    </div>

                                    <div className="col2 col1_mdm mgt-40lg item_activity_list">
                                        {notes?.map((n,i) => (
                                            <Event 
                                                key={i} 
                                                event={n}
                                                updateEvent={(ev) => updateEventFnc(ev)}
                                                deleteEvent={(ev) => deleteEventFnc(ev)}
                                            />
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>  
        </>
    )
};

export default PageCalendar
