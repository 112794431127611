import { Trans, t } from '@lingui/macro';
import { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { useLingui } from "@lingui/react";
import { useSelector } from 'react-redux';
import { IApplicationStore } from '../../../store/index';
import { IEvent } from '../../interfaces/types';
import ReactDOM from 'react-dom';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import FadeAnimation from "./FadeAnimation";
import { useLocation, useHistory } from 'react-router-dom';

interface IProps {
    event: IEvent,
    updateEvent: (ev: IEvent) => void,
    deleteEvent: (ev: IEvent) => void
}

const Event = (props: IProps) => {
    const lingui = useLingui()
    const location = useLocation()
    const { push } = useHistory()

    const ref = useRef<HTMLDivElement>(null)
    const [showModal, setShowModal] = useState(false)
    const [showTooltip, setShowTooltip] = useState(false)
    const [newName, setNewName] = useState('')
    const [newDescription, setNewDescription] = useState('')

    useOnClickOutside(ref, () => {
        setShowTooltip(false)
    })

    useEffect(() => {
        setNewName(props.event.name)
        setNewDescription(props.event.description)
    }, [props.event])

    useEffect(() => {
        let isEventEdit = new URLSearchParams(location.search).get("e")

        if(isEventEdit) {
            let editedEvent = Number(isEventEdit)

            if(props.event.id === editedEvent) {
                setShowModal(true)
                push('/app/calendar')
            }
        }
    }, [push, location.search, props.event.id])

    let marts = useSelector((store: IApplicationStore) => store.dataStore.marts)

    let mart = marts.find(m => m.id === props.event.mart)

    if(typeof mart === 'undefined') {
        return null
    }

    const hideModal = () => {
        setNewName(props.event.name)
        setNewDescription(props.event.description)
        setShowModal(false)
    }

    const submitForm = (e: React.FormEvent) => {
        e.preventDefault()
        e.stopPropagation()

        props.updateEvent({
            ...props.event,
            name: newName,
            description: newDescription
        })

        hideModal()
    }
    
    return (
        <>
            <div className="item_activity type2">
                <div className="item_activity_desc">
                    <span>{moment(props.event.date).format('DD. MM. YYYY')}</span>
                    <strong>{mart.name} - {props.event.name}</strong>    
                    <p>
                        {props.event.description}
                    </p>                         
                </div>

                <div className={`item_activity_control ${showTooltip?'active':''}`} ref={ref}>
                    <button 
                        type="button" 
                        className="toggle-parent-className wh16"
                        onClick={() => setShowTooltip(true)}
                    >
                        <svg className="sicofill">
                            <use xlinkHref="/assets/layout/icons.svg#s_menu2" />
                        </svg>
                    </button>
                    <div className="action_drop">
                        <button 
                            className="add-element-className" 
                            type="button" 
                            onClick={() => {
                                setShowTooltip(false)
                                setShowModal(true)
                            }} 
                        >
                            <svg className="sico wh12"><use xlinkHref="/assets/layout/icons.svg#s_edit" /></svg>
                            <span><Trans>Editovať</Trans></span>   
                        </button>
                        <button 
                            type="button"
                            onClick={() => {
                                setShowTooltip(false)
                                props.deleteEvent(props.event)
                            }} 
                        >
                            <svg className="sico wh12"><use xlinkHref="/assets/layout/icons.svg#s_delete" /></svg>
                            <span><Trans>Odstraniť</Trans></span>   
                        </button>
                        <button 
                            type="button" 
                            className="trigger-closest-click" 
                            onClick={() => setShowTooltip(false)}
                        >
                            <svg className="sico wh12-8"><use xlinkHref="/assets/layout/icons.svg#s_cross" /></svg>
                            <span><Trans>Zavrieť</Trans></span>   
                        </button>                                
                    </div>
                </div>
            </div>
            {ReactDOM.createPortal((
                <FadeAnimation animKey={showModal?'1':'0'}>
                    <div className={`modal modal1 ${showModal?'active':''}`}>
                        <button className="modal_cover toggle-parent-className" type="button" onClick={() => hideModal()} ></button>
                        <div className="modal_inner">
                            <div className="modal_content wmax460 pd-20">
                                <form onSubmit={(e) => submitForm(e)}>
                                    <input 
                                        type="text" 
                                        className="input_text2" 
                                        value={newName}
                                        onChange={(e) => setNewName(e.target.value)}
                                        placeholder={t`Názov` + '...'} 
                                        required
                                    />  
                                    
                                    <div className="grid mgt-10">
                                        <div className="col_default mgt-10">
                                            <a href="/" title="" style={{pointerEvents: 'none'}} className="btn_action text_w500 text_nowrap">
                                                <svg className="sico wh16"><use xlinkHref="/assets/layout/icons.svg#s_cal" /></svg>
                                                <span>{moment(props.event.date).locale(lingui.i18n._locale).format('D. MMMM YYYY')}</span>
                                            </a>
                                        </div>
                                    </div>

                                    {/* <div className="mgt-25">
                                        <label className="text_w500 dblock">Chcete dostať upozornenie?</label>

                                        <div className="grid small-space">
                                            <label className="label_radio mgt-10 col_default">
                                                <input type="radio" value="1" name="r1" checked />
                                                <span>5 min pred</span>
                                            </label>

                                            <label className="label_radio mgt-10 col_default">
                                                <input type="radio" value="1" name="r1" />
                                                <span>10 min pred</span>
                                            </label>

                                            <label className="label_radio mgt-10 col_default">
                                                <input type="radio" value="1" name="r1" />
                                                <span>30 min pred</span>
                                            </label>

                                            <label className="label_radio mgt-10 col_default">
                                                <input type="radio" value="1" name="r1" />
                                                <span>1 hod pred</span>
                                            </label>
                                        </div>
                                    </div> */}

                                    <textarea 
                                        className="input_text2 mgt-25" 
                                        cols={30} 
                                        rows={1} 
                                        value={newDescription}
                                        onChange={(e) => setNewDescription(e.target.value)}
                                        placeholder={t`Poznámka` + '...'} 
                                        required
                                    />

                                    <div className="text_right mgt-15">
                                        <button type="submit" className="btn_basic">
                                            <svg className="sico"><use xlinkHref="/assets/layout/icons.svg#s_save" /></svg>
                                            <span><Trans>Uložiť</Trans></span>
                                        </button>
                                    </div>
                                </form>
                            </div>                        
                        </div>
                    </div>
                </FadeAnimation>
            ), document.getElementById('event-modal-wrapper')||document.body)}
        </>
    )
};

export default Event
