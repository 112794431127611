import { Trans } from "@lingui/macro"
import { useState, useRef, useEffect } from 'react';
import useOnClickOutside from '../../../hooks/useOnClickOutside';
import { useSelector, useDispatch } from 'react-redux';
import { IApplicationStore } from '../../../../store/index';
import getComingEvents from '../../../../reducers/api/getComingEvents';
import { useLingui } from "@lingui/react";
import Loader from "../Loader";
import { IEvent, EEventType } from '../../../interfaces/types';
import moment from 'moment';
import deleteEvent from '../../../../reducers/api/deleteEvent';
import { setLoadingStore, ELoadingActions } from '../../../../reducers/loading';
import { useHistory } from 'react-router-dom';
interface IProps {
}

interface IPropsActivity {
    activity: IEvent,
    deleteActivity: () => void
}

const Activity: React.FC<IPropsActivity> = ({activity, deleteActivity}) => {
    const ref = useRef<HTMLDivElement>(null)
    const history = useHistory()

    const marts = useSelector((store: IApplicationStore) => store.dataStore.marts)
    
    const [show, setShow] = useState(false)

    useOnClickOutside(ref, () => {
        setShow(false)
    })

    let mart = marts.find(m => activity.mart === m.id)

    let title = activity.name

    if(mart) {
        title = mart.name + ' - ' + title
    }
    
    let color = 'transparent'

    switch(activity.type) {
        case EEventType.holliday:
            color = '#EDC2CE'
            break
        case EEventType.note:
            color = '#F8BA48'
            break
        case EEventType.report:
            color = '#C4302F'
            break
    }

    return (
        <div className="item_activity">
            <div className="item_activity_line" style={{backgroundColor: color}}/>
            <div className="item_activity_desc">
                <a 
                    href="/"
                    onClick={(e) => {
                        e.preventDefault()
                        history.push('/app/calendar?s=' + activity.id)
                    }}
                >
                    {title}
                </a>
                <span>{moment(activity.date).format('DD. MM. YYYY')}</span>
            </div>

            {activity.type === EEventType.note ? (
                <div 
                    className={`item_activity_control ${show?'active':''}`}    
                    ref={ref}
                >
                    <button 
                        type="button" 
                        className="toggle-parent-class wh16"
                        onClick={() => setShow(true)}
                    >
                        <svg className="sicofill">
                            <use xlinkHref="/assets/layout/icons.svg#s_menu2" />
                        </svg>
                    </button>
                    <div className="action_drop">
                        <button
                            onClick={() => {
                                setShow(false)
                                deleteActivity()
                            }}    
                        >
                            <svg className="sico wh12"><use xlinkHref="/assets/layout/icons.svg#s_delete" /></svg>
                            <span><Trans>Odstrániť</Trans></span>
                        </button>
                        <button
                            onClick={() => {
                                setShow(false)
                                history.push('/app/calendar?e=' + activity.id)
                            }}    
                        >
                            <svg className="sico wh12"><use xlinkHref="/assets/layout/icons.svg#s_edit" /></svg>
                            <span><Trans>Editovať</Trans></span>
                        </button>

                        <button 
                            className="trigger-closest-click" 
                            onClick={() => setShow(false)}
                        >
                            <svg className="sico wh12-8"><use xlinkHref="/assets/layout/icons.svg#s_cross" /></svg>
                            <span><Trans>Zavrieť</Trans></span>
                        </button>
                    </div>
                </div>
            ):null}
        </div>
    )
}

const Activities: React.FC<IProps> = () => {
    const dispatch = useDispatch()
    const lingui = useLingui()
    
    const selectedMarts = useSelector((store: IApplicationStore) => store.dataStore.selectedMarts)
    const loading = useSelector((store: IApplicationStore) => store.loadingStore.loadingActivities)
    const activities = useSelector((store: IApplicationStore) => store.dataStore.activities)


    useEffect(() => {
        dispatch(getComingEvents(lingui.i18n._locale, selectedMarts.map(m => m.id)))
    }, [dispatch, lingui.i18n._locale, selectedMarts])

    const deleteEventFnc = (ev: IEvent) => {
        dispatch(setLoadingStore(ELoadingActions.SET_LOADING_ACTIVITIES, true))

        deleteEvent(ev.id)
            .then(res => {
                dispatch(getComingEvents(lingui.i18n._locale, selectedMarts.map(m => m.id)))
                dispatch(setLoadingStore(ELoadingActions.SET_LOADING_ACTIVITIES, false))
            })
            .catch(e => {
                dispatch(getComingEvents(lingui.i18n._locale, selectedMarts.map(m => m.id)))
                dispatch(setLoadingStore(ELoadingActions.SET_LOADING_ACTIVITIES, false))
            })
    }

    if(selectedMarts.length === 0) {
        return null
    }

    return (
        <>
            <h2 className="title_h4"><Trans>Planované aktivity</Trans></h2>

            <div className={`sidebar_scroll item_activity_list mgt-20 ${loading?'loader-wrapper':''}`}>
                <Loader value={loading}/>
                {activities.length === 0 && (
                    <small className="empty">
                        <Trans>Nemáte naplánované žiadne aktivity.</Trans>
                    </small>
                )}
                {activities.map((act, i) =>(
                    <Activity activity={act} deleteActivity={() => deleteEventFnc(act)} key={i}/>
                ))}
            </div>
        </>
    )
}

export default Activities