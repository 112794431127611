import { Trans, t } from '@lingui/macro';
import { useRouteMatch } from 'react-router-dom';
import Tabs from '../global/Tabs';
import FormPasswordChange from '../forms/FormPasswordChange';
import FadeAnimation from "../global/FadeAnimation";
import FormUserChange from '../forms/FormUserChange';

interface IProps {
}

export enum EUserTabs {
    password = 'password',
    personal = 'personal'
}

const PageUserProfile = (props: IProps) => {
    const match = useRouteMatch<{tabId?: string}>()

    const currentTab = match.params.tabId

    return (
        <>
            <div className="grid small-space align-start pdt-40">
                <h1 className="title_h1 col_default">
                    VISITOR <span className="text_w400"><Trans>nastavenie učtu</Trans></span>
                </h1>
                <div className="icon_info wh24 col_default">
                    <svg className="sicofill"><use xlinkHref="/assets/layout/icons.svg#s_info" /></svg>
                </div>
            </div>

            <p className="text_basic mgt-20 wmax460">
                <Trans>Zmena osobných údajov a prístupových hesiel</Trans>
            </p>

            <div className="tabs mgt-40">
                <Tabs
                    tabs={[
                        {
                            link: '/app/profile',
                            tabId: null,
                            title: t`Zmena prístupového hesla`
                        },
                        {
                            link: '/app/profile/' + EUserTabs.personal,
                            tabId: EUserTabs.personal,
                            title: t`Zmena osobných údajov`
                        }
                    ]}
                />             
            </div>

            <FadeAnimation animKey={currentTab?currentTab:''}>
                {((currentTab === EUserTabs.password) || (typeof currentTab === 'undefined')) && (
                    <div className="box mgt-30 mgb-30 pd-40 pdl-25lg pdr-25lg">    

                        <h2 className="title_h2 text_center"><Trans>Zmena prístupového hesla</Trans></h2>

                        <FormPasswordChange/>
                        
                    </div>
                )}
                {currentTab === EUserTabs.personal && (
                    <div className="box mgt-30 mgb-30 pd-40 pdl-25lg pdr-25lg">    

                        <h2 className="title_h2 text_center"><Trans>Zmena osobných údajov</Trans></h2>

                        <FormUserChange/>
                        
                    </div>
                )}
            </FadeAnimation>
            
        </>
    )
};

export default PageUserProfile
