import { Route, Redirect } from 'react-router';
import { useSelector } from 'react-redux';
import { IApplicationStore } from '../../../store/index';

export const PrivateRoute = ({component: Component, ...rest}: any) => {
    const user = useSelector((store: IApplicationStore) => store.userStore.user)
    
    let authenticated = true
    
    if(user === null) {
        authenticated = false
    }

    if(rest.disableByRight) {
        authenticated = false
    }

    if(authenticated) {
        return (
            <Route
                {...rest}
                render={(props:any) => (
                    <Component {...props} />
                )}
            />
        )
    } else {
        return(
            <Redirect to="/" />
        )
    }
};