import PageNotFound from '../pages/PageNotFound';
import { PrivateRoute } from './PrivateRoute';

export const PrivateRestrictedRoute: React.FC<any> = (props: any) => {
    const {enableByRight, ...rest} = props

    if(enableByRight) {
        return (
            <PrivateRoute {...rest}/>
        )
    } else {
        return (
            <PageNotFound/>
        )
    }
}