import { useState, useRef, useMemo } from 'react';
import useOnClickOutside from '../../../hooks/useOnClickOutside';
import { Trans } from '@lingui/macro';
import { EStatisticsDateType } from '../../../../reducers/data';
import DatePicker from 'react-datepicker';
import { useLingui } from '@lingui/react';
import { getDaysBetweenDates, getYearsBetweenDates } from '../../../helpers/index';
import moment from 'moment';

interface IProps {
    dateType: EStatisticsDateType[],
    selectedDateType: EStatisticsDateType,
    selectedRanges: {
        from: string,
        to: string
    }[],
    onSelectedRanges: (ranges: {
        from: string,
        to: string
    }[]) => void,
    onSelectDateType?: (type: EStatisticsDateType) => void
}

const startDate = '2010-01-01'

const FilterDayWeekMonth: React.FC<IProps> = (props) => {
    const ref = useRef<HTMLDivElement>(null)
    const lingui = useLingui()

    const [show, setShow] = useState(false)
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear())
    const [rangeStart, setRangeStart] = useState<string|null>(null)

    useOnClickOutside(ref, () => {
        setShow(false)
    })

    let { selectedDays, selectedMonths, selectedYears } = useMemo(() => {
        let newDays: string[] = []
        let newMonths: number[] = []
        let years: number[] = []

        //eslint
        if(props.selectedDateType) {}

        props.selectedRanges.forEach(r => {
            let days = getDaysBetweenDates(r.from, r.to)
    
            newDays = newDays.concat(days)

            let month = moment(r.from).toDate().getMonth()
            let year = moment(r.from).toDate().getFullYear()

            if(!newMonths.includes(month) && year === selectedYear) {
                newMonths.push(month)
            }

            if(!years.includes(year)) {
                years.push(year)
            }
        })
    
        return {
            selectedDays: newDays, //remove duplicates
            selectedMonths: newMonths,
            selectedYears: years
        }
    }, [props.selectedRanges, props.selectedDateType, selectedYear])


    const handleDateChange = (date: Date) => {
        let myDate = moment(date).format('YYYY-MM-DD')

        //If it is there then remove that range
        if (props.selectedDateType !== EStatisticsDateType.custom && selectedDays.includes(myDate)) {
            let ranges = props.selectedRanges.filter(r => {
                if(getDaysBetweenDates(r.from, r.to).includes(myDate)) {
                    return false
                } else {
                    return true
                }
            })
    
            setRangeStart(null)
            props.onSelectedRanges(ranges)
        } else {
            switch(props.selectedDateType) {
                case EStatisticsDateType.day:
                    props.onSelectedRanges([
                        ...props.selectedRanges,
                        {
                            from: myDate,
                            to: myDate
                        }
                    ])
                    break
                case EStatisticsDateType.week:
                    props.onSelectedRanges([
                        ...props.selectedRanges,
                        {
                            from: moment(date).startOf('week').format('YYYY-MM-DD'),
                            to: moment(date).endOf('week').format('YYYY-MM-DD')
                        }
                    ])
                    break
                case EStatisticsDateType.month:
                    props.onSelectedRanges([
                        ...props.selectedRanges,
                        {
                            from: moment(date).startOf('month').format('YYYY-MM-DD'),
                            to: moment(date).endOf('month').format('YYYY-MM-DD')
                        }
                    ])
                    break
                case EStatisticsDateType.year:
                    console.log(date)
                    props.onSelectedRanges([
                        ...props.selectedRanges,
                        {
                            from: moment(date).startOf('year').format('YYYY-MM-DD'),
                            to: moment(date).endOf('year').format('YYYY-MM-DD')
                        }
                    ])
                    break
                case EStatisticsDateType.custom:
                    console.log(date)

                    if (rangeStart) {
                        let newRanges = props.selectedRanges.filter(r => r.from !== rangeStart)
                        if (moment(rangeStart).isBefore(moment(date))) {
                            newRanges.push({
                                from: rangeStart,
                                to: myDate
                            })
                        } else {
                            newRanges.push({
                                from: myDate,
                                to: rangeStart
                            })
                        }

                        props.onSelectedRanges(newRanges)
                        setRangeStart(null)
                    } else {
                        props.onSelectedRanges([
                            ...props.selectedRanges,
                            {
                                from: myDate,
                                to: myDate
                            }
                        ])
                        setRangeStart(myDate)
                    }
                    break
            }
        }
    }

    let header: any = undefined

    if(props.selectedDateType === EStatisticsDateType.year) {
        header = (params:any) => {
            console.log(params)

            return null
        }
    }

    let years = getYearsBetweenDates(startDate, moment().format('YYYY-MM-DD'))

    return (
        <div className={`element_drop basic to_left small col_default offset ${show?'active':''}`} ref={ref}>
            <button 
                type="button" 
                className="btn_basic to_half element_drop_button"
                onClick={() => {
                    setShow(!show)
                }}
            >
                <span><Trans>Deň</Trans></span>
                <svg className="sico drop"><use xlinkHref="/assets/layout/icons.svg#s_ar1_d" /></svg>

                <span><Trans>Dátum</Trans></span>
                <svg className="sico drop"><use xlinkHref="/assets/layout/icons.svg#s_ar1_d" /></svg>
            </button>  
            <div className="element_drop_inner">
                <div className="element_drop_arrow"></div>
                <div className="element_drop_content pd-20">
                    <div className="day-datepicker">
                        <div className="days">
                            <ul>
                                {props.dateType.map((td, i) => (
                                    <li 
                                        key={td} 
                                        className={props.selectedDateType === td?'active':''}
                                    >
                                        <span 
                                            onClick={() => {
                                                if(typeof props.onSelectDateType !== 'undefined') {
                                                    props.onSelectDateType(td)
                                                    setRangeStart(null)
                                                }
                                            }}
                                        >
                                            {td === EStatisticsDateType.day && <Trans>Deň</Trans>}
                                            {td === EStatisticsDateType.week && <Trans>Týždeň</Trans>}
                                            {td === EStatisticsDateType.month && <Trans>Mesiac</Trans>}
                                            {td === EStatisticsDateType.year && <Trans>Rok</Trans>}
                                            {td === EStatisticsDateType.custom && <Trans>Vlastné</Trans>}
                                        </span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className={`calendar ${selectedMonths.map(sm => ' selected-month-' + sm).join(' ')}`}>
                            {props.selectedDateType === EStatisticsDateType.year ? (
                                <div className="years">
                                    {years.map((y,i) => (
                                        <div 
                                            className={`year ${selectedYears.includes(Number(y))?'year__active':''} ${new Date().getFullYear() === Number(y)?'year__current':''}`} 
                                            key={i}
                                            onClick={() => handleDateChange(moment(y).toDate())}
                                        >   
                                            {y}
                                        </div>
                                    ))}
                                </div>
                            ):(
                                <DatePicker
                                    selected={null}
                                    onChange={handleDateChange}
                                    locale={lingui.i18n._locale}
                                    showMonthYearPicker={props.selectedDateType === EStatisticsDateType.month}
                                    highlightDates={selectedDays.map(sd => moment(sd).toDate())}
                                    onYearChange={(year) => {
                                        setSelectedYear(year.getFullYear())
                                    }}
                                    dayClassName={(date) => {
                                        let count = selectedDays.filter(day => day === moment(date).format('YYYY-MM-DD')).length

                                        return 'highlighted-' + count
                                    }}
                                    renderCustomHeader={header}
                                    inline
                                    maxDate={new Date()}
                                    yearItemNumber={9}
                                    minDate={moment(startDate).toDate()}
                                    showWeekNumbers={props.selectedDateType === EStatisticsDateType.week}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>                      
        </div> 
    )
}

export default FilterDayWeekMonth