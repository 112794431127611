import { Trans } from "@lingui/macro";
import { EStatisticsGender } from '../../../../reducers/data';

interface IProps {
    type: EStatisticsGender
}

const SmallGenderTitle = (props: IProps) => {
    switch(props.type) {
        case EStatisticsGender.all: 
            return (<><Trans>Všetko</Trans></>)
        case EStatisticsGender.man:
            return (<><Trans>Muž</Trans></>)
        case EStatisticsGender.woman: 
            return (<><Trans>Žena</Trans></>)
    }
}

export default SmallGenderTitle
