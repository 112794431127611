import { Trans, t } from '@lingui/macro';
import { useState, useRef } from 'react';
import moment from 'moment';
import { useLingui } from "@lingui/react";
import { useSelector } from 'react-redux';
import { IApplicationStore } from '../../../store/index';
import useOnClickOutside from '../../hooks/useOnClickOutside';

interface IProps {
    date: string,
    createEvent: (name: string, description: string, date: string, marts: number[]) => void
}

const AddNewEvent = (props: IProps) => {
    const lingui = useLingui()

    const ref = useRef<HTMLDivElement>(null)
    const [showTooltip, setShowTooltip] = useState(false)
    const [newName, setNewName] = useState('')
    const [newDescription, setNewDescription] = useState('')

    useOnClickOutside(ref, () => {
        setShowTooltip(false)
        setNewName('')
        setNewDescription('')
    })

    let marts = useSelector((store: IApplicationStore) => store.dataStore.selectedMarts)


    const submitForm = (e: React.FormEvent) => {
        e.preventDefault()
        e.stopPropagation()

        props.createEvent(
            newName,
            newDescription,
            props.date,
            marts.map(m => m.id)
        )

        setShowTooltip(false)
        setNewName('')
        setNewDescription('')
    }
    
    return (
        <>
            <div className={`element_drop edit_note basic to_right2 col_default offset ${showTooltip?'active':''}`} ref={ref}>
                <button type="button" className="btn_basic element_drop_button toggle-parent-className" onClick={() => setShowTooltip(true)}>
                    <span><Trans>Pridať poznámku</Trans></span>
                    <svg className="sico plus"><use xlinkHref="/assets/layout/icons.svg#s_plus" /></svg>
                </button>  
                <div className="element_drop_inner">
                    <div className="element_drop_arrow"></div>
                    <div className="element_drop_content pd-20">
                        <form onSubmit={(e) => submitForm(e)}>
                            <input 
                                type="text" 
                                className="input_text2" 
                                value={newName}
                                onChange={(e) => setNewName(e.target.value)}
                                placeholder={t`Názov` + '...'} 
                                required
                            />  
                            
                            <div className="grid mgt-10">
                                <div className="col_default mgt-10">
                                    <a href="/" title="" style={{pointerEvents: 'none'}} className="btn_action text_w500 text_nowrap">
                                        <svg className="sico wh16"><use xlinkHref="/assets/layout/icons.svg#s_cal" /></svg>
                                        <span>{moment(props.date).locale(lingui.i18n._locale).format('D. MMMM YYYY')}</span>
                                    </a>
                                </div>
                            </div>

                            {/* <div className="mgt-25">
                                <label className="text_w500 dblock">Chcete dostať upozornenie?</label>

                                <div className="grid small-space">
                                    <label className="label_radio mgt-10 col_default">
                                        <input type="radio" value="1" name="r1" checked />
                                        <span>5 min pred</span>
                                    </label>

                                    <label className="label_radio mgt-10 col_default">
                                        <input type="radio" value="1" name="r1" />
                                        <span>10 min pred</span>
                                    </label>

                                    <label className="label_radio mgt-10 col_default">
                                        <input type="radio" value="1" name="r1" />
                                        <span>30 min pred</span>
                                    </label>

                                    <label className="label_radio mgt-10 col_default">
                                        <input type="radio" value="1" name="r1" />
                                        <span>1 hod pred</span>
                                    </label>
                                </div>
                            </div> */}

                            <textarea 
                                className="input_text2 mgt-25" 
                                cols={30} 
                                rows={1} 
                                value={newDescription}
                                onChange={(e) => setNewDescription(e.target.value)}
                                placeholder={t`Poznámka` + '...'} 
                                required
                            />

                            <div className="text_right mgt-15">
                                <button type="submit" className="btn_basic">
                                    <svg className="sico"><use xlinkHref="/assets/layout/icons.svg#s_save" /></svg>
                                    <span><Trans>Uložiť</Trans></span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>                      
            </div> 
        </>
    )
};

export default AddNewEvent
